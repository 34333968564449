import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import QRCode from 'react-qr-code';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faEdit,
  faUpload,
  faRefresh,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { getAllCities } from '../../../../redux/CountryAndCities/CountryAndCitySlice';
import logoBlue from '../../../../assets/images/icons/GoRateLogo.png';
import logoWhite from '../../../../assets/images/icons/GoRateWhite.png';
import logoBlack from '../../../../assets/images/icons/GoRateBlack.png';
import { Modal, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';
//=========Redux===========
import { useSelector, useDispatch } from 'react-redux';
import {
  ResetSuccessUpdateDefaultCustQR,
  updateCompanySettings,
  updateCusReason,
  updateDefaultCusQuestion,
  updateDefaultCustQR,
  updateDefaultEmpQR,
  updateDefaultEmpQuestion,
  updateEmpReason,
  updateQuestionReason,
} from '../../../../redux/auth/authSlice';
import {
  addReason,
  deleteReason,
  editReason,
  getAllReasons,
  reset,
} from '../../../../redux/reason/reasonSlice';
import {
  addQrCodeTemplate,
  deleteQrCodeTemplate,
  editQrCodeTemplate,
  getAllQrCodeTemplates,
  reset as QrCodeReset,
} from '../../../../redux/qrCode/qrSlice';
import PaymentComponent from './PaymentComponent';
import { getAllQuestions } from '../../../../redux/question/questionSlice';
import { getAllSectors } from '../../../../redux/sector/sectorSlice';

// import { Country, City } from "country-state-city";

import { toast } from 'react-toastify';
import PaymentConfiguration from './PaymentConfiguration';
import {
  addQuestionReason,
  editQuestionReason,
  getAllQuestionReason,
  reset as QuestionReasonReset,
} from '../../../../redux/reasonQuestion/reasonQuestionSlice';
import ReactLoading from 'react-loading';
import ButtonLoading from '../../../../common/ButtonLoading';
// import "./csetting.css";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGE;
const REACT_APP_BASE_URL_QR_CODE = process.env.REACT_APP_BASE_URL_QR_CODE;

const Csetting = () => {
  const [PasswordVisible, setPasswordVisible] = useState(true);
  const [PasswordVisible2, setPasswordVisible2] = useState(true);
  const [ReTypePasswordVisible, setReTypePasswordVisible] = useState(true);
  let dispatch = useDispatch();
  const componentRef = useRef();
  const [CitiesLoading, setCitiesLoading] = useState(false);
  const { profile, isSuccessUpdateProfile } = useSelector((state) => {
    return state.auth;
  });
  const { allReasons, isSuccess, isLoadingAddReason } = useSelector((state) => {
    return state.reason;
  });

  const { allQuestions } = useSelector((state) => {
    return state.question;
  });
  const { allQrCodeTemplates } = useSelector((state) => {
    return state.qrCode;
  });

  const { allSectors } = useSelector((state) => {
    return state.sector;
  });

  const { allQuestionReason, isReaQSuccess, isLoadingAddQuestionReason } =
    useSelector((state) => {
      return state.reasonQuestion;
    });

  let isQRSucceed = useSelector((state) => {
    return state.qrCode.isSuccess;
  });

  const { getCountries, getCities, isCitiesLoading } = useSelector(
    (state) => state.CountryAndCitySlice
  );

  //============Get All Reasons && Get All QRCODES (customer & employee)  =============================
  useEffect(() => {
    dispatch(getAllReasons({ verificationCode }));
    dispatch(getAllQuestionReason({ verificationCode }));
    dispatch(getAllQrCodeTemplates({ verificationCode }));
    dispatch(getAllQuestions({ verificationCode }));
    dispatch(getAllSectors({ verificationCode }));
  }, [dispatch]);

  //===========Country and Cities Selection START=================================
  const [selectedCountry, setSelectedCountry] = useState('');
  const [Cities, setCities] = useState();

  const [AllCountry, setAllCountry] = useState();
  const fetchData = async () => {
    try {
      await fetch(`https://countriesnow.space/api/v0.1/countries`).then(
        async (response) => {
          let data = await response.json();
          setAllCountry(data.data);
        }
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [ValidationError, setValidationError] = useState('');
  const handleCountryChange = async (event) => {
    const countryCode = event.target.value;

    setSelectedCountry(countryCode);
    setCitiesLoading(true);
    try {
      dispatch(getAllCities(countryCode));
    } catch (error) {
      console.error('Error during POST request:', error);
    }
  };
  const validateReasons = () => {
    for (const data of customerResons) {
      if (data.reason.trim() !== '') {
        setValidationError('');
        return true;
      }
    }
    setValidationError('Please fill at least one reason.');
    return false;
  };

  useEffect(() => {
    fetchData();
  }, []);

  //===========Country and Cities Selection END=================================

  //============ Update Profile Settings ============
  //Selected type for account
  const [selectedButton, setSelectedButton] = useState('Customer Experience');
  // const handleButtonClick = (buttonName) => {
  //   setSelectedButton(buttonName);
  // };

  const [logo, setLogo] = useState(null);
  const [profileLogo, setProfileLogo] = useState();

  const handleLogoChange = (e) => {
    setLogo(e.target.files[0]);
  };
  const [preview, setPreview] = useState(null);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!logo) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(logo);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [logo]);

  const [formUpdateData, setFormUpdateData] = useState({
    name_of_business: profile.name_of_business,
    sector: profile.sector,
    password: '',
    repeatPassword: '',
    email: profile.email,
    invoiceAddress: profile.invoiceAddress,
    vat: profile.vat,
    postalCode: profile.postalCode,
    city: profile.city,
    // country: profile.country,
    contactNumber: profile.contactNumber,
    termAndCondition: profile.termAndCondition,
  });

  useEffect(() => {
    // setFormUpdateData({
    //   name_of_business: profile.name_of_business,
    //   sector: profile.sector,
    //   email: profile.email,
    //   invoiceAddress: profile.invoiceAddress,
    //   vat: profile.vat,
    //   postalCode: profile.postalCode,
    //   city: profile.city,
    //   // country: profile.country,
    //   contactNumber: profile.contactNumber,
    //   termAndCondition: profile.termAndCondition,
    // });
    // setSelectedCountry(profile?.country);
    // setProfileLogo(profile?.logo);
    // setSelectedButton(profile?.lookingFor);

    setFormUpdateData({
      name_of_business: profile.name_of_business,
      sector: profile.sector,
      email: profile.email,
      invoiceAddress: profile.invoiceAddress,
      vat: profile.vat,
      postalCode: profile.postalCode,
      city: profile.city,
      country: profile.country,
      contactNumber: profile.contactNumber,
      termAndCondition: profile.termAndCondition,
    });
    setSelectedCountry(profile?.country);
    if (profile?.country) {
      if (profile?.country === 'United States') {
      } else {
        dispatch(getAllCities(profile?.country));
      }
    }
    setProfileLogo(profile?.logo);
    setSelectedButton(profile?.lookingFor);

    // Check if profile city matches a city name in the list
  }, [profile]);

  const {
    name_of_business,
    sector,
    password,
    repeatPassword,
    email,
    invoiceAddress,
    vat,
    postalCode,
    city,
    contactNumber,
    termAndCondition,
  } = formUpdateData;

  const handleUpdateData = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormUpdateData({
      ...formUpdateData,
      [name]: newValue,
    });
  };

  const handleCompanyUpdate = async (e) => {
    e.preventDefault();
    if (password !== repeatPassword) {
      toast.error('Password do not match !');
    } else {
      var businessData = new FormData();
      businessData.append('verificationCode', verificationCode);
      businessData.append('name_of_business', name_of_business);
      businessData.append('sector', sector);
      businessData.append('password', password ? password : '');
      businessData.append('email', email);
      businessData.append('invoiceAddress', invoiceAddress);
      businessData.append('vat', vat);
      businessData.append('postalCode', postalCode);
      businessData.append('city', city);
      businessData.append('country', selectedCountry);
      businessData.append('contactNumber', contactNumber);
      businessData.append('lookingFor', selectedButton);
      businessData.append('termAndCondition', termAndCondition);
      businessData.append('logo', logo);
      dispatch(updateCompanySettings(businessData));
    }
  };

  //=======================++++++++++++CUSTOMER SETTINGS START+++++++++++================

  //=============QR code customer MOdal and Api START ==============================
  const [qrCodeType, setQrCodeType] = useState('');
  //--State for customer QR code settings--

  const [qrFormData, setQRFormData] = useState({
    nameCusQR: '',
    bgcolor: '#352eef',
    fontColor: '#000',
    poweredByLogo: 'blue',
    experienceText: 'Rate Your Experience...',
    fgTextSize: 20,
  });

  const {
    nameCusQR,
    bgcolor,
    fontColor,
    poweredByLogo,
    experienceText,
    fgTextSize,
  } = qrFormData;

  const handleQRDataChange = (e) => {
    const { name, value } = e.target;

    setQRFormData({
      ...qrFormData,
      [name]: value,
    });
  };

  const [showCusQRModal, setShowCusQRModal] = useState(false);
  const handleCloseCusQRModal = () => setShowCusQRModal(false);

  const handleShowCusQRModal = (qrType) => {
    setQrCodeType(qrType);
    setQRFormData({
      nameCusQR: '',
      bgcolor: '#352eef',
      fontColor: '#000',
      poweredByLogo: 'blue',
      experienceText: 'Rate Your Experience...',
      fgTextSize: 20,
    });

    setShowCusQRModal(true);
  };

  const [cusComLogo, setCusComLogo] = useState(null);
  const [previewLogoModal, setPreviewLogoModal] = useState(null);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!cusComLogo) {
      setPreviewLogoModal(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(cusComLogo);
    setPreviewLogoModal(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [cusComLogo]);

  const handleSubmitCusQR = async (e) => {
    e.preventDefault();

    var cusQRData = new FormData();
    cusQRData.append('verificationCode', verificationCode);
    cusQRData.append('name', nameCusQR);
    cusQRData.append('bgColor', bgcolor);
    cusQRData.append('fgColor', fontColor);
    cusQRData.append('fgText', experienceText);
    cusQRData.append('fgTextSize', fgTextSize);
    cusQRData.append('qrCodeType', qrCodeType);
    cusQRData.append('poweredByLogo', poweredByLogo);
    cusQRData.append('companyLogo', cusComLogo);
    dispatch(addQrCodeTemplate(cusQRData));
  };

  //==============Edit QR template ==============================
  const [qrEditTemplate, setQrEditTemplate] = useState(false);
  const [qrEditData, setQrEditData] = useState(null);
  const [editCompLogo, setEditCompLogo] = useState(null);

  const handleEditQRData = (editData) => {
    setEditCompLogo(qrEditData?.companyLogo);
    setQrEditData(editData);
    setQrEditTemplate(true);
  };

  const handleQRDataEditChange = (e) => {
    const { name, value } = e.target;

    setQrEditData({
      ...qrEditData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (qrEditData) {
      // Set qrFormData based on qrEditData
      setQRFormData({
        nameCusQR: qrEditData.name,
        bgcolor: qrEditData.bgColor,
        fontColor: qrEditData.fgColor,
        poweredByLogo: qrEditData.poweredByLogo,
        experienceText: qrEditData.fgText,
        fgTextSize: qrEditData.fgTextSize,
      });
    }
    setQrCodeType(qrEditData?.qrCodeType);
    setEditCompLogo(qrEditData?.companyLogo);
  }, [qrEditData]);

  const handleUpdateQR = async (e) => {
    e.preventDefault();

    var updateQrData = new FormData();
    updateQrData.append('verificationCode', verificationCode);
    updateQrData.append('qrId', qrEditData?._id);
    updateQrData.append('name', nameCusQR);
    updateQrData.append('bgColor', bgcolor);
    updateQrData.append('fgColor', fontColor);
    updateQrData.append('fgText', experienceText);
    updateQrData.append('fgTextSize', fgTextSize);
    updateQrData.append('qrCodeType', qrCodeType);
    updateQrData.append('poweredByLogo', poweredByLogo);
    updateQrData.append(
      'companyLogo',
      cusComLogo ? cusComLogo : qrEditData?.companyLogo
    );
    dispatch(editQrCodeTemplate(updateQrData));
  };

  //==================Qr To view for print start alex========================
  const [isShowQRImage, setIsShowQRImage] = useState(false);

  //==================Qr To view for print end alex========================

  //==============Delete QR template ==========================
  const handleDeleteQR = async (e, id) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      qrId: id,
    };
    dispatch(deleteQrCodeTemplate(body));
  };

  //=============QR code customer MOdal and Api END ===========

  //=============Default QR CODE for Customer and Employee Sentiment START ===========
  const [customerDefaultQR, setCustomerDefaultQR] = useState('');
  const [employeeDefaultQR, setEmployeeDefaultQR] = useState('');

  const handleCusDefaultQR = (e) => {
    setCustomerDefaultQR(e.target.value);
    let body = {
      verificationCode: verificationCode,
      type: 'Customer Experience',
      qrId: e.target.value,
    };
    dispatch(updateDefaultCustQR(body));
  };

  const handleEmpDefaultQR = (e) => {
    setEmployeeDefaultQR(e.target.value);
    let body = {
      verificationCode: verificationCode,
      type: 'Employee Sentiment',
      qrId: e.target.value,
    };
    dispatch(updateDefaultEmpQR(body));
  };

  useEffect(() => {
    setEmployeeDefaultQR(profile?.employeeDefaultQr?._id);
    setCustomerDefaultQR(profile?.customerDefaultQr?._id);
  }, [profile]);

  //=============Default QR CODE for Customer Experience and Employee Sentiment END ===========

  //=============Default Customer Experience and Employee Question START ===========
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [customerDefaultQues, setCustomerDefaultQues] = useState('');
  const [employeeDefaultQues, setEmployeeDefaultQues] = useState('');

  const handleCusDefaultQues = (e) => {
    setCustomerDefaultQues(e.target.value);
    if (e.target.value === '') {
      return toast.error('Please select a default question');
    } else {
      let body = {
        verificationCode: verificationCode,
        type: 'Customer Experience',
        questionId: e.target.value,
        defaultLanguage: defaultLanguage,
      };
      dispatch(updateDefaultCusQuestion(body));
    }
  };

  const handleEmpDefaultQues = (e) => {
    setEmployeeDefaultQues(e.target.value);
    if (e.target.value === '') {
      return toast.error('Please select a default question');
    } else {
      let body = {
        verificationCode: verificationCode,
        type: 'Employee Sentiment',
        questionId: e.target.value,
        defaultLanguage: defaultLanguage,
      };
      dispatch(updateDefaultEmpQuestion(body));
    }
  };

  useEffect(() => {
    setDefaultLanguage(profile?.defaultLanguage);
    setEmployeeDefaultQues(profile?.employeeQuestion?._id);
    setCustomerDefaultQues(profile?.customerQuestion?._id);
  }, [profile]);

  //=============Default Customer Experience and Employee Question END ===========

  //=============REASONS FOR CUSTOMER CRUD alex ===================
  const [reasonType, setReasonType] = useState('');

  const [customerResons, setCustomerResons] = useState([
    { language: 'English', reason: '' },
    { language: 'French', reason: '' },
    { language: 'Italian', reason: '' },
    { language: 'Spanish', reason: '' },
    { language: 'Portuguese', reason: '' },
  ]);

  const [showCustomerReason, setShowCustomerReason] = useState(false);
  const handleCloseCustomerReason = () => setShowCustomerReason(false);
  const handleShowCustomerReason = (rType) => {
    setReasonType(rType);
    setCustomerResons([
      { language: 'English', reason: '' },
      { language: 'French', reason: '' },
      { language: 'Italian', reason: '' },
      { language: 'Spanish', reason: '' },
      { language: 'Portuguese', reason: '' },
    ]);
    setShowCustomerReason(true);
  };

  const handleChangeCusReason = (index, e) => {
    const updatedReasons = [...customerResons];
    updatedReasons[index] = {
      ...updatedReasons[index],
      [e.target.name]: e.target.value,
    };
    setCustomerResons(updatedReasons);
  };

  const handleAddCusReason = (e) => {
    setValidationError('');
    e.preventDefault();
    if (validateReasons()) {
      const reasonNew = {
        verificationCode: verificationCode,
        reasons: customerResons,
        type: reasonType,
      };
      dispatch(addReason(reasonNew));
    }
  };

  //---------------------Edit REASON START--------------------

  const [showEditCusReason, setShowEditCusReason] = useState(false);
  const handleEditCusReasonCloseModal = () => setShowEditCusReason(false);

  const [reasonCusEditData, setReasonCusEditData] = useState();

  const handleEditCusReasonData = (reason) => {
    setReasonCusEditData(reason);
    setShowEditCusReason(true);
  };

  useEffect(() => {
    if (reasonCusEditData && reasonCusEditData.reasons) {
      const options = reasonCusEditData.reasons.map((item) => ({
        language: item.language,
        reason: item.reason,
      }));
      setCustomerResons(options);
    }
  }, [reasonCusEditData]);

  const handleUpdateCusReason = (e) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      reasonId: reasonCusEditData._id,
      reasons: customerResons,
      type: reasonCusEditData.type,
    };
    dispatch(editReason(body));
  };

  //---------------------Edit REASON END--------------------

  //==========Delete Reason =================
  const handleDeleteReason = (e, id) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      id: id,
    };
    dispatch(deleteReason(body));
  };

  //====================Default Reason for customer and employee------------------------
  const [customerReasons, setCustomerReasons] = useState([]);

  const handleCusReasonsSelection = (e) => {
    const options = e.map((item) => ({
      value: item.value,
      label: item.value,
    }));
    setCustomerReasons(options);
  };

  useEffect(() => {
    const options =
      profile &&
      profile?.customerReasons?.map((item) => ({
        value: item.value,
        label: item.label,
        _id: item._id,
      }));
    setCustomerReasons(options);
  }, [profile]);

  const handleSaveCustomerReasons = (e) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      customerReasons: customerReasons,
    };
    dispatch(updateCusReason(body));
  };

  //====================Default Reason for employee and employee------------------------
  const [employeeReasons, setEmployeeReasons] = useState([]);

  const handleEmpReasonsSelection = (e) => {
    const options = e.map((item) => ({
      value: item.value,
      label: item.value,
    }));
    setEmployeeReasons(options);
  };

  const handleSaveEmployeeReasons = (e) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      employeeReasons: employeeReasons,
    };
    dispatch(updateEmpReason(body));
  };

  //====================Default Question for Reason -----------------
  const [showQReason, setShowQReason] = useState(false);
  const handleCloseQReason = () => setShowQReason(false);

  const [questionReason, setQuestionReason] = useState([
    { language: 'English', Qreason: '' },
    { language: 'French', Qreason: '' },
    { language: 'Italian', Qreason: '' },
    { language: 'Spanish', Qreason: '' },
    { language: 'Portuguese', Qreason: '' },
  ]);

  const handleChangeQReason = (index, e) => {
    const updatedQReasons = [...questionReason];
    updatedQReasons[index] = {
      ...updatedQReasons[index],
      [e.target.name]: e.target.value,
    };
    setQuestionReason(updatedQReasons);
  };

  const handleSaveReasonQuestion = (e) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      reasonQuestion: questionReason,
    };
    dispatch(addQuestionReason(body));
  };

  //======Edit reason Question============

  const [showEditQuestionReason, setShowEditQuestionReason] = useState(false);
  const handleEditQuestionReasonCloseModal = () =>
    setShowEditQuestionReason(false);

  const [reasonQuestionEditData, setReasonQuestionEditData] = useState();

  const handleEditQuestionReasonData = (reason) => {
    setReasonQuestionEditData(reason);
    setShowEditQuestionReason(true);
  };

  useEffect(() => {
    if (reasonQuestionEditData) {
      const options = reasonQuestionEditData.reasonQuestion.map((item) => ({
        language: item.language,
        Qreason: item.Qreason,
      }));
      setQuestionReason(options);
    }
  }, [reasonQuestionEditData]);

  const handleUpdateQuestionReason = (e) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      business: reasonQuestionEditData.business,
      reasonId: reasonQuestionEditData._id,
      reasonQuestion: questionReason,
      // type: reasonCusEditData.type,
    };
    dispatch(editQuestionReason(body));
  };

  //====================Default Question for Reason END-----------------

  //=============REASONS FOR CUSTOMER CRUD END ===================

  useEffect(() => {
    const options =
      profile &&
      profile?.employeeReasons?.map((item) => ({
        value: item.value,
        label: item.label,
        _id: item._id,
      }));
    setEmployeeReasons(options);
    // setQuestionReason(profile?.reasonQuestion);
  }, [profile]);
  useEffect(() => {
    if (isSuccessUpdateProfile && profile) {
      if (profile?.employeeDefaultQr?._id && profile?.customerDefaultQr?._id) {
        setEmployeeDefaultQR(profile?.employeeDefaultQr?._id);
        setCustomerDefaultQR(profile?.customerDefaultQr?._id);
      } else {
        if (profile?.customerDefaultQr) {
          let body = {
            verificationCode: verificationCode,
            type: 'Customer Experience',
            qrId: profile?.customerDefaultQr,
          };
          dispatch(updateDefaultCustQR(body));
        }
        if (profile?.employeeDefaultQr) {
          let body = {
            verificationCode: verificationCode,
            type: 'Employee Sentiment',
            qrId: profile?.employeeDefaultQr,
          };
          dispatch(updateDefaultCustQR(body));
        }
        setEmployeeDefaultQR(profile?.employeeDefaultQr);
        setCustomerDefaultQR(profile?.customerDefaultQr);
      }
    }
    dispatch(ResetSuccessUpdateDefaultCustQR());
  }, [isSuccessUpdateProfile]);

  //=======================++++++++++++CUSTOMER SETTINGS END+++++++++++================

  //===========Reset Data on Success =====================
  useEffect(() => {
    if (isSuccess) {
      setCustomerResons([
        { language: 'English', reason: '' },
        { language: 'French', reason: '' },
        { language: 'Italian', reason: '' },
        { language: 'Spanish', reason: '' },
        { language: 'Portuguese', reason: '' },
      ]);
      handleCloseCustomerReason();
      setShowEditCusReason(false);
    }
    dispatch(reset());
  }, [isSuccess]);

  //-----Resetting Customer and employee states ------------

  useEffect(() => {
    if (isQRSucceed) {
      setQRFormData({
        nameCusQR: '',
        bgcolor: '#352eef',
        fontColor: '#000',
        fgTextSize: 20,
        poweredByLogo: 'blue',
        experienceText: 'Rate Your Experience...',
      });
      setCusComLogo(null);
      setPreviewLogoModal(null);
      handleCloseCusQRModal();
      setQrEditTemplate(false);
    }
    dispatch(QrCodeReset());
  }, [isQRSucceed]);

  //-----Resetting Question State ------------
  useEffect(() => {
    if (isReaQSuccess) {
      // setQuestionReason([
      //   { language: "English", Qreason: "" },
      //   { language: "French", Qreason: "" },
      //   { language: "Italian", Qreason: "" },
      //   { language: "Spanish", Qreason: "" },
      //   { language: "Portuguese", Qreason: "" },
      // ]);

      handleCloseQReason();
      handleEditQuestionReasonCloseModal();
    }
    dispatch(QuestionReasonReset());
  }, [isReaQSuccess]);

  // Filter data by type "Customer Experience"
  const uniqueLanguages = Array.from(
    new Set(
      allReasons.flatMap((record) =>
        record.reasons.map((reason) => reason.language)
      )
    )
  );

  const filteredDataCus = allReasons.filter(
    (record) => record.type === 'Customer Experience'
  );

  // Filter data by type "Customer Experience"
  const filteredDataEmp = allReasons.filter(
    (record) => record.type === 'Employee Sentiment'
  );

  // Filter data by Reason Questions ADDED 3-10-23
  const uniqueLanguagesQuestion = Array.from(
    new Set(
      allQuestionReason.flatMap((record) =>
        record.reasonQuestion.map((reason) => reason.language)
      )
    )
  );

  const { language, setLanguage } = useMyContext();
  return (
    <>
      <div className="container-fluid my-3" id="userSetting">
        {/* Business Setting start*/}
        <div className="row mb-2">
          <div className="col-12 col-md-12">
            <div className="d-flex justify-content-between align-items-center">
              <select onChange={(e) => setLanguage(e.target.value)}>
                <option value={'en'} selected={language == 'en'}>
                  English
                </option>
                <option value={'es'} selected={language == 'es'}>
                  Spanish
                </option>
              </select>
              <p className="mb-0">
                Default Language :{' '}
                <span className="Language">
                  {language === 'en' ? 'English' : 'Spanish'}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="card shadow-sm">
          <div className="card-header">
            <h4 className="mb-0">
              {' '}
              <Trans>Business Settings</Trans>
            </h4>
          </div>
          <div className="card-body">
            <div className="row mt-3">
              <div className="col-md-12">
                <form className="row g-3" onSubmit={handleCompanyUpdate}>
                  <div className="row">
                    <div className="col-md-4">
                      <label className="form-label">
                        <Trans>Business Name</Trans>
                      </label>
                      <input
                        type="text"
                        value={name_of_business}
                        name="name_of_business"
                        onChange={handleUpdateData}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        <Trans>VAT ID</Trans>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={vat}
                        name="vat"
                        onChange={handleUpdateData}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        <Trans>Contact number</Trans>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={contactNumber}
                        name="contactNumber"
                        onChange={handleUpdateData}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label className="form-label">
                        <Trans>Email Address</Trans>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        name="contactNumber"
                        readOnly
                      />
                    </div>
                    <div className="col-md-4 position-relative">
                      <label className="form-label">
                        <Trans>Password</Trans>
                      </label>
                      <input
                        type={PasswordVisible ? 'password' : 'text'}
                        className="form-control PasswordField"
                        value={password}
                        name="password"
                        onChange={handleUpdateData}
                      />
                      {PasswordVisible ? (
                        <img
                          src="/assets/icons/EyeView.svg"
                          alt="DropDownIcon"
                          className="DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp"
                          onClick={() => {
                            setPasswordVisible(!PasswordVisible);
                          }}
                        />
                      ) : (
                        <img
                          src="/assets/icons/EyeViewNot.svg"
                          alt="DropDownIcon"
                          className="DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp"
                          onClick={() => {
                            setPasswordVisible(!PasswordVisible);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-4 position-relative">
                      <label className="form-label">
                        <Trans>Repeat Password</Trans>
                      </label>
                      <input
                        type={PasswordVisible2 ? 'password' : 'text'}
                        className="form-control PasswordField"
                        value={repeatPassword}
                        name="repeatPassword"
                        onChange={handleUpdateData}
                      />
                      {PasswordVisible2 ? (
                        <img
                          src="/assets/icons/EyeView.svg"
                          alt="DropDownIcon"
                          className="DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp"
                          onClick={() => {
                            setPasswordVisible2(!PasswordVisible2);
                          }}
                        />
                      ) : (
                        <img
                          src="/assets/icons/EyeViewNot.svg"
                          alt="DropDownIcon"
                          className="DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp"
                          onClick={() => {
                            setPasswordVisible2(!PasswordVisible2);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row selectBorder">
                    <div className="col-md-4">
                      <label className="form-label">
                        <Trans>Address</Trans>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={invoiceAddress}
                        name="invoiceAddress"
                        onChange={handleUpdateData}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        <Trans>Postal Code</Trans>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={postalCode}
                        name="postalCode"
                        onChange={handleUpdateData}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        <Trans>Sector</Trans>
                      </label>
                      <select
                        className="form-select"
                        name="sector"
                        value={sector}
                        onChange={handleUpdateData}
                      >
                        <option>
                          <Trans>Select from list</Trans>...
                        </option>
                        {allSectors &&
                          allSectors.length > 0 &&
                          allSectors.map((sec, i) => (
                            <option key={i} value={sec?.sector}>
                              {sec?.sector}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row selectBorder">
                    <div className="col-md-4">
                      <label className="form-label">
                        <Trans>Country</Trans>
                      </label>
                      <select
                        className="form-select"
                        name="selectedCountry"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                      >
                        <option value={''}>
                          <Trans>
                            <Trans>Select from list</Trans>...
                          </Trans>
                        </option>
                        {getCountries?.countries?.map((country) => (
                          <option
                            key={country.countryId}
                            value={country.countryId}
                          >
                            {country.country}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        <Trans>City</Trans>
                      </label>
                      {isCitiesLoading ? (
                        <div className="form-control d-flex justify-content-center">
                          <ReactLoading
                            type={'spinningBubbles'}
                            color={'#000000'}
                            height={24}
                            width={17}
                          />
                        </div>
                      ) : (
                        <div>
                          {selectedCountry && (
                            <select
                              className="form-control"
                              value={city}
                              name="city"
                              onChange={handleUpdateData}
                            >
                              {getCities?.map((city, index) => (
                                <option key={index} value={city?._id}>
                                  {city?.name_of_city}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={preview ? 'col-md-4' : 'col-md-4'}>
                      <div className="admin-field mt-4">
                        <div className="review-drag">
                          <div className="d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="uploadbgIcon"
                            />

                            <span className="text-center greenHeading ms-2">
                              <Trans>Upload Logo</Trans>
                            </span>
                          </div>
                          <input
                            type="file"
                            accept="image/*"
                            name="upload_signature_file"
                            onChange={handleLogoChange}
                          />
                        </div>
                      </div>
                    </div>
                    {logo && (
                      <div className={preview ? 'col-md-2' : 'col-md-0'}>
                        <div className="form-group">
                          <img
                            className=""
                            // width={150}
                            // height={50}
                            src={preview}
                            alt=""
                            style={{
                              // objectFit: "cover",
                              width: '150px',
                              height: 'auto',
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {profileLogo && !logo && (
                      <div className="col-md-3 ">
                        <img
                          // width={150}
                          // height={50}
                          src={BASE_URL_IMAGES + profileLogo}
                          alt=""
                          className="ImageInSetting"
                        />
                      </div>
                    )}

                    {/* <div className="col-md-3 mt-4">
                      <div className="form-check form-group ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="gridCheck"
                          name="termAndCondition"
                          value={termAndCondition}
                          onChange={handleUpdateData}
                          checked={termAndCondition === true ? true : false}
                          disabled={
                            profile.termAndCondition === true ? true : false
                          }
                        />
                        <label
                          className="form-check-label mbl-text"
                          for="gridCheck"
                        >
                          Accept terms and conditions
                        </label>
                      </div>
                    </div> */}
                  </div>

                  <div className="row">
                    <div className="mt-3 d-flex justify-content-end col-md-3 col-lg-12">
                      <Button
                        className="btn btn-primary"
                        onClick={handleCompanyUpdate}
                      >
                        <FontAwesomeIcon icon={faRefresh} />{' '}
                        <Trans>Update Settings</Trans>
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Business Setting end*/}

        {/* CUSTOMERS AND EMPLOYEE VOTING QUESTION SETTINGS START */}
        <div className="card shadow-sm  my-3 ">
          <div className="card-header">
            <h4 className="mb-0">
              <Trans>Voting Question Settings</Trans>
            </h4>
          </div>
          <div className="card-body">
            {/* <div className="col-12 col-md-12">
              <div className="form-group">
                <label className="form-label fw-bold text-nowrap">
                  <Trans>Select Language</Trans>
                </label>
                <select
                  className="form-control"
                  value={defaultLanguage}
                  name="defaultLanguage"
                  onChange={(e) => setDefaultLanguage(e.target.value)}
                >
                  <option value="">
                    <Trans>Select</Trans>...
                  </option>
                  <option value="English">English</option>
                  <option value="Italian">Italian</option>
                  <option value="French">French</option>
                  <option value="Spanish">Spanish</option>
                  <option value="Portuguese">Portuguese</option>
                </select>
              </div>
            </div> */}
            <div className="row selectBorder">
              <div className="col-12 col-md-6">
                <label className="form-label fw-bold text-nowrap">
                  <Trans>Customer Experience Voting Question</Trans>
                </label>
                <select
                  className="form-select"
                  value={customerDefaultQues}
                  onChange={handleCusDefaultQues}
                >
                  <option value="">
                    <Trans>Select from list</Trans>...
                  </option>
                  {allQuestions &&
                    allQuestions.map((question) => {
                      if (
                        question.inputType === 'Customer Experience'
                        // && question.language === defaultLanguage
                      ) {
                        return (
                          <option key={question._id} value={question._id}>
                            {question.question}{' '}
                            <span>({question.language})</span>
                          </option>
                        );
                      }
                      return null;
                    })}
                </select>
              </div>
              <div className="col-12 col-md-6">
                <label className="form-label fw-bold">
                  <Trans>Employee Sentiment Voting Question</Trans>
                </label>
                <select
                  className="form-select"
                  value={employeeDefaultQues}
                  onChange={handleEmpDefaultQues}
                >
                  <option value="">
                    <Trans>Select from list</Trans>...
                  </option>
                  {allQuestions &&
                    allQuestions.map((question) => {
                      if (
                        question.inputType === 'Employee Sentiment'
                        // && question.language === defaultLanguage
                      ) {
                        return (
                          <option key={question._id} value={question._id}>
                            {question.question}{' '}
                            <span>({question.language})</span>
                          </option>
                        );
                      }
                      return null;
                    })}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* CUSTOMERS AND EMPLOYEE VOTING QUESTION SETTINGS END */}

        {/* CUSTOMERS AND EMPLOYEE SETTINGS START */}
        <div className="row my-2">
          {/* Default Setting for customer start*/}
          <div className="col-12 col-md-6">
            <div className="card shadow-sm">
              <div className="card-header">
                <h4 className="mb-0">
                  <Trans>Customer Experience Settings</Trans>
                </h4>
              </div>
              <div className="card-body">
                <div className="row mt-3 selectBorder">
                  <div className="col-12 col-md-12">
                    <label className="form-label fw-bold">
                      <Trans>Customer Experience QR Template</Trans>
                    </label>
                    <select
                      className="form-select"
                      value={customerDefaultQR}
                      onChange={handleCusDefaultQR}
                    >
                      <option value="">
                        <Trans>Select from list</Trans>...
                      </option>
                      {allQrCodeTemplates &&
                        allQrCodeTemplates
                          .filter(
                            (template) =>
                              template?.qrCodeType === 'Customer Experience'
                          )
                          .map((template, i) => (
                            <option value={template?._id}>
                              {template?.name}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-12 text-center ">
                    <Link
                      className="btn btn-primary mt-4 px-1"
                      onClick={(e) =>
                        handleShowCusQRModal('Customer Experience')
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} /> Customer Experience QR
                      Template
                    </Link>
                    <div className="mt-3">
                      {allQrCodeTemplates &&
                        allQrCodeTemplates
                          .filter(
                            (template) =>
                              template?.qrCodeType === 'Customer Experience'
                          )
                          .map((template, i) => (
                            <div className="whiteShadow" key={i}>
                              <div className="d-flex justify-content-between">
                                <img
                                  src={
                                    REACT_APP_BASE_URL_QR_CODE +
                                    template?.qrCode
                                  }
                                  alt="QR Code"
                                  className="img-fluid"
                                  width={25}
                                />
                                <h6 className="text-muted fw-light">
                                  {template?.name}
                                </h6>
                                <div className="d-flex">
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    className="pink cursor-pointer me-2 "
                                    onClick={() => handleEditQRData(template)}
                                    style={{
                                      fontSize: '20px',
                                    }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="pink text-danger cursor-pointer "
                                    onClick={(e) =>
                                      handleDeleteQR(e, template._id)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Default Setting for customer End*/}

          {/* Default Setting for EMPLOYEE START*/}

          <div className="col-12 col-md-6">
            <div className="card shadow-sm">
              <div className="card-header">
                <h4 className="mb-0">
                  <Trans>Employee Sentiment Settings</Trans>
                </h4>
              </div>
              <div className="card-body">
                <div className="row mt-3 selectBorder">
                  <div className="col-12 col-md-12">
                    <label className="form-label fw-bold">
                      <Trans>Employee Sentiment QR Template</Trans>
                    </label>
                    <select
                      className="form-select"
                      value={employeeDefaultQR}
                      onChange={handleEmpDefaultQR}
                    >
                      <option value="">
                        <Trans>Select from list</Trans>...
                      </option>
                      {allQrCodeTemplates &&
                        allQrCodeTemplates
                          .filter(
                            (template) =>
                              template?.qrCodeType === 'Employee Sentiment'
                          )
                          .map((template, i) => (
                            <option value={template?._id}>
                              {template?.name}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-12 text-center">
                    <Link
                      className="btn btn-primary mt-4 px-1"
                      onClick={(e) =>
                        handleShowCusQRModal('Employee Sentiment')
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} /> Employee Sentiment QR
                      Template
                    </Link>
                    <div className="mt-3">
                      {allQrCodeTemplates &&
                        allQrCodeTemplates
                          .filter(
                            (template) =>
                              template?.qrCodeType === 'Employee Sentiment'
                          )
                          .map((template, i) => (
                            <div className="whiteShadow" key={i}>
                              <div className="d-flex justify-content-between">
                                <img
                                  src={
                                    REACT_APP_BASE_URL_QR_CODE +
                                    template?.qrCode
                                  }
                                  alt="QR Code"
                                  className="img-fluid"
                                  width={25}
                                />
                                <h6 className="text-muted fw-light">
                                  {template?.name}
                                </h6>
                                <div className="d-flex">
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    className="pink cursor-pointer me-2 "
                                    onClick={() => handleEditQRData(template)}
                                    style={{
                                      fontSize: '20px',
                                    }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="pink text-danger cursor-pointer "
                                    onClick={(e) =>
                                      handleDeleteQR(e, template._id)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Default Setting for EMPLOYEE END*/}
        </div>

        {/* Default REASON QUESTION START*/}
        <div className="card shadow-sm">
          <div className="card-header">
            <h4 className="mb-0">
              <Trans>Reason Settings</Trans>
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex">
                  <h5 className="pt-2">
                    <Trans>Question to collect the voting reason</Trans>
                  </h5>
                  {allQuestionReason && allQuestionReason.length === 0 && (
                    <Link
                      className="btn btn-primary btn-sm mx-3"
                      onClick={() => setShowQReason(true)}
                    >
                      Set Question
                    </Link>
                  )}
                </div>
                <div className="mt-3 table-responsive-sm">
                  <table className="table ">
                    <thead>
                      <tr>
                        {uniqueLanguagesQuestion.map((language, index) => (
                          <th className="text-center" key={index}>
                            {language}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {allQuestionReason &&
                        allQuestionReason?.map((data) => (
                          <tr key={data.id} className="text-center">
                            {uniqueLanguagesQuestion.map((language, index) => (
                              <td key={index}>
                                {data.reasonQuestion
                                  .filter((item) => item.language === language)
                                  .map((item, index) => (
                                    <div key={index}>{item.Qreason}</div>
                                  ))}
                              </td>
                            ))}
                            <td className="text-center d-flex">
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="pink cursor-pointer pt-1"
                                onClick={() =>
                                  handleEditQuestionReasonData(data)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* Default REASON QUESTION END*/}

            <div className="row ">
              <div className="col-md-6  text-center">
                {/* <div className="row my-3">
                  <div className="col-9 col-md-10">
                    <label className="form-label">
                      Customer Experience Reasons
                    </label>
                    {customerReasons && (
                      <Select
                        id="multiple-select-field"
                        isMulti
                        options={allReasons
                          .filter(
                            (reason) =>
                              reason?.type === "Customer Experience" &&
                              reason?.language === defaultLanguage
                          )
                          .map((reason) => ({
                            label: reason.reason,
                            value: reason.reason,
                          }))}
                        onChange={handleCusReasonsSelection}
                        value={customerReasons}
                      />
                    )}
                  </div>
                  <div className="col-3 col-md-2">
                    <label className="form-label "></label>
                    <Link
                      className="btn btn-primary btn-sm"
                      onClick={handleSaveCustomerReasons}
                    >
                     Save
                    </Link>
                  </div>
                </div> */}
                <Link
                  className="btn btn-primary  px-1"
                  onClick={(e) =>
                    handleShowCustomerReason('Customer Experience')
                  }
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <Trans>Reason</Trans>( <Trans>Customer Experience</Trans> )
                </Link>
                <div className="mt-3 table-responsive-sm">
                  <table className="table ">
                    <thead>
                      <tr>
                        {uniqueLanguages.map((language, index) => (
                          <th className="text-center" key={index}>
                            {language}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredDataCus.map((data) => (
                        <tr key={data.id} className="text-center">
                          {uniqueLanguages.map((language, index) => (
                            <td key={index}>
                              {data.reasons
                                .filter((item) => item.language === language)
                                .map((item, index) => (
                                  <div key={index}>{item.reason}</div>
                                ))}
                            </td>
                          ))}
                          <td className="text-center d-flex">
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="pink cursor-pointer pt-1"
                              onClick={() => handleEditCusReasonData(data)}
                            />
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="pink cursor-pointer text-danger mx-1"
                              onClick={(e) => handleDeleteReason(e, data?._id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-6 text-center">
                {/* <div className="row my-3">
                  <div className="col-9 col-md-10">
                    <label className="form-label">
                      Employee Sentiment Reasons
                    </label>
                    <Select
                      id="multiple-select-field"
                      isMulti
                      options={allReasons
                        .filter(
                          (reason) =>
                            reason?.type === "Employee Sentiment" &&
                            reason?.language === defaultLanguage
                        )
                        .map((reason) => ({
                          label: reason.reason,
                          value: reason.reason,
                        }))}
                      onChange={handleEmpReasonsSelection}
                      value={employeeReasons}
                    />
                  </div>
                  <div className="col-3 col-md-2">
                    <label className="form-label"></label>
                    <Link
                      className="btn btn-primary btn-sm "
                      onClick={handleSaveEmployeeReasons}
                    >
                    Save
                    </Link>
                  </div>
                </div> */}
                <Link
                  className="btn btn-primary  px-1"
                  onClick={(e) =>
                    handleShowCustomerReason('Employee Sentiment')
                  }
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <Trans>Reason</Trans> ( <Trans>Employee Sentiment</Trans> )
                </Link>
                <div className="mt-3 table-responsive-sm">
                  <table className="table ">
                    <thead>
                      <tr>
                        {uniqueLanguages.map((language, index) => (
                          <th className="text-center" key={index}>
                            {language}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredDataEmp.map((data) => (
                        <tr key={data._id} className="text-center">
                          {uniqueLanguages.map((language, index) => (
                            <td key={index}>
                              {data.reasons
                                .filter((item) => item.language === language)
                                .map((item, index) => (
                                  <div key={index}>{item.reason}</div>
                                ))}
                            </td>
                          ))}
                          <td className="text-center d-flex">
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="pink cursor-pointer pt-1"
                              onClick={() => handleEditCusReasonData(data)}
                            />
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="pink cursor-pointer text-danger mx-1"
                              onClick={(e) => handleDeleteReason(e, data?._id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* CUSTOMERS AND EMPLOYEE SETTINGS END  */}
          </div>
        </div>
        <PaymentConfiguration />
        <PaymentComponent />
      </div>

      {/*================ MODALS START================= */}

      {/*++++++++++++++++++ CUSTOMER SETTING MODAL START ++++++++++++++++++*/}

      {/* ------------ QR Code Customer Experience Template ------- */}
      <Modal
        show={showCusQRModal}
        onHide={handleCloseCusQRModal}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>New QR Code Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-12 mb-2">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    value={nameCusQR}
                    onChange={handleQRDataChange}
                    name="nameCusQR"
                    className="form-control"
                  />
                </div>

                <div className="col-md-12 mb-2">
                  <label className="form-label">Background Color</label>
                  <div>
                    <input
                      type="color"
                      name="bgcolor"
                      value={bgcolor}
                      onChange={handleQRDataChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="form-label">Font and Logo Colors</label>
                  <div>
                    <input
                      type="color"
                      name="fontColor"
                      value={fontColor}
                      onChange={handleQRDataChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="form-label">Font Size</label>
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      name="fgTextSize"
                      value={fgTextSize}
                      onChange={(e) => {
                        const cleanedValue = e.target.value.replace(
                          /^0+(\d)|(\.\d)/,
                          '$1$2'
                        );
                        const newValue = parseFloat(cleanedValue) || 0;
                        // Prevent setting negative values
                        if (newValue >= 0) {
                          qrFormData.fgTextSize = newValue;
                          setQRFormData({ ...qrFormData });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="form-label">Powered By Logo Color</label>
                  <select
                    className="form-select"
                    name="poweredByLogo"
                    value={poweredByLogo}
                    onChange={handleQRDataChange}
                  >
                    <option value="blue">Original</option>
                    <option value="white">Full White</option>
                    <option value="black">Full Black</option>
                  </select>
                </div>

                <div className="col-md-12">
                  <div className="d-flex">
                    <div className="review-drag">
                      <div className="d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon
                          icon={faUpload}
                          className="uploadbgIcon"
                        />

                        <span className="text-center ms-2">
                          <Trans>Upload Logo</Trans>
                        </span>
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        name="upload_signature_file"
                        onChange={(e) => setCusComLogo(e.target.files[0])}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-7 mx-auto"
              style={{
                backgroundColor: bgcolor,
                // padding: "2px",
                borderRadius: '10px',
                width: '300px',
                height: '450px', // Set the maximum height
                overflow: 'auto', // Add scrollbars when content overflows
              }}
            >
              <div className="qrcontent text-center">
                <h6 className="fw-light text-black text-center">
                  <textarea
                    className="editable-text form-control"
                    name="experienceText"
                    value={experienceText}
                    onChange={handleQRDataChange}
                    rows={1}
                    style={{
                      color: fontColor,
                      backgroundColor: bgcolor,
                      fontSize: `${parseInt(fgTextSize) - 4}px`,
                      maxWidth: '100%',
                      textAlign: 'center',
                    }}
                  />
                </h6>

                {nameCusQR && (
                  <div>
                    {/* <QRCode
                      value={nameCusQR}
                      bgColor={bgcolor}
                      fgColor={fontColor}
                    /> */}
                    <QRCode
                      value={nameCusQR}
                      bgColor={bgcolor}
                      fgColor={fontColor}
                      // style={{
                      //   width: "75%", // You can adjust this based on your design
                      //   height: `calc(${fgTextSize}px * 1.5)`, // Maintain 1.5:1 aspect ratio
                      // }}
                    />
                  </div>
                )}

                <div className="text-center">
                  {cusComLogo && (
                    <div>
                      <div className="form-group ">
                        <img
                          className="img-responsive  mt-3"
                          width={150}
                          height={50}
                          src={previewLogoModal}
                          alt=""
                          style={{
                            objectFit: 'cover',
                            mixBlendMode: 'multiply',
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <p className="text-black text-center mt-3">
                  <span>Powered By: </span>
                  {poweredByLogo === 'blue' && (
                    <img
                      src={logoBlue}
                      alt="Our Logo"
                      className="img-fluid d-block m-auto w-50"
                    />
                  )}
                  {poweredByLogo === 'white' && (
                    <img
                      src={logoWhite}
                      alt="Our Logo"
                      className="img-fluid d-block m-auto w-50"
                    />
                  )}
                  {poweredByLogo === 'black' && (
                    <img
                      src={logoBlack}
                      alt="Our Logo"
                      className="img-fluid d-block m-auto w-50"
                    />
                  )}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 d-flex justify-content-center mt-3">
                <Link className="btn btn-primary" onClick={handleSubmitCusQR}>
                  <Trans>Save</Trans>
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*--------------- Edit QR Template -------------------------------*/}
      <Modal
        show={qrEditTemplate}
        onHide={() => setQrEditTemplate(false)}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit QR Code Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {qrEditData && (
            <div className="row">
              <div className="col-md-5">
                <form>
                  <div className="row">
                    <div className="col-md-12 mb-2">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        value={nameCusQR}
                        onChange={handleQRDataEditChange}
                        name="name"
                        className="form-control"
                      />
                    </div>

                    <div className="col-md-12 mb-2">
                      <label className="form-label">Background Colour</label>
                      <div>
                        <input
                          type="color"
                          name="bgColor"
                          value={bgcolor}
                          onChange={handleQRDataEditChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">
                        Font and Logo Colours
                      </label>
                      <div>
                        <input
                          type="color"
                          name="fgColor"
                          value={fontColor}
                          onChange={handleQRDataEditChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">Font Size</label>
                      <div>
                        <input
                          type="number"
                          className="form-control"
                          name="fgTextSize"
                          value={fgTextSize}
                          onChange={(e) => {
                            const cleanedValue = e.target.value.replace(
                              /^0+(\d)|(\.\d)/,
                              '$1$2'
                            );
                            const newValue = parseFloat(cleanedValue) || 0;
                            // Prevent setting negative values
                            if (newValue >= 0) {
                              qrFormData.fgTextSize = newValue;
                              setQRFormData({ ...qrFormData });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">
                        Powered By Logo Color
                      </label>
                      <select
                        className="form-select"
                        name="poweredByLogo"
                        value={poweredByLogo}
                        onChange={handleQRDataEditChange}
                      >
                        <option value="blue">Original</option>
                        <option value="white">Full White</option>
                        <option value="black">Full Black</option>
                      </select>
                    </div>
                    <div className="col-md-12">
                      <div className="d-flex">
                        <div className="review-drag">
                          <div className="d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              icon={faUpload}
                              className="uploadbgIcon"
                            />
                            <span className="text-center ms-2">
                              <Trans>Upload Logo</Trans>
                            </span>
                          </div>
                          <input
                            type="file"
                            accept="image/*"
                            name="upload_signature_file"
                            onChange={(e) => setCusComLogo(e.target.files[0])}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div
                className="col-md-7 mx-auto"
                style={{
                  backgroundColor: bgcolor,
                  // padding: "2px",
                  borderRadius: '10px',
                  width: '300px',
                  height: '450px', // Set the maximum height
                  overflow: 'auto', // Add scrollbars when content overflows
                }}
              >
                <div className="qrcontent text-center">
                  <h6 className="fw-light text-black text-center">
                    <textarea
                      className="editable-text form-control"
                      name="fgText"
                      value={experienceText}
                      onChange={handleQRDataEditChange}
                      rows={1}
                      style={{
                        color: fontColor,
                        backgroundColor: bgcolor,
                        fontSize: `${parseInt(fgTextSize) - 4}px`,
                        maxWidth: '100%',
                        textAlign: 'center',
                      }}
                    />
                  </h6>
                  {nameCusQR && (
                    <div>
                      <QRCode
                        value={nameCusQR}
                        bgColor={bgcolor}
                        fgColor={fontColor}
                      />
                    </div>
                  )}

                  <div className="text-center">
                    {editCompLogo && !cusComLogo && (
                      <img
                        className="img-responsive mt-3"
                        width={150}
                        height={50}
                        src={BASE_URL_IMAGES + editCompLogo}
                        alt=""
                        style={{
                          objectFit: 'cover',
                          mixBlendMode: 'multiply',
                        }}
                      />
                    )}
                    {cusComLogo && (
                      <div>
                        <div className="form-group ">
                          <img
                            className="img-responsive mt-3"
                            width={150}
                            height={50}
                            src={previewLogoModal}
                            alt=""
                            style={{
                              objectFit: 'cover',
                              mixBlendMode: 'multiply',
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <p className="text-black text-center mt-3">
                    <span style={{ color: fontColor }}>Powered By: </span>
                    {poweredByLogo === 'blue' && (
                      <img
                        src={logoBlue}
                        alt="Our Logo"
                        className="img-fluid d-block m-auto w-50"
                      />
                    )}
                    {poweredByLogo === 'white' && (
                      <img
                        src={logoWhite}
                        alt="Our Logo"
                        className="img-fluid d-block m-auto w-50"
                      />
                    )}
                    {poweredByLogo === 'black' && (
                      <img
                        src={logoBlack}
                        alt="Our Logo"
                        className="img-fluid d-block m-auto w-50"
                      />
                    )}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 d-flex justify-content-center mt-3">
                  <Link className="btn btn-primary" onClick={handleUpdateQR}>
                    Update
                  </Link>
                </div>
                <div className="col-md-6 d-flex justify-content-center mt-3">
                  <Link
                    className="btn btn-primary"
                    onClick={() => setIsShowQRImage(true)}
                  >
                    QR Preview
                  </Link>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* ------------  QR Code Customer Experience Template  End ------------*/}

      {/* CREATE CUSTOMER REASON START + */}
      <Modal
        show={showCustomerReason}
        onHide={handleCloseCustomerReason}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>New Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-3">
            {customerResons.map((reason, index) => (
              <>
                <div className="col-6" key={index}>
                  <h6 className="text-muted">Reason</h6>
                  <input
                    type="text"
                    className="form-control"
                    name="reason"
                    value={reason.reason}
                    onChange={(e) => handleChangeCusReason(index, e)}
                    placeholder="Enter reason"
                  />
                </div>
                <div className="col-6">
                  <h6 className="text-muted">Language</h6>
                  <input
                    type="text"
                    className="form-control"
                    name="language"
                    value={reason.language}
                    readOnly
                  />
                </div>
              </>
            ))}
            {ValidationError && <Alert>{ValidationError}</Alert>}

            <div className="d-flex justify-content-end mt-3">
              <div className="d-flex justify-content-between gap-2">
                <button
                  type="button"
                  className="btn btn-secondary w-100"
                  onClick={handleCloseCustomerReason}
                >
                  Cancel
                </button>
                {isLoadingAddReason ? (
                  <div>
                    {' '}
                    <ButtonLoading />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary d-block "
                    onClick={handleAddCusReason}
                  >
                    <Trans>Save</Trans>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* CREATE  CUSTOMER REASON END */}

      {/* EDIT/UPDATE CUSTOMER REASON START */}
      <Modal
        show={showEditCusReason}
        onHide={handleEditCusReasonCloseModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-3">
            {reasonCusEditData && (
              <>
                {customerResons?.map((reason, index) => (
                  <>
                    <div className="col-6" key={index}>
                      <h6 className="text-muted">Reason</h6>
                      <input
                        type="text"
                        className="form-control"
                        name="reason"
                        value={reason.reason}
                        onChange={(e) => handleChangeCusReason(index, e)}
                        placeholder="Enter reason"
                      />
                    </div>
                    <div className="col-6">
                      <h6 className="text-muted">Language</h6>
                      <input
                        type="text"
                        className="form-control"
                        name="language"
                        value={reason.language}
                        readOnly
                      />
                    </div>
                  </>
                ))}
              </>
            )}

            <button
              type="button"
              className="btn btn-primary d-block mt-2 ms-auto"
              onClick={handleUpdateCusReason}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* EDIT/UPDATECUSTOMER REASON END */}

      {/* CREATE CUSTOMER REASON START + */}
      <Modal show={showQReason} onHide={handleCloseQReason} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Reason Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-3">
            {questionReason &&
              questionReason.length > 0 &&
              questionReason?.map((reason, index) => (
                <>
                  <div className="col-6" key={index}>
                    <h6 className="text-muted">Reason Question</h6>
                    <input
                      type="text"
                      className="form-control"
                      name="Qreason"
                      value={reason.Qreason}
                      onChange={(e) => handleChangeQReason(index, e)}
                      placeholder="Enter reason question"
                    />
                  </div>
                  <div className="col-6">
                    <h6 className="text-muted">Language</h6>
                    <input
                      type="text"
                      className="form-control"
                      name="language"
                      value={reason.language}
                      readOnly
                    />
                  </div>
                </>
              ))}
            <div className="d-flex justify-content-end mt-3">
              <div className="d-flex justify-content-between gap-2">
                <button
                  type="button"
                  className="btn btn-secondary w-100"
                  onClick={handleCloseQReason}
                >
                  Cancel
                </button>
                {isLoadingAddQuestionReason ? (
                  <div>
                    {' '}
                    <ButtonLoading />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary d-block "
                    onClick={handleSaveReasonQuestion}
                  >
                    <Trans>Save</Trans>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* CREATE  CUSTOMER REASON END */}

      {/* EDIT/UPDATE Question REASON START */}
      <Modal
        show={showEditQuestionReason}
        onHide={handleEditQuestionReasonCloseModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Question Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-3">
            {reasonQuestionEditData && (
              <>
                {questionReason?.map((reason, index) => (
                  <>
                    <div className="col-6" key={index}>
                      <h6 className="text-muted">Reason Question</h6>
                      <input
                        type="text"
                        className="form-control"
                        name="Qreason"
                        value={reason.Qreason}
                        onChange={(e) => handleChangeQReason(index, e)}
                        placeholder="Enter reason question"
                      />
                    </div>
                    <div className="col-6">
                      <h6 className="text-muted">Language</h6>
                      <input
                        type="text"
                        className="form-control"
                        name="language"
                        value={reason.language}
                        readOnly
                      />
                    </div>
                  </>
                ))}
              </>
            )}

            <button
              type="button"
              className="btn btn-primary d-block mt-2 ms-auto"
              onClick={handleUpdateQuestionReason}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* EDIT/UPDATE Question REASON END */}

      {/*++++++++++++++++++ CUSTOMER SETTING MODAL END    ++++++++++++++++++*/}

      {/* //======================QR Code View Start alex====================== */}
      <Modal show={isShowQRImage} onHide={() => setIsShowQRImage(false)}>
        <Modal.Header closeButton>
          <Modal.Title>QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <>
              {qrEditData && (
                <div className="row " ref={componentRef}>
                  <div
                    className="col-12 col-md-8 mx-auto section-to-print"
                    style={{
                      backgroundColor: qrEditData.bgColor,
                    }}
                  >
                    <div className="text-center">
                      <h6
                        style={{
                          color: qrEditData?.fgColor,
                          fontSize: `${qrEditData?.fgTextSize}px`,
                        }}
                      >
                        {qrEditData?.fgText}
                      </h6>

                      <div className="">
                        <img
                          src={REACT_APP_BASE_URL_QR_CODE + qrEditData?.qrCode}
                          alt="QR code"
                          style={{
                            width: '100%',
                            height: 'auto',
                          }}
                        />
                      </div>
                      {qrEditData?.companyLogo && (
                        <div className="">
                          <img
                            src={BASE_URL_IMAGES + qrEditData?.companyLogo}
                            alt="QR code"
                            className="img-fluid w-50 qr-logo-img"
                          />
                        </div>
                      )}
                      <div className="mt-1">
                        <h6 className="mb-0">Powered By</h6>
                        <div className="text-center">
                          <img
                            src={
                              qrEditData?.poweredByLogo === 'white'
                                ? logoWhite
                                : qrEditData?.poweredByLogo === 'blue'
                                ? logoBlue
                                : qrEditData?.poweredByLogo === 'black'
                                ? logoBlack
                                : null
                            }
                            alt="Our Logo"
                            className="img-fluid w-50 text-center"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </Modal.Body>
        {/* {isSuccess && */}
        <Modal.Footer>
          <ReactToPrint
            trigger={() => <button className="btn btn-primary">Print</button>}
            content={() => componentRef.current}
          />
        </Modal.Footer>
        {/* } */}
      </Modal>
      {/* //======================QR Code View End ====================== */}
    </>
  );
};

export default Csetting;
