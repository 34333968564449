import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import {
  reset,
  updateCompanyCardDetails,
} from '../../../../redux/auth/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import ButtonLoading from '../../../../common/ButtonLoading';
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  let dispatch = useDispatch();
  const { profile, isSuccess, isLoadingUpdateCompanyCardDetails } = useSelector(
    (state) => {
      return state.auth;
    }
  );

  const [Accountholdername, setAccountholdername] = useState('');
  const [BankName, setBankName] = useState();
  const [AccountNumber, setAccountNumber] = useState();
  const [AccountDetails, setAccountDetails] = useState();

  //====Configuration====Popup=============

  const [showModalPayment, setShowModalPayment] = useState(false);
  const handlePaymentClose = () => setShowModalPayment(false);

  const handleConfigPopup = () => {
    setShowModalPayment(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const { token, error } = await stripe.createToken(
      elements.getElement(CardElement)
    );
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    if (payload && payload.error) {
      toast.error(payload.error?.message);
    }
    if (payload && payload?.paymentMethod) {
      let body = {
        verificationCode: verificationCode,
        cardHolder: payload?.paymentMethod?.customer,
        cardNumber: payload?.paymentMethod?.card?.last4,
        cardCvv: token?.card?.address_zip,
        cardMonth: token?.card?.exp_month,
        cardYear: token?.card?.exp_year,
        cardBillingAddress:
          payload?.paymentMethod?.billing_details?.address?.line1,
        cardCountry: payload?.paymentMethod?.billing_details?.address?.country,
        cardCity: payload?.paymentMethod?.billing_details?.address?.city,
        cardZipCode:
          payload?.paymentMethod?.billing_details?.address?.postal_code,
        token: token?.id,
        pmId: payload?.paymentMethod?.id,
        paymentMethod: 'card',
      };
      dispatch(updateCompanyCardDetails(body));
    }
  };

  function handlerSubmitBankData(params) {
    dispatch(
      updateCompanyCardDetails({
        businessId: profile?.businessId,
        verificationCode: 123123123,
        email: profile?.email,
        paymentMethod: 'bank_transfer',
        customerId: 'cus_PLbarB8K3Fx62X',
        cardBillingAddress: null,
        cardCity: null,
        cardCountry: null,
        cardCvv: '42424',
        cardHolder: null,
        cardMonth: 4,
        cardNumber: '4242',
        cardYear: 2024,
        cardZipCode: '42424',
        pmId: 'pm_1OXLaMJSz382R8K8doLO7y1O',
        token: 'tok_1OXLaMJSz382R8K8ql0bWA0b',
        bankDetails: {
          accountHolder: Accountholdername,
          bankName: BankName,
          accountNumber: AccountNumber,
          AccountDetails: AccountDetails,
        },

        cardDetails: {
          AccountDetails: AccountDetails,
          AccountNumber: AccountNumber,
          BankName: BankName,
          Accountholdername: Accountholdername,
        },
      })
    );
  }

  useEffect(() => {
    if (isSuccess) {
      handlePaymentClose();
    }
    dispatch(reset());
  }, [isSuccess]);

  return (
    <>
      <div className="d-flex gap-2 my-3 align-items-center">
        <Link
          className="btn btn-primary text-nowrap "
          onClick={handleConfigPopup}
        >
          <FontAwesomeIcon icon={faCog} className="me-2" />
          <Trans>Payment Settings</Trans>
        </Link>
        <p className="mb-0">
          {profile && profile?.customerId && (
            <Alert className="mb-0 p-1  text-center">
              <Trans>You have already configured your payment details</Trans>{' '}
              <span>{profile?.cardDetails?.cardNumber}</span>
            </Alert>
          )}
        </p>
      </div>
      {/* Payment Modal */}
      <Modal
        show={showModalPayment}
        onHide={handlePaymentClose}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Payment Details</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {profile && profile?.customerId && (
              <Alert className="p-1  text-center">
                You have already configured your payment details , want to
                update ?
              </Alert>
            )}
          </>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              {profile?.paymentMethod === 'card' ? (
                <div className="card-element-container">
                  <label className="mb-3">Card details</label>
                  <CardElement
                    className="card-element"
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#fa755a',
                        },
                      },
                    }}
                  />
                </div>
              ) : (
                <div>
                  <h4 className="mt-3">Account Details</h4>
                  <form className="row">
                    <div className="col-md-6">
                      <label className="form-label">Account Holder Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="accountholdername"
                        value={Accountholdername}
                        onChange={(e) => setAccountholdername(e.target.value)}
                        id="inputEmail4"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Bank Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="accountholdername"
                        value={BankName}
                        onChange={(e) => setBankName(e.target.value)}
                        id="inputEmail4"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Account Number</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={AccountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        id="inputEmail4"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Account Details</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={AccountDetails}
                        onChange={(e) => setAccountDetails(e.target.value)}
                        id="inputEmail4"
                      />
                    </div>
                    {/* <div className="col-md-12">
                  <div className="d-flex justify-content-end mt-4">
                    <div className="d-flex justify-content-between gap-2">
                      <button
                        type="button"
                        className="btn btn-secondary w-100"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      {false ? <div> <ButtonLoading /></div>  : <button
                        className="btn btn-warning w-100"
                        onClick={handleSubmitBankTransfer}
                      >
                        Subscribe
                      </button>
                      }
                    </div>
                  </div>
                </div> */}
                  </form>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-end my-3">
              <div className="d-flex justify-content-between gap-2">
                <button
                  type="button"
                  className="btn btn-secondary w-100"
                  onClick={handlePaymentClose}
                >
                  Cancel
                </button>
                {isLoadingUpdateCompanyCardDetails ? (
                  <div>
                    {' '}
                    <ButtonLoading />
                  </div>
                ) : (
                  <div>
                    {profile?.paymentMethod === 'card' ? (
                      <button
                        type="submit"
                        disabled={!stripe}
                        className="btn btn-primary "
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="Button"
                        className="btn btn-primary "
                        onClick={handlerSubmitBankData}
                      >
                        Save
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* Payment Modal End */}
    </>
  );
};

export default PaymentForm;
