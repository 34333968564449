import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import ReactApexChart from 'react-apexcharts';

import api from '../../../../utility/api';

import { Trans } from 'react-i18next';
import { useMyContext } from '../../../..';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import { useDispatch, useSelector } from 'react-redux';
import { getOneDTP } from '../../../../redux/auth/authSlice';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import logoBlue from '../../../../assets/images/icons/GoRateLogo.png';
import logoWhite from '../../../../assets/images/icons/GoRateWhite.png';
import logoBlack from '../../../../assets/images/icons/GoRateBlack.png';
import ReactToPrint from 'react-to-print';

// import "./sdash.css";

//======Utils
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGE;
const REACT_APP_BASE_URL_QR_CODE = process.env.REACT_APP_BASE_URL_QR_CODE;

const SDash = () => {
  let dispatch = useDispatch();
  const { language, setLanguage } = useMyContext();
  //=======useSelector Data=====================
  const { oneDTPData, profile } = useSelector((state) => {
    return state.auth;
  });

  //=========Get one Data Touchpoint =================
  useEffect(() => {
    dispatch(
      getOneDTP({
        verificationCode,
        slug: profile?.slug,
      })
    );
  }, [dispatch, profile]);

  const [associatedDTP, setAssociatedDTP] = useState([]);
  const [selectDTP, setSelectDTP] = useState('');

  useEffect(() => {
    if (oneDTPData) {
      if (oneDTPData?.associatedDTP) {
        setAssociatedDTP(oneDTPData?.associatedDTP);
      }
    }
  }, [oneDTPData]);

  useEffect(() => {
    if (selectDTP !== '') {
      dispatch(
        getOneDTP({
          verificationCode,
          slug: selectDTP,
        })
      );
    }
  }, [dispatch, selectDTP]);

  //===============Chart Pie for last 30 days && <Trans>Year</Trans> to Date now START ============= ======
  const [thirtyDaysNPS, setThirtyDaysNPS] = useState();
  const [color30Days, setColor30Days] = useState();

  let [thirtyDaySeries, setThirtyDaySeries] = useState([]);
  let [thirtyDaysLabels, setThirtyDaysLabels] = useState([]);

  //=====YEAR to Date NPS ===========
  const [ytdNPS, setYTDNPS] = useState();
  const [colorYTDNPS, setColorYTDNPS] = useState();
  let [YTDSeries, setYTDSeries] = useState([]);
  let [YTDLabels, setYTDLabels] = useState([]);

  let totalPromotersSum = 0;
  let totalPassivesSum = 0;
  let totalDetractorsSum = 0;

  //==<Trans>Year</Trans> to Date NPS
  let totalPromotersYTDSum = 0;
  let totalPassivesYTDSum = 0;
  let totalDetractorsYTDSum = 0;

  // Iterate through each data touchpoint in npsData
  useEffect(() => {
    oneDTPData?.npsData &&
      oneDTPData?.npsData?.forEach((dataTouchPoint) => {
        const { npsLast30Days, npsYearToDate } = dataTouchPoint;

        const {
          totalPromotersLast30Days,
          totalPassivesLast30Days,
          totalDetractorsLast30Days,
        } = npsLast30Days;

        totalPromotersSum += totalPromotersLast30Days;
        totalPassivesSum += totalPassivesLast30Days;
        totalDetractorsSum += totalDetractorsLast30Days;

        // Calculate the series data for  YTD the chart
        const {
          totalPromotersYearToDate,
          totalPassivesYearToDate,
          totalDetractorsYearToDate,
        } = npsYearToDate;

        totalPromotersYTDSum += totalPromotersYearToDate;
        totalPassivesYTDSum += totalPassivesYearToDate;
        totalDetractorsYTDSum += totalDetractorsYearToDate;

        let totalReviewLast30Days =
          totalPromotersSum + totalPassivesSum + totalDetractorsSum;

        let promotersPercentage =
          (totalPromotersSum / totalReviewLast30Days) * 100;
        let detractorsPercentage =
          (totalDetractorsSum / totalReviewLast30Days) * 100;

        let npsLast30ds = promotersPercentage - detractorsPercentage;

        if (npsLast30ds != undefined) {
          if (npsLast30ds >= 30) {
            setColor30Days('#41E998'); // Green for NPS >= 30
          } else if (npsLast30ds >= -30) {
            setColor30Days('#F5A623');
          } else {
            setColor30Days('#E84949');
          }
        }
        setThirtyDaysNPS(npsLast30ds);

        let thirtyDayArr = [];

        thirtyDayArr.push(
          totalPromotersSum,
          totalPassivesSum,
          totalDetractorsSum
        );
        setThirtyDaySeries(thirtyDayArr);

        //-----Labels 30 days-----

        let thirtyDayLabelsArr = [];

        let totalPromoters1 =
          parseInt(totalPromotersSum) / parseInt(totalReviewLast30Days);
        let totalPassives1 =
          parseInt(totalPassivesSum) / parseInt(totalReviewLast30Days);
        let totalDetractors1 =
          parseInt(totalDetractorsSum) / parseInt(totalReviewLast30Days);

        thirtyDayLabelsArr.push(
          `${language === 'en' ? 'Promoters' : 'Promotoras'}\n${(
            parseFloat(totalPromoters1 ? totalPromoters1 : 0) * 100
          ).toFixed(1)}% | ${totalPromotersSum}`,
          `${language === 'en' ? 'Passives' : 'Pasivos'}\n${(
            parseFloat(totalPassives1 ? totalPassives1 : 0) * 100
          ).toFixed(1)}% | ${totalPassivesSum}`,
          `${language === 'en' ? 'Detractors' : 'Detractoras'}\n${(
            parseFloat(totalDetractors1 ? totalDetractors1 : 0) * 100
          ).toFixed(1)}% | ${totalDetractorsSum}`
        );

        setThirtyDaysLabels(thirtyDayLabelsArr);

        //+++++++NPS For <Trans>Year</Trans> to date
        let totalReviewYTD =
          totalPromotersYTDSum + totalPassivesYTDSum + totalDetractorsYTDSum;
        let promotersYTDPercentage =
          (totalPromotersYTDSum / totalReviewYTD) * 100;
        let detractorsYTDPercentage =
          (totalDetractorsYTDSum / totalReviewYTD) * 100;

        // Calculate the NPS
        let npsLastYTD = promotersYTDPercentage - detractorsYTDPercentage;
        if (npsLastYTD != undefined) {
          if (npsLastYTD >= 30) {
            setColorYTDNPS('#41E998'); // Green for NPS >= 30
          } else if (npsLastYTD >= -30) {
            setColorYTDNPS('#F5A623');
          } else {
            setColorYTDNPS('#E84949');
          }
        }

        setYTDNPS(npsLastYTD);

        let YTDArr = [];

        YTDArr.push(
          totalPromotersYTDSum,
          totalPassivesYTDSum,
          totalDetractorsYTDSum
        );
        setYTDSeries(YTDArr);

        //-----Labels YTD-----

        let YTDLabelsArr = [];

        let totalPromoters =
          parseInt(totalPromotersYTDSum) / parseInt(totalReviewYTD);
        let totalPassives =
          parseInt(totalPassivesYTDSum) / parseInt(totalReviewYTD);
        let totalDetractors =
          parseInt(totalDetractorsYTDSum) / parseInt(totalReviewYTD);
        YTDLabelsArr.push(
          `${language === 'en' ? 'Promoters' : 'Promotoras'}\n${(
            parseFloat(totalPromoters ? totalPromoters : 0) * 100
          ).toFixed(1)}% | ${totalPromotersYTDSum}`,
          `${language === 'en' ? 'Passives' : 'Pasivos'}\n${(
            parseFloat(totalPassives ? totalPassives : 0) * 100
          ).toFixed(1)}% | ${totalPassivesYTDSum}`,
          `${language === 'en' ? 'Detractors' : 'Detractoras'}\n${(
            parseFloat(totalDetractors ? totalDetractors : 0) * 100
          ).toFixed(1)}% | ${totalDetractorsYTDSum}`
        );

        setYTDLabels(YTDLabelsArr);
      });
  }, [oneDTPData, thirtyDaysNPS, ytdNPS]);

  // Calculate the series last 30d data for the chart\\

  //+++++++NPS For last 30 Days

  const optionsLast30d = {
    chart: {
      type: 'donut',
      width: '200px',
      height: '200px',
      borderWidth: 15,
    },
    colors: ['#41E998', '#A2A39E', '#E84949'],
    stroke: {
      width: 0,
      lineCap: 'round',
    },
    labels: thirtyDaysLabels,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              formatter: function () {
                return `${thirtyDaysNPS ? thirtyDaysNPS.toFixed(0) : 0}`;
              },
            },
            value: {
              fontSize: '45px',
              fontWeight: 'bold',
              offsetY: 15,
              color: color30Days,
            },
            name: {
              show: false,
              fontSize: '10px',
              offsetY: -0,
              color: '#000',
            },
          },
          size: '85%',
        },
        spacing: 1,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 280,
          },
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
      {
        breakpoint: 9999,
        options: {
          chart: {
            height: 350,
            width: 350,
          },
          legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    dataLabels: {
      style: {
        fontSize: '0px',
        fontFamily: 'Poppins, sans-serif',
      },
    },
  };

  const optionsYTD = {
    chart: {
      type: 'donut',
      width: '200px',
      height: '200px',
      borderWidth: 15,
    },
    colors: ['#41E998', '#A2A39E', '#E84949'],
    stroke: {
      width: 0,
      lineCap: 'round',
    },
    labels: YTDLabels,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true, // Hide the "Total" text
              formatter: function (val) {
                return `${ytdNPS ? ytdNPS.toFixed(0) : 0}`; // Display the NPS value with a percentage symbol
              },
            },
            value: {
              fontSize: '45px',
              fontWeight: 'bold',
              offsetY: 15,
              color: colorYTDNPS,
            },
            name: {
              show: false,
              fontSize: '10px',
              offsetY: -0,
              color: '#000',
            },
          },
          size: '85%',
        },
        spacing: 1,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 280,
          },
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
      {
        breakpoint: 9999,
        options: {
          chart: {
            height: 350,
            width: 350,
          },
          legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    dataLabels: {
      style: {
        fontSize: '0px',
        fontFamily: 'Poppins, sans-serif',
      },
    },
  };

  //===============Chart Pie for last 30 days && <Trans>Year</Trans> to Date now END ============= ======

  //===============LAST SEVEN DAYS AS WELL RANGE PIE START ============= =================

  let [sevenDaySeries, setSevenDaysSeries] = useState([]);
  let [sevenDayLabels, setSevenDaysLabels] = useState([]);
  const [sevenDayNPS, setSevenDayNPS] = useState();
  const [colorSevenDayNPS, setColorSevenDayNPS] = useState();

  useEffect(() => {
    const startDateLast7Days = new Date();
    startDateLast7Days.setDate(startDateLast7Days.getDate() - 6);

    let positivesCountLast7Days = 0;
    let passivesCountLast7Days = 0;
    let negativesCountLast7Days = 0;

    oneDTPData?.npsData?.forEach((Bdtp) => {
      Bdtp.reviews.forEach((review) => {
        const reviewDate = new Date(review.createdAt); // Assuming review.createdAt is the review date
        if (reviewDate >= startDateLast7Days) {
          if (review.rating === 3) {
            positivesCountLast7Days++;
          } else if (review.rating === 2) {
            passivesCountLast7Days++;
          } else if (review.rating === 1) {
            negativesCountLast7Days++;
          }
        }
      });
    });

    let overAll7ds =
      positivesCountLast7Days +
      passivesCountLast7Days +
      negativesCountLast7Days;

    let promoters7DsPercentage = (positivesCountLast7Days / overAll7ds) * 100;
    let detractors7DsPercentage = (negativesCountLast7Days / overAll7ds) * 100;

    // Calculate the NPS of 7 Days
    let npsLast7Ds = promoters7DsPercentage - detractors7DsPercentage;
    if (npsLast7Ds != undefined) {
      if (npsLast7Ds >= 30) {
        setColorSevenDayNPS('#41E998'); // Green for NPS >= 30
      } else if (npsLast7Ds >= -30) {
        setColorSevenDayNPS('#F5A623');
      } else {
        setColorSevenDayNPS('#E84949');
      }
    }

    setSevenDayNPS(npsLast7Ds);

    setSevenDaysSeries([
      positivesCountLast7Days,
      passivesCountLast7Days,
      negativesCountLast7Days,
    ]);

    let totalPromoters =
      parseInt(positivesCountLast7Days) / parseInt(overAll7ds);
    let totalPassives = parseInt(passivesCountLast7Days) / parseInt(overAll7ds);
    let totalDetractors =
      parseInt(negativesCountLast7Days) / parseInt(overAll7ds);

    setSevenDaysLabels([
      `${language === 'en' ? 'Promoters' : 'Promotoras'}\n${(
        parseFloat(totalPromoters ? totalPromoters : 0) * 100
      ).toFixed(1)}% | ${positivesCountLast7Days}`,
      `${language === 'en' ? 'Passives' : 'Pasivos'}\n${(
        parseFloat(totalPassives ? totalPassives : 0) * 100
      ).toFixed(1)}% | ${passivesCountLast7Days}`,
      `${language === 'en' ? 'Detractors' : 'Detractoras'}\n${(
        parseFloat(totalDetractors ? totalDetractors : 0) * 100
      ).toFixed(1)}% | ${negativesCountLast7Days}`,
    ]);
  }, [oneDTPData, profile, sevenDayNPS]);

  //===Range selection
  let [rangeSeries, setRangeSeries] = useState([]); // Initialize as empty array
  let [rangeLabels, setRangeLabels] = useState([]); // Initialize as empty array
  const [rangeDayNPS, setRangeDayNPS] = useState();
  const [colorRangeNPS, setColorRangeNPS] = useState();
  const [minEndDate, setMinEndDate] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });

  const { startDate, endDate } = dateRange;
  const [multiDtp, setMultiDtp] = useState([]);
  const handleRangeChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setMinEndDate(value);
    }
    setDateRange({ ...dateRange, [e.target.name]: e.target.value });
  };
  const handleMultiDtpSelection = (e) => {
    const options = e.map((item) => ({
      value: item.value,
      label: item.label,
    }));

    setMultiDtp(options);
  };
  useEffect(() => {
    if (startDate && endDate) {
      const rangeStartDate = new Date(startDate);
      const rangeEndDate = new Date(endDate);
      rangeEndDate.setDate(rangeEndDate.getDate() + 1);

      let positivesCountRange = 0;
      let passivesCountRange = 0;
      let negativesCountRange = 0;

      oneDTPData?.npsData?.forEach((Bdtp) => {
        Bdtp.reviews.forEach((review) => {
          const reviewDate = new Date(review.createdAt); // Assuming review.createdAt is the review date
          if (reviewDate >= rangeStartDate && reviewDate < rangeEndDate) {
            if (review.rating === 3) {
              positivesCountRange++;
            } else if (review.rating === 2) {
              passivesCountRange++;
            } else if (review.rating === 1) {
              negativesCountRange++;
            }
          }
        });
      });

      const overAllRange =
        positivesCountRange + passivesCountRange + negativesCountRange;

      let promotersRangePercentage = (positivesCountRange / overAllRange) * 100;
      let detractorsRangePercentage =
        (negativesCountRange / overAllRange) * 100;

      // Calculate the NPS of Range selected Days
      let npsRangeSelected =
        promotersRangePercentage - detractorsRangePercentage;

      if (npsRangeSelected != undefined) {
        if (npsRangeSelected >= 30) {
          setColorRangeNPS('#41E998'); // Green for NPS >= 30
        } else if (npsRangeSelected >= -30) {
          setColorRangeNPS('#F5A623');
        } else {
          setColorRangeNPS('#E84949');
        }
      }

      if (!isNaN(npsRangeSelected)) {
        setRangeDayNPS(npsRangeSelected);
      } else {
        setRangeDayNPS(0);
      }

      setRangeSeries([
        positivesCountRange,
        passivesCountRange,
        negativesCountRange,
      ]);

      let totalPromoters =
        parseInt(positivesCountRange) / parseInt(overAllRange);
      let totalPassives = parseInt(passivesCountRange) / parseInt(overAllRange);
      let totalDetractors =
        parseInt(negativesCountRange) / parseInt(overAllRange);

      setRangeLabels([
        `${language === 'en' ? 'Promoters' : 'Promotoras'}\n${(
          !isNaN(parseFloat(totalPromoters ? totalPromoters : 0)) * 100
        ).toFixed(1)}% | ${positivesCountRange}`,
        `${language === 'en' ? 'Passives' : 'Pasivos'}\n${(
          !isNaN(parseFloat(totalPassives ? totalPassives : 0)) * 100
        ).toFixed(1)}% | ${passivesCountRange}`,
        `${language === 'en' ? 'Detractors' : 'Detractoras'}\n${(
          !isNaN(parseFloat(totalDetractors ? totalDetractors : 0)) * 100
        ).toFixed(1)}% | ${negativesCountRange}`,
      ]);
    }
  }, [startDate, endDate, oneDTPData, profile, rangeDayNPS]);

  const optionsLast7Days = {
    chart: {
      type: 'donut',
      width: '200px',
      height: '200px',
      borderWidth: 15,
    },
    colors: ['#41E998', '#A2A39E', '#E84949'],
    stroke: {
      width: 0,
      lineCap: 'round',
    },
    labels: sevenDayLabels,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true, // Hide the "Total" text
              formatter: function (val) {
                return `
                ${sevenDayNPS ? sevenDayNPS.toFixed(0) : 0}`; // Display the NPS value with a percentage symbol
              },
            },
            value: {
              fontSize: '45px',
              fontWeight: 'bold',
              offsetY: 15,
              color: colorYTDNPS,
            },
            name: {
              show: false,
              fontSize: '10px',
              offsetY: -0,
              color: '#000',
            },
          },
          size: '85%',
        },
        spacing: 1,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 280,
          },
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
      {
        breakpoint: 9999,
        options: {
          chart: {
            height: 350,
            width: 350,
          },
          legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    dataLabels: {
      style: {
        fontSize: '0px',
        fontFamily: 'Poppins, sans-serif',
      },
    },
  };

  const optionsSelectedRange = {
    chart: {
      type: 'donut',
      width: '200px',
      height: '200px',
      borderWidth: 15,
    },
    colors: ['#41E998', '#A2A39E', '#E84949'],
    stroke: {
      width: 0,
      lineCap: 'round',
    },
    labels: rangeLabels,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true, // Hide the "Total" text
              formatter: function (val) {
                return `${rangeDayNPS ? rangeDayNPS.toFixed(0) : 0}`;
              },
            },
            value: {
              fontSize: '45px',
              fontWeight: 'bold',
              offsetY: 15,
              color: colorYTDNPS,
            },
            name: {
              show: false,
              fontSize: '10px',
              offsetY: -0,
              color: '#000',
            },
          },
          size: '85%',
        },
        spacing: 1,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 280,
          },
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
      {
        breakpoint: 9999,
        options: {
          chart: {
            height: 350,
            width: 350,
          },
          legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    dataLabels: {
      style: {
        fontSize: '0px',
        fontFamily: 'Poppins, sans-serif',
      },
    },
  };
  //===============LAST SEVEN DAYS AS WELL RANGE PIE ENDED ============= =================

  //===============REASON BAR FOR THE DTP START ============= =================
  const [barSeries, setBarSeries] = useState([]);
  const { allBusinessDTP, allBusinessData } = useSelector((state) => {
    return state.auth;
  });
  const benchmarkNPSData =
    allBusinessDTP?.benchMarkData &&
    allBusinessDTP?.benchMarkData?.map((item) => {
      return {
        label: item.name,
        data: item.npsData.map((dataPoint) => dataPoint.overAllNps),
        // borderColor: getRandomColor(),
        // backgroundColor: getRandomColor(),
        hidden: false,
      };
    });
  const [filterData, setFilterData] = useState('hour');
  useEffect(() => {
    let updatedBarSeries = [];

    const overallTop5Reasons = oneDTPData?.npsData?.reduce(
      (acc, dataTouchPoint) => {
        dataTouchPoint.top5Reasons.forEach((reason) => {
          const existingReason = acc.find((r) => r.name === reason.name);
          if (existingReason) {
            existingReason.data = existingReason?.data.map(
              (value, index) => value + reason.data[index]
            );
          } else {
            acc.push({ name: reason.name, data: reason.data });
          }
        });
        return acc;
      },
      []
    );

    updatedBarSeries = overallTop5Reasons?.slice(0, 5);
    setBarSeries(updatedBarSeries);
  }, [oneDTPData?.npsData]);
  const chartOptions = {
    chart: {
      type: 'line',
      height: 350,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 4, // Set the line width
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        style: {
          colors: '#8e8da4',
        },
        offsetY: -7,
        offsetX: 0,
      },
    },
    yaxis: {
      min: -100,
      max: 100,
      tickAmount: 10, // You can adjust the number of ticks as needed
      labels: {
        style: {
          colors: '#8e8da4',
        },
        offsetY: -7,
        offsetX: 0,
        formatter: function (value) {
          if (value !== undefined) {
            return value.toFixed(0); // Ensure no decimal points
          }
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      x: {
        format: 'HH:mm', // Tooltip format for the x-axis
      },
      fixed: {
        enabled: false,
        position: 'topRight',
      },
    },
    grid: {
      show: false,
      yaxis: {
        lines: {
          offsetX: -30,
        },
      },
      padding: {
        left: 10,
      },
    },
  };
  const [barOptions] = useState({
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 280,
          },
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: false, // Disable the total dataLabels
          },
        },
      },
    },
    xaxis: {
      categories: [
        language === 'en' ? 'Promoters' : 'Promotoras',
        language === 'en' ? 'Passives' : 'Pasivos',
        language === 'en' ? 'Detractors' : 'Detractoras',
      ], // Adjust the categories
      labels: {
        show: true, // Hide x-axis labels
      },
    },
    yaxis: {
      show: false, // Hide the vertical axis
    },
    legend: {
      show: true,
      position: 'right',
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false, // Hide gridlines
    },
  });

  //===============REASON BAR FOR THE DTP END ============= =================

  //====================LOAD DTP LOCATION ON MAP START===========================

  const MapContainer = ({ cityCoordinates }) => {
    const mapRef = useRef(null);

    useEffect(() => {
      const loadMapScript = () => {
        if (!window.google || !window.google.maps || !mapRef.current) {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCAmVYL3WPlOgib6t-e79DJbUs8dRWzjYY&callback=initMap`;
          script.async = true;
          script.defer = true;
          window.initMap = initMap;
          document.body.appendChild(script);
        } else {
          initMap();
        }
      };
      const initMap = () => {
        const map = new window.google.maps.Map(mapRef.current, {
          center: { lat: 40.4637, lng: -3.7492 }, // Default center coordinates
          zoom: 4,
        });

        cityCoordinates &&
          cityCoordinates.forEach((city) => {
            const marker = new window.google.maps.Marker({
              position: { lat: city.lat, lng: city.lng },
              map: map,
              title: city,
            });
          });
      };

      loadMapScript();
    }, [cityCoordinates]);

    return <div ref={mapRef} style={{ height: '100%', width: '100%' }}></div>;
  };
  const [timeFrameLabel, setTimeFrameLabel] = useState([]);
  const [timeFrameData, setTimeFrameData] = useState([]);
  const [compTimeFrameData, setCompTimeFrameData] = useState([]);
  const [multiDtpData, setMultiDtpData] = useState();
  const benchmarkData = benchmarkNPSData?.map((dataSet) => ({
    name: dataSet.label,
    data: timeFrameLabel.map((label, index) => ({
      x: label,
      y: dataSet.data[index],
    })),
  }));

  const getData = async () => {
    try {
      const response = await api.post('/review/getMultiDtpData', {
        verificationCode,
        dtpIds: multiDtp,
        // businessID: businessId,
      });
      if (response?.data) {
        setMultiDtpData(response?.data);
      }
    } catch (error) {
      console.error('Error :', error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (multiDtp !== undefined) {
      if (
        multiDtpData &&
        multiDtpData?.result?.hourWiseNps &&
        filterData === 'hour'
      ) {
        setTimeFrameLabel(multiDtpData?.result?.hourWiseNps?.name);
        setTimeFrameData(multiDtpData?.result?.hourWiseNps?.data);
      }
      if (
        multiDtpData &&
        multiDtpData?.result?.dayWiseNps &&
        filterData === 'daily'
      ) {
        setTimeFrameLabel(multiDtpData?.result?.dayWiseNps?.name);
        setTimeFrameData(multiDtpData?.result?.dayWiseNps?.data);
      }
      if (
        multiDtpData &&
        multiDtpData?.result?.weekWiseNps &&
        filterData === 'week'
      ) {
        setTimeFrameLabel(multiDtpData?.result?.weekWiseNps?.name);
        setTimeFrameData(multiDtpData?.result?.weekWiseNps?.data);
      }
      if (
        multiDtpData &&
        multiDtpData?.result?.monthWiseNps &&
        filterData === 'month'
      ) {
        setTimeFrameLabel(multiDtpData?.result?.monthWiseNps?.name);
        setTimeFrameData(multiDtpData?.result?.monthWiseNps?.data);
      }
      if (
        multiDtpData &&
        multiDtpData?.result?.yearWiseNps &&
        filterData === 'year'
      ) {
        setTimeFrameLabel(multiDtpData?.result?.yearWiseNps?.name);
        setTimeFrameData(multiDtpData?.result?.yearWiseNps?.data);
      }
      //+++++++COmpany
      if (multiDtpData && multiDtpData?.companyResult) {
        if (
          multiDtpData &&
          multiDtpData?.companyResult?.hourWiseNps &&
          filterData === 'hour'
        ) {
          // setTimeFrameLabel(multiDtpData?.companyResult?.hourWiseNps?.name);
          setCompTimeFrameData(multiDtpData?.companyResult?.hourWiseNps?.data);
        }

        if (
          multiDtpData &&
          multiDtpData?.companyResult?.dayWiseNps &&
          filterData === 'daily'
        ) {
          // setTimeFrameLabel(multiDtpData?.companyResult?.dayWiseNps?.name);
          setCompTimeFrameData(multiDtpData?.companyResult?.dayWiseNps?.data);
        }
        if (
          multiDtpData &&
          multiDtpData?.companyResult?.weekWiseNps &&
          filterData === 'week'
        ) {
          // setTimeFrameLabel(multiDtpData?.companyResult?.weekWiseNps?.name);
          setCompTimeFrameData(multiDtpData?.companyResult?.weekWiseNps?.data);
        }
        if (
          multiDtpData &&
          multiDtpData?.companyResult?.monthWiseNps &&
          filterData === 'month'
        ) {
          // setTimeFrameLabel(multiDtpData?.companyResult?.monthWiseNps?.name);
          setCompTimeFrameData(multiDtpData?.companyResult?.monthWiseNps?.data);
        }
        if (
          multiDtpData &&
          multiDtpData?.companyResult?.yearWiseNps &&
          filterData === 'year'
        ) {
          // setTimeFrameLabel(multiDtpData?.companyResult?.yearWiseNps?.name);
          setCompTimeFrameData(multiDtpData?.companyResult?.yearWiseNps?.data);
        }
      }
    }
  }, [multiDtpData, filterData]);
  // Format your data for different datasets

  const convertToLocalTimeZone = (date) => {
    const localDate = new Date(date); // Convert to local time zone
    return format(localDate, 'dd-MM-yyyy HH:mm', {
      useAdditionalWeekYearTokens: true,
    });
  };

  const sliceIndex = Math.max(0, timeFrameLabel.length - 15);
  const slicedTimeFrameLabel = timeFrameLabel.slice(sliceIndex);

  const southData = slicedTimeFrameLabel.map((label, index) => ({
    x: convertToLocalTimeZone(label),
    // x: label,
    y: compTimeFrameData[index + sliceIndex],
  }));

  const northData = slicedTimeFrameLabel.map((label, index) => ({
    x: convertToLocalTimeZone(label),
    y: timeFrameData[index + sliceIndex],
  }));

  // const northData = timeFrameLabel.map((label, index) => ({
  //   x: label,
  //   y: timeFrameData[index],
  // }));

  // const southData = timeFrameLabel.map((label, index) => ({
  //   x: label,
  //   y: compTimeFrameData[index],
  // }));

  const chartSeries = [
    {
      name: (multiDtp?.length !== 0 && multiDtp.map((d) => d.label)) || '',
      data: northData,
    },
    {
      name: 'Selected Business',
      data: southData,
    },
    ...(Array.isArray(benchmarkData) ? benchmarkData : []), // Ensure benchmarkData is an array
  ];

  function MapComponent() {
    const [cityCoordinates, setCityCoordinates] = useState([]);
    // useEffect(() => {
    //   const geocoder = new window.google.maps.Geocoder();
    //   const fetchSpecificCoordinates = async () => {
    //     if (profile !== "") {
    //       const newCoordinates = await new Promise((resolve) => {
    //         geocoder.geocode({ address: profile?.city }, (results, status) => {
    //           if (status === "OK") {
    //             resolve([
    //               {
    //                 name:
    //                   profile?.city +
    //                   " " +
    //                   `(${oneDTPData?.npsData[0]?.npsLast30Days?.npsLast30Days.toFixed(
    //                     0
    //                   )} NPS)`,
    //                 lat: results[0].geometry.location.lat(),
    //                 lng: results[0].geometry.location.lng(),
    //               },
    //             ]);
    //           } else {
    //             resolve([]);
    //           }
    //         });
    //       });

    //       setCityCoordinates(newCoordinates);
    //     }
    //   };
    //   fetchSpecificCoordinates();
    // }, [profile]);
    return (
      <>
        {cityCoordinates && <MapContainer cityCoordinates={cityCoordinates} />}
      </>
    );
  }
  //====================LOAD DTP LOCATION ON MAP END===========================
  const optionsChart = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: <Trans>NPS</Trans>,
      },
    },
    scales: {
      y: {
        suggestedMin: -0, // Set the minimum value of y-axis
        suggestedMax: 100, // Set the maximum value of y-axis
      },
    },
    height: '100%',
    width: '100%',
  };

  //====================Result Data to display alex=================
  let totalNPS = oneDTPData && oneDTPData?.PPL30Days - oneDTPData?.DPL30Days;

  const [showResultPopup, setShowResultPopup] = useState(false);
  const handleShowResult = () => {
    setShowResultPopup(true);
  };

  //====================QR code Download alex=================
  const componentRef = useRef();

  const [showQRCode, setShowQRCode] = useState(false);
  const [qrData, setQRData] = useState(null);

  const handleShowQRCode = () => {
    if (oneDTPData && oneDTPData?.dataTouchPoint) {
      if (oneDTPData?.dataTouchPoint?.dtpType === 'Customer Experience') {
        setQRData(oneDTPData?.dataTouchPoint?.business?.customerDefaultQr);
      } else {
        setQRData(oneDTPData?.dataTouchPoint?.business?.employeeDefaultQr);
      }
    }
    setShowQRCode(true);
  };

  return (
    <>
      <div className="container-fluid py-3">
        <div className="row g-3">
          <div className="col-md-4">
            <h1 className="dashboard mb-4 fw-500 d-flex align-items-center">
              <Trans>Dashboard</Trans>
            </h1>
          </div>
          <div className="col-md-2">
            {/* <Link
              to="#"
              className="btn btn-primary mb-3 w-100"
              style={{ borderRadius: '20px' }}
            >
              QR Stickers
            </Link> */}
          </div>
          <div className="col-md-2">
            <Link
              to={`/result/${profile?.slug}`}
              className="btn btn-primary mb-3 w-100"
              style={{ borderRadius: '20px' }}
              // onClick={handleShowResult}
            >
              Result Display
            </Link>
          </div>
          <div className="col-md-2">
            <Link
              to="#"
              className="btn btn-secondary text-nowrap w-100"
              onClick={handleShowQRCode}
            >
              <FontAwesomeIcon icon={faDownload} className="btnIcon" />
              QR Code
            </Link>
          </div>
          <div className="col-md-1">
            <select onChange={(e) => setLanguage(e.target.value)}>
              <option value={'en'} selected={language == 'en'}>
                English
              </option>
              <option value={'es'} selected={language == 'es'}>
                Spanish
              </option>
            </select>
          </div>
          {associatedDTP.length > 0 && (
            <div className="col-12 col-lg-4">
              <div className="form-group">
                <label htmlFor="associated">Associated Data Touchpoint</label>
                <select
                  className="form-control"
                  value={selectDTP}
                  onChange={(e) => setSelectDTP(e.target.value)}
                >
                  <option value="">
                    <Trans>Select from list</Trans>...
                  </option>
                  {associatedDTP &&
                    associatedDTP.length > 0 &&
                    associatedDTP.map((sec, i) => (
                      <option key={i} value={sec?.slug}>
                        {sec?.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
        </div>
        <div className="row my-2">
          <div className="col-md-6 col-lg-6 mb-2">
            <div className="chart-div card shadow-sm">
              <div className="card-header">
                <h5 className="mb-0">
                  <Trans>NPS Last 30 days</Trans>
                </h5>
              </div>
              <div
                id="chart"
                className="small-chart card-body OverFlow p-1  mx-auto mt-1"
              >
                <ReactApexChart
                  options={optionsLast30d}
                  series={thirtyDaySeries}
                  type="donut"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 mb-2">
            <div className="chart-div card shadow-sm">
              <div className="card-header">
                <h5 className="mb-0">
                  <Trans>NPS YTD</Trans>
                </h5>
              </div>
              <div
                id="chart"
                className="small-chart card-body OverFlow p-1  mx-auto mt-1"
              >
                <ReactApexChart
                  options={optionsYTD}
                  series={YTDSeries}
                  type="donut"
                />
              </div>
            </div>
          </div>

          <hr className="mt-3" />
        </div>

        {/* LAST 30 DAYS AND YEAR TO DATE GRAPH END */}

        {/* NPS AND REASON GRAPG START */}

        <div className="row">
          <div className="col-md-6 mb-2">
            <div className="card p-0 card-height shadow-sm">
              <div className="card-header">
                <h4 className="mb-0">NPS</h4>
              </div>
              <div className="card-body">
                <div className="chart-div mb-3">
                  <div className="chartContent">
                    <div className="chartContent">
                      <div className="mob-flex" id="filer">
                        <div className="col">
                          <div className="form-group">
                            <input
                              type="date"
                              className="form-control m-0"
                              name="startDate"
                              value={startDate}
                              onChange={handleRangeChange}
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <input
                              type="date"
                              className="form-control m-0"
                              name="endDate"
                              value={endDate}
                              min={minEndDate}
                              onChange={handleRangeChange}
                              style={{ height: '42px' }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h6>
                  {' '}
                  {startDate &&
                    endDate &&
                    moment(startDate).format('DD-MM-YYYY') +
                      ' -- ' +
                      moment(endDate).format('DD-MM-YYYY')}{' '}
                </h6>
                <div
                  id="chart"
                  className="small-chart card-body p-1 mx-auto mt-0"
                >
                  {startDate && endDate ? (
                    <ReactApexChart
                      options={optionsSelectedRange}
                      series={rangeSeries}
                      type="donut"
                    />
                  ) : (
                    <ReactApexChart
                      options={optionsLast7Days}
                      series={sevenDaySeries}
                      type="donut"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-2">
            <div className="card p-0 card-height shadow-sm">
              <div className="card-header">
                <h4 className="mb-0">
                  <Trans>Reason of Votes</Trans>
                </h4>
              </div>
              <div className="card-body">
                <div
                  id="Treechart"
                  className="small-chart card-body OverFlow p-1  mx-auto mt-1"
                >
                  <ReactApexChart
                    options={barOptions}
                    series={barSeries}
                    type="bar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* NPS AND REASON GRAPG END */}

        {/* Graph and Map START*/}
        {/* <div className="row my-3">
          <div className="col-12 col-md-12 mb-2">
            <div className="card card-height-btm shadow-sm">
              <div className="card-header">
                <h5 className="mb-0">Data Touchpoint Location</h5>
              </div>
              <div className="card-body">
                <div className="map-container">
                  <MapComponent />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="col-12 col-md-12 mb-2">
        <div className="card card-height-btm shadow-sm">
          <div className="card-header mb-0">
            <div className="mbl-graph ">
              <div>
                <h5 className="mb-0">
                  <Trans>NPS Evolution</Trans>
                </h5>
              </div>
              <div>
                <div className="d-flex justify-content-center gap-2">
                  <button
                    onClick={() => setFilterData('hour')}
                    className={
                      filterData === 'hour' ? 'btn-chart active' : 'btn-chart'
                    }
                  >
                    <Trans>Hour</Trans>
                  </button>
                  <button
                    onClick={() => setFilterData('daily')}
                    className={
                      filterData === 'daily' ? 'btn-chart active' : 'btn-chart'
                    }
                  >
                    <Trans>Day</Trans>
                  </button>
                  <button
                    onClick={() => setFilterData('week')}
                    className={
                      filterData === 'week' ? 'btn-chart active' : 'btn-chart'
                    }
                  >
                    <Trans>Week</Trans>
                  </button>
                  <button
                    onClick={() => setFilterData('month')}
                    className={
                      filterData === 'month' ? 'btn-chart active' : 'btn-chart'
                    }
                  >
                    <Trans>Month</Trans>
                  </button>
                  <button
                    onClick={() => setFilterData('year')}
                    className={
                      filterData === 'year' ? 'btn-chart active' : 'btn-chart'
                    }
                  >
                    <Trans>Year</Trans>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="mbl-top">
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type="line"
                height={350}
              />

              {/* <Line options={optionsChart} data={data} /> */}
            </div>
          </div>
        </div>
      </div>

      {/* //======================ADD MORE BENCHMARK REQUEST Start ====================== */}
      <Modal show={showResultPopup} onHide={() => setShowResultPopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>NPS 30 Days</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div id="result" className="reviewsection">
              <p className="text-center">
                <Trans>NPS Last 30 Days</Trans>
              </p>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div
                    className=" d-flex align-items-center justify-content-center mx-auto"
                    style={{
                      width: '250px',
                      height: '250px',
                      backgroundColor:
                        totalNPS < 0
                          ? 'red'
                          : totalNPS >= 0 && totalNPS < 30
                          ? 'orange'
                          : totalNPS >= 30 && totalNPS < 50
                          ? '#ecec46'
                          : totalNPS >= 50 && totalNPS < 70
                          ? 'lightgreen'
                          : totalNPS >= 70 && totalNPS <= 100
                          ? 'green'
                          : 'black',
                    }}
                  >
                    {totalNPS !== null && (
                      <h1
                        style={{
                          fontSize: '100px', // Adjust the font size as needed
                          color: 'white',
                        }}
                      >
                        {totalNPS}
                      </h1>
                    )}
                  </div>
                </div>
              </div>
              <p className="text-black text-center mt-3">
                <img
                  src={
                    BASE_URL_IMAGES + oneDTPData?.dataTouchPoint?.business?.logo
                  }
                  alt="Logo"
                  onError={(e) => (e.target.src = '/assets/user.png')}
                  className=" img-fluid d-block m-auto reviewImglogo"
                  style={{
                    width: '150px',
                    height: '60px',
                    textAlign: 'center',
                    objectFit: 'cover',
                  }}
                />
              </p>

              <p className="text-black text-center">
                Powered By:{' '}
                <img
                  src={logoBlue}
                  alt="Our Logo"
                  className="img-fluid d-block m-auto reviewImglogo"
                />
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* //======================ADD MORE BENCHMARK REQUEST End ====================== */}

      {/* //======================QR Code View Start alex====================== */}
      <Modal show={showQRCode} onHide={() => setShowQRCode(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>QR Code</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {qrData && (
              <div className="row " ref={componentRef}>
                <div
                  className="col-12 col-md-8 mx-auto section-to-print"
                  style={{
                    backgroundColor: qrData.bgColor,
                    // width: "200px",
                    // height: "400px",
                  }}
                >
                  <div className="text-center">
                    <h6
                      style={{
                        color: qrData?.fgColor,
                        fontSize: `${qrData?.fgTextSize}px`,
                      }}
                    >
                      {qrData?.fgText}
                    </h6>
                    <div className="">
                      <img
                        src={REACT_APP_BASE_URL_QR_CODE + qrData?.qrCode}
                        alt="QR code"
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      />
                      <span
                        className="vertical-text vertical-text"
                        style={{
                          color: qrData?.fgColor,
                        }}
                      >
                        {qrData?.Name}
                      </span>
                      {/* <span
                        className="vertical-text"
                        style={{
                          color: qrData?.fgColor,
                        }}
                      >
                        {qrData?.name}
                      </span> */}
                    </div>
                    <div className="">
                      <img
                        src={BASE_URL_IMAGES + qrData?.companyLogo}
                        // width={120}
                        // height={60}
                        alt="QR code"
                        className="img-responsive w-50 qr-logo-img"
                        // style={{
                        //   objectFit: "cover",
                        // }}
                      />
                    </div>
                    <div className="mt-1">
                      <h6 className="mb-0">Powered By</h6>
                      <div className="text-center">
                        <img
                          src={
                            qrData?.poweredByLogo === 'white'
                              ? logoWhite
                              : qrData?.poweredByLogo === 'blue'
                              ? logoBlue
                              : qrData?.poweredByLogo === 'black'
                              ? logoBlack
                              : null
                          }
                          alt="Our Logo"
                          className="img-fluid w-50 text-center"
                          // style={{
                          //   objectFit: "cover",
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ReactToPrint
            trigger={() => <button className="btn btn-primary">Print</button>}
            content={() => componentRef.current}
          />
        </Modal.Footer>
      </Modal>
      {/* //======================QR Code View End ====================== */}
    </>
  );
};

export default SDash;
