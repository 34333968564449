import api, { apiWithFormData } from "../../utility/api";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// add Review ANYONE
const addReview = async (reviewData) => {
  const response = await axios.post(`${BASE_URL}/review/create `, reviewData);
  return response;
};

//===========Get All DTPs Reports======================
const allDtpReports = async (data) => {
  const response = await api.post("/review/getAllDataTouchPointsNPS", data);
  return response;
};

const reviewService = {
  addReview,
  allDtpReports,
};

export default reviewService;
