import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";
import { toast } from "react-toastify";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const token = localStorage.getItem("token");
const profile = JSON.parse(localStorage.getItem("profile"));

const initialState = {
  token: token ? token : null,
  isAuthenticated: token ? true : false,
  showBench: true,
  profile: profile ? profile : null,
  allAdmins: [],
  allBusinessData: [],
  oneBusinessData: {},
  allBusinessDTP: [], //=====Get only for business DTP
  allSubRequests: [],
  oneDTPData: {},
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  isLoadingBusinessNote:false,
  isLoadingCreateNewBusiness:false,
  isLoadingCreateAdmin:false,
  isLoadingCreateBusinessDPT:false,
  isLoadingAddUserDTP:false,
  isLoadingAvailSubscription:false,
  isLoadingUpdateCompanyCardDetails:false,
  SuccessUpdateDefaultCustQR:false,
  loadingUpdateAdminSettings:false,
  isSuccessUpdateProfile:false
};

// Login user
export const userLogin = createAsyncThunk(
  "auth/login",
  async (user, thunkAPI) => {
    try {
      const res = await authService.login(user);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Admins
export const getAllAdmins = createAsyncThunk(
  "auth/getAllAdmins",
  async (data, thunkAPI) => {
    try {
      const res = await authService.getAllAdmins(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Create New Admin
export const createAdmin = createAsyncThunk(
  "auth/createAdmin",
  async (user, thunkAPI) => {
    try {
      await authService.createAdmin(user);
      const res = await authService.getAllAdmins({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Delete  Admin
export const deleteAdmin = createAsyncThunk(
  "auth/deleteAdmin",
  async (user, thunkAPI) => {
    try {
      await authService.deleteAdmin(user);
      const res = await authService.getAllAdmins({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update Admin Settings
export const updateAdminSettings = createAsyncThunk(
  "auth/updateAdminSettings",
  async (user, thunkAPI) => {
    try {
      const res = await authService.updateAdminSettings(user);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update default Customer Questions by  Admin  (alex)
export const customerDefaultVotingAdmin = createAsyncThunk(
  "auth/customerDefaultVotingAdmin",
  async (data, thunkAPI) => {
    try {
      const res = await authService.customerDefaultVotingAdmin(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update default Employee Questions by  Admin  (alex)
export const employeeDefaultVotingAdmin = createAsyncThunk(
  "auth/employeeDefaultVotingAdmin",
  async (data, thunkAPI) => {
    try {
      const res = await authService.employeeDefaultVotingAdmin(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//=======================================BUSINESS APIs START alex=================================

// Business registration
export const businessRegistration = createAsyncThunk(
  "auth/businessRegistration",
  async (user, thunkAPI) => {
    try {
      const res = await authService.businessRegistration(user);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Create Business by Admin
export const createBusiness = createAsyncThunk(
  "auth/createBusiness",
  async (user, thunkAPI) => {
    try {
      await authService.createBusiness(user);

      const res = await authService.getAllBusinesses({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Sign Up Business by Admin
export const signupBusiness = createAsyncThunk(
  "auth/signupBusiness",
  async (user, thunkAPI) => {
    try {
      const res = await authService.signupBusiness(user);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Businesses for Admin
export const getAllBusinesses = createAsyncThunk(
  "auth/getAllBusinesses",
  async (data, thunkAPI) => {
    try {
      const res = await authService.getAllBusinesses(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update Approve status by Admin
export const approveBusinessStatus = createAsyncThunk(
  "auth/approveBusinessStatus",
  async (data, thunkAPI) => {
    try {
      await authService.approveBusinessStatus(data);
      const res = await authService.getAllBusinesses({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update disApproveBusiness status by Admin

export const disApproveBusiness = createAsyncThunk(
  "auth/disApproveBusiness",
  async (data, thunkAPI) => {
    try {
      await authService.disApproveBusiness(data);
      const res = await authService.getAllBusinesses({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update Business Settings (Can be from business or can Admin)
export const updateCompanySettings = createAsyncThunk(
  "auth/updateCompanySettings",
  async (data, thunkAPI) => {
    try {
      const res = await authService.updateCompanySettings(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update Business Settings (Can be from business or can Admin)
export const updateCompanyCardDetails = createAsyncThunk(
  "auth/updateCompanyCardDetails",
  async (data, thunkAPI) => {
    try {
      const res = await authService.updateCompanyCardDetails(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Edit Business Settings ONLY ADMIN
export const editCompanySettings = createAsyncThunk(
  "auth/editCompanySettings",
  async (data, thunkAPI) => {
    try {
      await authService.editCompanySettings(data);
      const res = await authService.getAllBusinesses({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Delete Business by Admin
export const deleteBusiness = createAsyncThunk(
  "auth/deleteBusiness",
  async (data, thunkAPI) => {
    try {
      await authService.deleteBusiness(data);
      const res = await authService.getAllBusinesses({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Create Business Note by Admin
export const createBusineeNote = createAsyncThunk(
  "auth/createBusineeNote",
  async (data, thunkAPI) => {
    try {
      const res = await authService.createBusineeNote(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get One Business Data
export const getOneBusiness = createAsyncThunk(
  "auth/getOneBusiness",
  async (data, thunkAPI) => {
    try {
      const res = await authService.getOneBusiness(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update Business subscription by business (later may be Admin)
export const updateBusinessSub = createAsyncThunk(
  "auth/updateBusinessSub",
  async (data, thunkAPI) => {
    try {
      const res = await authService.updateBusinessSub(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update Business BenchMark by business (later may be Admin)
export const updateBusinessBenchMark = createAsyncThunk(
  "auth/updateBusinessBenchMark",
  async (data, thunkAPI) => {
    try {
      const res = await authService.updateBusinessBenchMark(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update default Customer Questions by business (later may be Admin)
export const updateDefaultCusQuestion = createAsyncThunk(
  "auth/updateDefaultCusQuestion",
  async (data, thunkAPI) => {
    try {
      const res = await authService.updateDefaultCusQuestion(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update default Employee Questions by business (later may be Admin)
export const updateDefaultEmpQuestion = createAsyncThunk(
  "auth/updateDefaultEmpQuestion",
  async (data, thunkAPI) => {
    try {
      const res = await authService.updateDefaultEmpQuestion(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update default Employee QR Code by business (later may be Admin)
export const updateDefaultEmpQR = createAsyncThunk(
  "auth/updateDefaultEmpQR",
  async (data, thunkAPI) => {
    try {
      const res = await authService.updateDefaultEmpQR(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update default Customer QR Code by business (later may be Admin)
export const updateDefaultCustQR = createAsyncThunk(
  "auth/updateDefaultCustQR",
  async (data, thunkAPI) => {
    try {
      const res = await authService.updateDefaultCustQR(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update default Customer Reasons by business (later may be Admin)
export const updateCusReason = createAsyncThunk(
  "auth/updateCusReason",
  async (data, thunkAPI) => {
    try {
      const res = await authService.updateCusReason(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update default Employee Reasons by business (later may be Admin)
export const updateEmpReason = createAsyncThunk(
  "auth/updateEmpReason",
  async (data, thunkAPI) => {
    try {
      const res = await authService.updateEmpReason(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//====================Default Question for Reason-----------------
export const updateQuestionReason = createAsyncThunk(
  "auth/updateQuestionReason",
  async (data, thunkAPI) => {
    try {
      const res = await authService.updateQuestionReason(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//===========Data touchpoints API START =================================

// Get All datatouchpoints For business
export const getAllBusinessDTP = createAsyncThunk(
  "auth/getAllBusinessDTP",
  async (data, thunkAPI) => {
    try {
      const res = await authService.getAllBusinessDTP(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//==========Create TouchPoint by Business
export const createBusinessDTP = createAsyncThunk(
  "auth/createBusinessDTP",
  async (data, thunkAPI) => {
    try {
      await authService.createBusinessDTP(data);
      const res = await authService.getAllBusinessDTP({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//==========Add user to DTP
export const addUserToDTP = createAsyncThunk(
  "auth/addUserToDTP",
  async (data, thunkAPI) => {
    try {
      await authService.addUserToDTP(data);
      const res = await authService.getAllBusinessDTP({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//==========Delete user to DTP
export const deleteUserToDTP = createAsyncThunk(
  "auth/deleteUserToDTP",
  async (data, thunkAPI) => {
    try {
      await authService.deleteUserToDTP(data);
      const res = await authService.getAllBusinessDTP({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update Approve status for Data Touchpoint by Admin/business
export const approveDTPStatus = createAsyncThunk(
  "auth/approveDTPStatus",
  async (data, thunkAPI) => {
    try {
      await authService.approveDTPStatus(data);
      const res = await authService.getAllBusinessDTP({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get One Data Touch Point Details for reviews
export const getOneDTP = createAsyncThunk(
  "auth/getOneDTP",
  async (data, thunkAPI) => {
    try {
      const res = await authService.getOneDTP(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Delete Data Touchpoint by business
export const deleteDTP = createAsyncThunk(
  "auth/deleteDTP",
  async (data, thunkAPI) => {
    try {
      await authService.deleteDTP(data);
      const res = await authService.getAllBusinessDTP({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//   availSubscription
export const availSubscription = createAsyncThunk(
  "auth/availSubscription",
  async (data, thunkAPI) => {
    try {
      const res = await authService.availSubscription(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//   getAllSubReq
export const getAllSubReq = createAsyncThunk(
  "auth/getAllSubReq",
  async (data, thunkAPI) => {
    try {
      const res = await authService.getAllSubReq(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//   updateSubReqStatus
export const updateSubReqStatus = createAsyncThunk(
  "auth/updateSubReqStatus",
  async (data, thunkAPI) => {
    try {
      const res = await authService.updateSubReqStatus(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//========Benchmark setting get =============
export const getBenchSettings = createAsyncThunk(
  "auth/getBenchSettings",
  async (data, thunkAPI) => {
    try {
      const res = await authService.getBenchSettings(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//========Benchmark setting false =============
export const benchSetToFalse = createAsyncThunk(
  "auth/benchSetToFalse",
  async (data, thunkAPI) => {
    try {
      const res = await authService.benchSetToFalse(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//========Benchmark setting True =============
export const benchSetToTrue = createAsyncThunk(
  "auth/benchSetToTrue",
  async (data, thunkAPI) => {
    try {
      const res = await authService.benchSetToTrue(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
      state.isSuccess = false;
    },
    logout: (state) => {
      state.token = null;
      state.isAuthenticated = false;
      state.isLoading = false;
      state.profile = null;
      localStorage.clear();
    },
    ResetSuccessUpdateDefaultCustQR: (state) => {
      state.isSuccessUpdateProfile = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isAuthenticated = true;
        state.token = action.payload.token;
        state.profile = action.payload;
        toast.success("Login Successfull");
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.token = null;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(getAllAdmins.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAdmins.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allAdmins = action.payload;
      })
      .addCase(getAllAdmins.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(createAdmin.pending, (state) => {
        state.isLoadingCreateAdmin = true;
      })
      .addCase(createAdmin.fulfilled, (state, action) => {
        state.isLoadingCreateAdmin = false;
        state.isSuccess = true;
        state.allAdmins = action.payload;
        toast.success("Admin successfully created");
      })
      .addCase(createAdmin.rejected, (state, action) => {
        state.isLoadingCreateAdmin = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(deleteAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allAdmins = action.payload;
        toast.success("Admin deleted successfully");
      })
      .addCase(deleteAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateAdminSettings.pending, (state) => {
        state.isLoading = true;
        state.loadingUpdateAdminSettings = true;
      })
      .addCase(updateAdminSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.loadingUpdateAdminSettings = false;
        if (action.payload?.updatedUser) {
          state.profile = action.payload.updatedUser;
        }
        if (action.payload?.allAdmins) {
          state.allAdmins = action.payload.allAdmins;
        }
        toast.success(action.payload.message);
      })
      .addCase(updateAdminSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.loadingUpdateAdminSettings = false;
        toast.error(action.payload);
      })

      // BUSINESS API STARTS

      .addCase(businessRegistration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(businessRegistration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.token = action.payload.token;
        toast.success(action.payload.msg);
      })
      .addCase(businessRegistration.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
        state.token = null;
      })
      .addCase(createBusiness.pending, (state) => {
        state.isLoadingCreateNewBusiness = true;
      })
      .addCase(createBusiness.fulfilled, (state, action) => {
        state.isLoadingCreateNewBusiness = false;
        state.isSuccess = true;
        state.allBusinessData = action.payload?.reverse();
        toast.success("Business successfully created");
      })
      .addCase(createBusiness.rejected, (state, action) => {
        state.isLoadingCreateNewBusiness = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(signupBusiness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signupBusiness.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.allBusinessData = action.payload;
        toast.success(action.payload.msg);
      })
      .addCase(signupBusiness.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })

      .addCase(getAllBusinesses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllBusinesses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allBusinessData = action.payload?.reverse();
      })
      .addCase(getAllBusinesses.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(disApproveBusiness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(disApproveBusiness.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allBusinessData = action.payload?.reverse();
        toast.success("Status updated successfully");
      })
      .addCase(disApproveBusiness.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(approveBusinessStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(approveBusinessStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allBusinessData = action.payload?.reverse();
        toast.success("Status approved successfully");
      })
      .addCase(approveBusinessStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })

      .addCase(deleteBusiness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBusiness.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allBusinessData = action.payload?.reverse();
        toast.success("Business successfully deleted");
      })
      .addCase(deleteBusiness.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })

      .addCase(updateCompanySettings.pending, (state) => {
        state.isLoading = true;
        state.isSuccessUpdateProfile = false;
      })
      .addCase(updateCompanySettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isSuccessUpdateProfile = true;
        state.profile = action.payload.updatedBusiness;
        toast.success(action.payload.message);
      })
      .addCase(updateCompanySettings.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.isSuccessUpdateProfile = false;
        toast.error(action.payload);
      })
      .addCase(updateCompanyCardDetails.pending, (state) => {
        state.isLoadingUpdateCompanyCardDetails = true;
      })
      .addCase(updateCompanyCardDetails.fulfilled, (state, action) => {
        state.isLoadingUpdateCompanyCardDetails = false;
        state.isSuccess = true;
        state.profile = action.payload.updatedBusiness;
        toast.success(action.payload.message);
      })
      .addCase(updateCompanyCardDetails.rejected, (state, action) => {
        state.isLoadingUpdateCompanyCardDetails = false;
        state.isSuccess = false;
        state.isError = true;
        toast.error(action.payload);
      })

      //Only admin
      .addCase(editCompanySettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editCompanySettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allBusinessData = action.payload?.reverse();
        toast.success("Business updated  successfully ");
      })
      .addCase(editCompanySettings.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        toast.error(action.payload);
      })

      .addCase(createBusineeNote.pending, (state) => {
        state.isLoadingBusinessNote = true;
      })
      .addCase(createBusineeNote.fulfilled, (state, action) => {
        state.isLoadingBusinessNote = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
      })
      .addCase(createBusineeNote.rejected, (state, action) => {
        state.isLoadingBusinessNote = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(getOneBusiness.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneBusiness.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.oneBusinessData = action.payload;
      })
      .addCase(getOneBusiness.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateBusinessSub.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBusinessSub.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profile = action.payload.business;
        toast.success(action.payload.message);
      })
      .addCase(updateBusinessSub.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateBusinessBenchMark.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBusinessBenchMark.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profile = action.payload.business;
        toast.success(action.payload.message);
      })

      .addCase(updateDefaultCusQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateDefaultCusQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDefaultCusQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profile = action.payload.business;
        toast.success(action.payload.message);
      })

      .addCase(customerDefaultVotingAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(customerDefaultVotingAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(customerDefaultVotingAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profile = action.payload.user;
        toast.success(action.payload.message);
      })

      .addCase(employeeDefaultVotingAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(employeeDefaultVotingAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(employeeDefaultVotingAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profile = action.payload.user;
        toast.success(action.payload.message);
      })

      .addCase(updateDefaultEmpQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateDefaultEmpQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDefaultEmpQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profile = action.payload.business;
        toast.success(action.payload.message);
      })

      .addCase(updateDefaultCustQR.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateDefaultCustQR.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDefaultCustQR.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profile = action.payload.business;
        state.SuccessUpdateDefaultCustQR = true;
        toast.success(action.payload.message);
      })

      .addCase(updateCusReason.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateCusReason.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCusReason.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profile = action.payload.updatedBusiness;
        toast.success(action.payload.message);
      })

      .addCase(updateEmpReason.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateEmpReason.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateEmpReason.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profile = action.payload.updatedBusiness;
        toast.success(action.payload.message);
      })
      .addCase(updateQuestionReason.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateQuestionReason.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateQuestionReason.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profile = action.payload.updatedBusiness;
        toast.success(action.payload.message);
      })

      .addCase(updateDefaultEmpQR.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateDefaultEmpQR.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDefaultEmpQR.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profile = action.payload.business;
        toast.success(action.payload.message);
      })

      .addCase(updateBusinessBenchMark.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(getAllBusinessDTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllBusinessDTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allBusinessDTP = action.payload;
      })
      .addCase(getAllBusinessDTP.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })

      .addCase(createBusinessDTP.pending, (state) => {
        state.isLoadingCreateBusinessDPT = true;
      })
      .addCase(createBusinessDTP.fulfilled, (state, action) => {
        state.isLoadingCreateBusinessDPT = false;
        state.isSuccess = true;
        state.allBusinessDTP = action.payload;
        toast.success("Data Touchpoint successfully created");
      })
      .addCase(createBusinessDTP.rejected, (state, action) => {
        state.isLoadingCreateBusinessDPT = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(addUserToDTP.pending, (state) => {
        state.isLoadingAddUserDTP = true;
      })
      .addCase(addUserToDTP.fulfilled, (state, action) => {
        state.isLoadingAddUserDTP = false;
        state.isSuccess = true;
        state.allBusinessDTP = action.payload;
        toast.success("Data Touchpoint user successfully created");
      })
      .addCase(addUserToDTP.rejected, (state, action) => {
        state.isLoadingAddUserDTP = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(deleteUserToDTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUserToDTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allBusinessDTP = action.payload;
        toast.success("Data Touchpoint user successfully deleted");
      })
      .addCase(deleteUserToDTP.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })

      .addCase(approveDTPStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(approveDTPStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allBusinessDTP = action.payload;
        toast.success("Status updated successfully");
      })
      .addCase(approveDTPStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(getOneDTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneDTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.oneDTPData = action.payload;
      })
      .addCase(getOneDTP.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(deleteDTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allBusinessDTP = action.payload;
        toast.success("Data Touchpoint successfully deleted");
      })
      .addCase(deleteDTP.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(availSubscription.pending, (state) => {
        state.isLoadingAvailSubscription = true;
      })
      .addCase(availSubscription.fulfilled, (state, action) => {
        state.isLoadingAvailSubscription = false;
        state.isSuccess = true;
        state.allSubRequests = action.payload?.additionalDTPRequests;
        state.profile = action.payload?.updatedBusiness;
        toast.success("Subscription request send successfully !");
      })
      .addCase(availSubscription.rejected, (state, action) => {
        state.isLoadingAvailSubscription = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(getAllSubReq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSubReq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allSubRequests = action.payload;
      })
      .addCase(getAllSubReq.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateSubReqStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSubReqStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allSubRequests = action.payload;
        toast.success("Paid Successfully");
      })
      .addCase(updateSubReqStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(getBenchSettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBenchSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.showBench = action.payload.benchMarkShowHide;
      })
      .addCase(getBenchSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(benchSetToFalse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(benchSetToFalse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.showBench = action.payload.benchMarkShowHide;
        toast.success("Benchmark will be hide successfully");
      })
      .addCase(benchSetToFalse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(benchSetToTrue.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(benchSetToTrue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.showBench = action.payload.benchMarkShowHide;
        toast.success("Benchmark will be shown successfully");
      })
      .addCase(benchSetToTrue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const { reset, logout, benchShow, benchHide , ResetSuccessUpdateDefaultCustQR } = auth.actions;
export default auth.reducer;
