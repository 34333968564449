import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faMoneyBill,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';

import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import {
  HandlerUpdateStatus,
  getAllInvoices,
} from '../../../../redux/payment/paymentSlice';
import Loader from '../../../../Loader';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ExportCSVButton from '../../../../common/ExportCSVButton';

// import "./sales.css";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const Sales = () => {
  let dispatch = useDispatch();

  const { allInvoices } = useSelector((state) => {
    return state.payment;
  });

  //=========Get All Invoices======
  useEffect(() => {
    dispatch(getAllInvoices({ verificationCode }));
  }, [dispatch]);

  const { language, setLanguage } = useMyContext();

  //-------------------DATA TABLE START ---------------------------------
  const [pending, setPending] = useState(true);
  const [SelectedStatus, setSelectedStatus] = useState();

  //====Search Field
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const columns = [
    {
      name: 'Date',
      selector: (row) => moment(row.createdAt).format('DD-MM-YYYY'),
      sortable: true,
      center: true,
      width: '200px',
    },
    {
      name: 'Customer',
      selector: (row) => (
        <>
          <span>{row?.paymentFrom.name}</span>

          {/* <span>{row?.paymentFrom.email}</span> */}
        </>
      ),
      sortable: true,
      center: true,
      width: '200px',
    },

    {
      name: 'Customer VAT ID',
      selector: (row) => row.paymentFrom.vat,
      sortable: true,
      center: true,
      width: '205px',
    },
    {
      name: 'Product',
      selector: (row) => row.product,
      sortable: true,
      center: true,
      width: '205px',
    },
    {
      name: 'Amount without VAT',
      selector: (row) => row.amount + ' ' + row.currency,
      sortable: true,
      center: true,
      width: '205px',
    },
    {
      name: 'VAT',
      selector: (row) => row.vat + '%',
      sortable: true,
      center: true,
    },
    {
      name: 'Total (EUR)',
      selector: (row) => row.priceAfterVat + ' ' + row.currency,
      sortable: true,
      center: true,
      width: '205px',
    },

    {
      name: 'Card / Account Number',
      selector: (row) =>
        row?.PaymentMethod === 'bank_transfer'
          ? row?.bankDetails?.accountNumber
          : '**** **** ****' + '' + row?.cardDetails?.cardNumber,
      sortable: true,
      center: true,
      width: '180px',
    },

    {
      name: 'Status',
      selector: (row) =>
        row?.status === true ? (
          <span className="text-white badge bg-success p-1">Approved</span>
        ) : (
          <span className="text-white badge bg-warning p-1">Pending</span>
        ),
      sortable: true,
      center: true,
    },
    {
      name: 'Payment Method',
      selector: (row) =>
        row?.PaymentMethod === 'card' ? 'Card' : 'Bank Transfer',
      sortable: true,
      center: true,
    },
    {
      name: 'Payment Status',
      selector: (row) => (
        <span className="p-1 w-100">
          <div className="form-group w-100">
            <select
              className="form-select w-100"
              name="selectCountry"
              value={row?.status}
              onChange={(e) => {
                setSelectedStatus(e.target.value);
                dispatch(
                  HandlerUpdateStatus({
                    data: {
                      verificationCode: 123123123,
                      paymentId: row._id,
                      status: e.target.value,
                    },
                    id: row._id,
                  })
                );
              }}
            >
              <option value={true}>
                <Trans>Paid</Trans>
              </option>
              <option value={false}>
                <Trans>Pending</Trans>
              </option>
            </select>
          </div>
        </span>
      ),
      sortable: true,
      center: true,
    },
  ];

  //-------Export CSV ----------------
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(allInvoices[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Link
      className="btn btn-secondary mb-2 mx-2"
      onClick={(e) => onExport(e.target.value)}
    >
      <FontAwesomeIcon icon={faDownload} className="btnIcon" />
      <Trans>Export</Trans>
    </Link>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(allInvoices)} />,
    []
  );

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: '60px',
      },
    },
    headCells: {
      style: {
        fontSize: '14px',
        color: '#B5B7C0',
        textAlign: 'center',
      },
    },
    cells: {
      style: {
        textAlign: 'center', // Align regular cell content to center
        color: '#292D32 !important',
        fontWeight: '600',
      },
    },
  };

  console.log('allInvoices', allInvoices);
  // Apply the filter based on the input text
  const filteredData = searchQuery
    ? allInvoices.filter(
        (item) =>
          item.paymentFrom.name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item.paymentFrom.email
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item.paymentTo.name
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item.paymentTo.email.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : allInvoices;

  //Search Field END

  //-------------------DATA TABLE END---------------------------------

  //=================Data of overall monthly sales as well yearly=============================
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  // Calculate the overall amount for the current year up to now
  const overallAmountThisYearTillNow =
    allInvoices &&
    allInvoices
      ?.filter((entry) => {
        const entryDate = new Date(entry.createdAt);
        return (
          entryDate.getFullYear() === currentYear && entryDate <= currentDate
        );
      })
      .reduce((sum, entry) => sum + entry.amount, 0);

  // Calculate the total amount for the last 30 days
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  const totalAmountLast30Days =
    allInvoices &&
    allInvoices
      ?.filter((entry) => {
        const entryDate = new Date(entry.createdAt);
        return entryDate >= thirtyDaysAgo && entryDate <= currentDate;
      })
      .reduce((sum, entry) => sum + entry.amount, 0);

  //=================Data of overall monthly sales as well yearly=============================

  return (
    <>
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col-md-2">
            {' '}
            <h3 className="align-items-center m-0 fw-500 mt-1 mb-2">
              <Trans>Sales</Trans>
            </h3>
          </div>
          <div className="col-md-4">
            <div className="search-field search-bar">
              <input
                type="text"
                placeholder={language === 'en' ? 'Search' : 'Búsqueda'}
                className="dashboardinput"
                id="userSearch"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <span className="search-icon">
                <FontAwesomeIcon icon={faSearch} className="usersearch" />
              </span>
            </div>
          </div>
          <div className="col-md-6 justify-content-end d-flex">
            <ExportCSVButton
              data={allInvoices}
              filename={'exported_data.csv'}
            />
          </div>
        </div>
        <div className="bgcards mb-3 p-3">
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex">
                <div className="image">
                  <FontAwesomeIcon
                    icon={faMoneyBill}
                    className="text-success"
                  />
                </div>
                <div className="salesContent ms-3">
                  <p className="m-0 topSales">
                    <Trans>
                      <Trans>Year</Trans> to Date Sales
                    </Trans>
                  </p>
                  <h4>{overallAmountThisYearTillNow?.toFixed(2)} €</h4>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex">
                <div className="image">
                  <FontAwesomeIcon
                    icon={faMoneyBill}
                    className="text-success"
                  />
                </div>
                <div className="salesContent ms-3">
                  <div className="salesContent ms-3">
                    <p className="m-0 topSales">
                      <Trans>Monthly Sales</Trans>
                    </p>
                    <h4>{totalAmountLast30Days?.toFixed(2)} €</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div
                className="mt-2 outerWrapperTable"
                style={{ overflowX: 'auto' }}
              >
                {allInvoices && (
                  <DataTable
                    columns={columns}
                    data={filteredData}
                    progressPending={pending}
                    progressComponent={<Loader />}
                    customStyles={customStyles}
                    actions={actionsMemo}
                    pagination
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sales;
