import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { BiLinkAlt } from 'react-icons/bi';
import { CSVLink } from 'react-csv';

import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';

//=========Redux===========
import { useSelector, useDispatch } from 'react-redux';
import { getOneDTP } from '../../../../redux/auth/authSlice';
import { toast } from 'react-toastify';
import { getAllCities } from '../../../../redux/CountryAndCities/CountryAndCitySlice';

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const REACT_APP_BASE_URL_QR_CODE = process.env.REACT_APP_BASE_URL_QR_CODE;

const DTPView = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  let { slug } = useParams();

  //=======useSelector Data=====================
  const { oneDTPData, profile } = useSelector((state) => {
    return state.auth;
  });

  const { getCountries, getCities, isCitiesLoading } = useSelector(
    (state) => state.CountryAndCitySlice
  );

  useEffect(() => {
    if (oneDTPData) {
      dispatch(getAllCities(oneDTPData?.dataTouchPoint?.country));
    }
    return () => {};
  }, [oneDTPData]);

  //=========Get one Data Touchpoint =================
  useEffect(() => {
    dispatch(
      getOneDTP({
        verificationCode,
        slug,
      })
    );
  }, [dispatch, slug]);

  // Function to convert data to CSV format
  const [csvData, setCsvData] = useState([]);

  const prepareCSVData = () => {
    if (oneDTPData && oneDTPData?.npsData && oneDTPData?.npsData.length > 0) {
      const reviews = oneDTPData.npsData[0].reviews.map((review, i) => ({
        '#': i + 1,
        'Data Touchpoint': oneDTPData.dataTouchPoint.name,
        'Selected Question': oneDTPData.dataTouchPoint.question,
        'Selected Reason': review.answerReason,
        Ratings: review.rating,
        'Creation Date': moment(review.createdAt).format(
          'D MMMM, YYYY h:mm:ss A'
        ),
      }));
      setCsvData(reviews);
    }
  };

  //========Handle Back=================
  const handleBackToTable = () => {
    navigate('/company/touchpoints');
  };

  console.log(oneDTPData);

  return (
    <>
      {oneDTPData && (
        <div className="container" id="viewUser">
          <div className="main-body">
            <div className="row gutters-sm">
              <div className="col-md-12 d-flex justify-content-left">
                <button onClick={handleBackToTable} className="btn btnPrint">
                  {' '}
                  <FontAwesomeIcon icon={faArrowLeft} />{' '}
                </button>
              </div>
              <div className="col-md-3 mb-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-left text-left">
                      <img
                        src={
                          REACT_APP_BASE_URL_QR_CODE +
                          oneDTPData?.dataTouchPoint?.qrCode
                        }
                        onError={(e) => (e.target.src = '/assets/user.png')}
                        alt="Admin"
                      />
                      <div className="mt-4 text-center">
                        <h4>{oneDTPData?.dataTouchPoint?.name}</h4>
                        <p className="text-secondary mb-1 text-capitalize">
                          {oneDTPData?.dataTouchPoint?.role}
                        </p>
                        <p className="text-muted font-size-sm">
                          {oneDTPData?.dataTouchPoint?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Data touchpoint</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <span>{oneDTPData?.dataTouchPoint?.name}</span>
                      </div>
                    </div>

                    <hr />

                    {oneDTPData?.dataTouchPoint?.description && (
                      <>
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">
                              <Trans>Description</Trans>
                            </h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <span>
                              {oneDTPData?.dataTouchPoint?.description}
                            </span>
                          </div>
                        </div>

                        <hr />
                      </>
                    )}

                    {oneDTPData?.dataTouchPoint?.contactNumber && (
                      <>
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">
                              <Trans>Contact number</Trans>
                            </h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <span>
                              {oneDTPData?.dataTouchPoint?.contactNumber}
                            </span>
                          </div>
                        </div>

                        <hr />
                      </>
                    )}

                    {oneDTPData?.dataTouchPoint?.city && (
                      <>
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">City</h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <span>
                              {/* {oneDTPData?.dataTouchPoint?.city} */}
                              {getCities
                                ?.filter((item) => {
                                  return (
                                    item?._id ===
                                    oneDTPData?.dataTouchPoint?.city
                                  );
                                })
                                .map((filteredItem, index) => (
                                  <div key={index}>
                                    <p>{filteredItem?.name_of_city}</p>
                                  </div>
                                ))}
                            </span>
                          </div>
                        </div>

                        <hr />
                      </>
                    )}

                    {oneDTPData?.dataTouchPoint?.country && (
                      <>
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">
                              <Trans>Country</Trans>
                            </h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <span>
                              {/* {oneDTPData?.dataTouchPoint?.country} */}
                              {getCountries?.countries
                                ?.filter((item) => {
                                  return (
                                    item?.countryId ===
                                    oneDTPData?.dataTouchPoint?.country
                                  );
                                })
                                .map((filteredItem, index) => (
                                  <div key={index}>
                                    <p>{filteredItem?.country}</p>
                                  </div>
                                ))}
                            </span>
                          </div>
                        </div>

                        <hr />
                      </>
                    )}

                    {oneDTPData?.dataTouchPoint?.location !== 0 && (
                      <>
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">Location</h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <span>{oneDTPData?.dataTouchPoint?.location}</span>
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                    {oneDTPData?.dataTouchPoint?.slugLink !== 0 && (
                      <>
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mt-1">Link To Review</h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <span>
                              {oneDTPData?.dataTouchPoint?.slugLink}{' '}
                              <button
                                className="btn btn-sm border mx-2"
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    oneDTPData?.dataTouchPoint?.slugLink
                                  );
                                  toast.success(
                                    'Voting url Copied Successfully'
                                  );
                                }}
                              >
                                <BiLinkAlt />
                              </button>
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {oneDTPData &&
              oneDTPData?.npsData &&
              oneDTPData?.npsData.length > 0 && (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h4 className="py-3">Reviews</h4>
                        </div>
                        <div className="py-3">
                          <button
                            onClick={prepareCSVData}
                            className="btn btn-primary"
                          >
                            Export CSV
                          </button>
                          {csvData.length > 0 && (
                            <CSVLink
                              data={csvData}
                              filename={`reviews.csv`}
                              // filename={`${csvData[0]?.DataTouchpoint}.csv`}
                              className="btn btn-primary mx-2"
                              target="_blank"
                            >
                              <FontAwesomeIcon icon={faDownload} />
                            </CSVLink>
                          )}
                        </div>
                      </div>

                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="text-start">#</th>
                              <th className="text-start">Data Touchpoint</th>
                              <th className="text-center">Selected Question</th>
                              <th className="text-center">Selected Reason</th>
                              <th className="text-center">Ratings</th>
                              <th className="text-center">
                                <Trans>Creation Date</Trans>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {oneDTPData?.npsData[0]?.reviews.map(
                              (review, i) => (
                                <tr key={i}>
                                  <td className="text-start">{i + 1}</td>
                                  <td className="text-start">
                                    {oneDTPData?.dataTouchPoint?.name}
                                  </td>
                                  <td className="text-center">
                                    {oneDTPData?.dataTouchPoint?.question}
                                  </td>
                                  <td className="text-center">
                                    {review?.answerReason}
                                  </td>
                                  <td className="text-center">
                                    {review?.rating}
                                  </td>
                                  <td className="text-center">
                                    {moment(review?.createdAt).format(
                                      'D MMMM, YYYY h:mm:ss A'
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default DTPView;
