import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';

import ReactApexChart from 'react-apexcharts';

//=============redux============
import { useSelector, useDispatch } from 'react-redux';
import { getAllBusinessDTP } from '../../../../redux/auth/authSlice';

import { Trans } from 'react-i18next';
import { useMyContext } from '../../../..';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import moment from 'moment';

import { format } from 'date-fns';

// import "./main.css";
import api from '../../../../utility/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faEnvelope,
  faArrowAltCircleRight,
  faClose,
} from '@fortawesome/free-solid-svg-icons';

//======Utils
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const Main = () => {
  const dispatch = useDispatch();
  const { language, setLanguage } = useMyContext();
  const { allBusinessDTP } = useSelector((state) => {
    return state.auth;
  });

  const { isAuthenticated, profile } = useSelector((state) => {
    return state.auth;
  });

  //==========All business datatouchPoints================================
  useEffect(() => {
    dispatch(getAllBusinessDTP({ verificationCode }));
  }, [dispatch]);

  //==========Employee Sentiment & Customer Feedback Selection===========
  const [selectedButton, setSelectedButton] = useState('');
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  //===========Map initialization as well Data===========================
  //===============DataTouchPoint change =============================
  const [dtpId, setDTPId] = useState('');

  //==============================================START OF LAST 30 DAYS AND YTD DONUT GRAPH

  //=====================Doughout Chart js START======================//
  const [thirtyDaysNPS, setThirtyDaysNPS] = useState();
  const [color30Days, setColor30Days] = useState();

  const [ytdNPS, setYTDNPS] = useState();
  const [colorYTDNPS, setColorYTDNPS] = useState();

  // let thirtyDaySeries = [];
  let [thirtyDaySeries, setThirtyDaySeries] = useState([]);
  let [thirtyDaysLabels, setThirtyDaysLabels] = useState([]);

  //=====YEAR to Date NPS ===========
  let [YTDSeries, setYTDSeries] = useState([]);
  let [YTDLabels, setYTDLabels] = useState([]);
  let totalPromotersSum = 0;
  let totalPassivesSum = 0;
  let totalDetractorsSum = 0;

  //==<Trans>Year</Trans> to Date NPS
  let totalPromotersYTDSum = 0;
  let totalPassivesYTDSum = 0;
  let totalDetractorsYTDSum = 0;

  useEffect(() => {
    if (selectedButton !== '') {
      const selectedtypeDTPs = allBusinessDTP?.npsData?.filter(
        (dataTouchPoint) => dataTouchPoint?.type === selectedButton
      );
      // console.log('selectedtypeDTPs', selectedtypeDTPs);
      if (selectedtypeDTPs && selectedtypeDTPs.length > 0) {
        selectedtypeDTPs.forEach((selectedDTP) => {
          const { npsLast30Days, npsYearToDate } = selectedDTP;

          const {
            totalPromotersLast30Days,
            totalPassivesLast30Days,
            totalDetractorsLast30Days,
          } = npsLast30Days;

          totalPromotersSum += totalPromotersLast30Days;
          totalPassivesSum += totalPassivesLast30Days;
          totalDetractorsSum += totalDetractorsLast30Days;

          //++++ Calculate the percentages and set values to array series values YEAR TO DATE START++
          const {
            totalPromotersYearToDate,
            totalPassivesYearToDate,
            totalDetractorsYearToDate,
          } = npsYearToDate;

          totalPromotersYTDSum += totalPromotersYearToDate;
          totalPassivesYTDSum += totalPassivesYearToDate;
          totalDetractorsYTDSum += totalDetractorsYearToDate;

          //+++++++NPS For <Trans>Year</Trans> to date
          let totalReviewYTD =
            totalPromotersYTDSum + totalPassivesYTDSum + totalDetractorsYTDSum;

          let promotersYTDPercentage =
            (totalPromotersYTDSum / totalReviewYTD) * 100;
          let detractorsYTDPercentage =
            (totalDetractorsYTDSum / totalReviewYTD) * 100;

          // Calculate the NPS
          let npsLastYTD = promotersYTDPercentage - detractorsYTDPercentage;

          if (npsLastYTD != undefined) {
            if (npsLastYTD >= 30) {
              setColorYTDNPS('#41E998'); // Green for NPS >= 30
            } else if (npsLastYTD >= -30) {
              setColorYTDNPS('#F5A623');
            } else {
              setColorYTDNPS('#E84949');
            }
          }
          setYTDNPS(npsLastYTD);

          let YTDArr = [];

          YTDArr.push(
            totalPromotersYTDSum,
            totalPassivesYTDSum,
            totalDetractorsYTDSum
          );

          if (YTDArr?.length > 0) {
            setYTDSeries(YTDArr);
          } else {
            setYTDSeries([]);
          }

          //-----Labels YTD-----

          let YTDLabelsArr = [];

          let totalPromoters =
            parseInt(totalPromotersYTDSum) / parseInt(totalReviewYTD);
          let totalPassives =
            parseInt(totalPassivesYTDSum) / parseInt(totalReviewYTD);
          let totalDetractors =
            parseInt(totalDetractorsYTDSum) / parseInt(totalReviewYTD);
          YTDLabelsArr.push(
            `${language === 'en' ? 'Promoters' : 'Promotoras'}\n${(
              parseFloat(totalPromoters ? totalPromoters : 0) * 100
            ).toFixed(1)}% | ${parseInt(totalPromotersYTDSum)}`,
            `${language === 'en' ? 'Passives' : 'Pasivos'}\n${(
              parseFloat(totalPassives ? totalPassives : 0) * 100
            ).toFixed(1)}% | ${totalPassivesYTDSum}`,
            `${language === 'en' ? 'Detractors' : 'Detractoras'}\n${(
              parseFloat(totalDetractors ? totalDetractors : 0) * 100
            ).toFixed(1)}% | ${parseInt(totalDetractorsYTDSum)}`
          );
          setYTDLabels(YTDLabelsArr);

          //++++ Calculate the percentages and set values to array series values YEAR TO DATE END++

          //++++ Calculate the percentages and set values to array series values 30 DAYS START++
          //+++++++NPS For last 30 Days
          let totalReviewLast30Days =
            totalPromotersSum + totalPassivesSum + totalDetractorsSum;

          let promotersPercentage =
            (totalPromotersSum / totalReviewLast30Days) * 100;
          let detractorsPercentage =
            (totalDetractorsSum / totalReviewLast30Days) * 100;

          // Calculate the NPS
          let npsLast30ds = promotersPercentage - detractorsPercentage;
          if (npsLast30ds != undefined) {
            if (npsLast30ds >= 30) {
              setColor30Days('#41E998'); // Green for NPS >= 30
            } else if (npsLast30ds >= -30) {
              setColor30Days('#F5A623');
            } else {
              setColor30Days('#E84949');
            }
          }
          setThirtyDaysNPS(npsLast30ds);
          let thirtyDayArr = [];
          thirtyDayArr.push(
            totalPromotersSum,
            totalPassivesSum,
            totalDetractorsSum
          );
          setThirtyDaySeries(thirtyDayArr);

          //-----Labels 30 days-----

          let thirtyDayLabelsArr = [];

          let totalPromoters1 =
            parseInt(totalPromotersSum) / parseInt(totalReviewLast30Days);
          let totalPassives1 =
            parseInt(totalPassivesSum) / parseInt(totalReviewLast30Days);
          let totalDetractors1 =
            parseInt(totalDetractorsSum) / parseInt(totalReviewLast30Days);

          thirtyDayLabelsArr.push(
            `${language === 'en' ? 'Promoters' : 'Promotoras'}\n${(
              parseFloat(totalPromoters1 ? totalPromoters1 : 0) * 100
            ).toFixed(1)}% | ${totalPromotersSum}`,
            `${language === 'en' ? 'Passives' : 'Pasivos'}\n${(
              parseFloat(totalPassives1 ? totalPassives1 : 0) * 100
            ).toFixed(1)}% | ${totalPassivesSum}`,
            `${language === 'en' ? 'Detractors' : 'Detractoras'}\n${(
              parseFloat(totalDetractors1 ? totalDetractors1 : 0) * 100
            ).toFixed(1)}% | ${totalDetractorsSum}`
          );
          setThirtyDaysLabels(thirtyDayLabelsArr);
          //++++ Calculate the percentages and set values to array series values 30 DAYS END++
        });
      } else {
        setYTDNPS();
        setYTDSeries([0, 0, 0]);
        let res = [];
        res.push(
          `${language === 'en' ? 'Promoters' : 'Promotoras'}\n${(
            parseFloat(0 ? 0 : 0) * 100
          ).toFixed(1)}% | ${0}`,
          `${0 === 'en' ? 'Passives' : 'Pasivos'}\n${(
            parseFloat(0 ? 0 : 0) * 100
          ).toFixed(1)}% | ${0}`,
          `${0 === 'en' ? 'Detractors' : 'Detractoras'}\n${(
            parseFloat(0 ? 0 : 0) * 100
          ).toFixed(1)}% | ${0}`
        );
        setThirtyDaysLabels(res);
        let YTDLabelsArr2 = [];
        YTDLabelsArr2.push(
          `${language === 'en' ? 'Promoters' : 'Promotoras'}\n${(
            parseFloat(0 ? 0 : 0) * 100
          ).toFixed(1)}% | ${parseInt(0)}`,
          `${language === 'en' ? 'Passives' : 'Pasivos'}\n${(
            parseFloat(0 ? 0 : 0) * 100
          ).toFixed(1)}% | ${0}`,
          `${language === 'en' ? 'Detractors' : 'Detractoras'}\n${(
            parseFloat(0 ? 0 : 0) * 100
          ).toFixed(1)}% | ${parseInt(0)}`
        );
        setYTDLabels(YTDLabelsArr2);
        setThirtyDaySeries([0, 0, 0]);
        setThirtyDaysNPS(0);
      }
    } else {
      // Iterate through each data touchpoint in npsData
      allBusinessDTP?.npsData &&
        allBusinessDTP?.npsData?.forEach((dataTouchPoint) => {
          const { npsLast30Days, npsYearToDate } = dataTouchPoint;

          const {
            totalPromotersLast30Days,
            totalPassivesLast30Days,
            totalDetractorsLast30Days,
          } = npsLast30Days;

          totalPromotersSum += totalPromotersLast30Days;
          totalPassivesSum += totalPassivesLast30Days;
          totalDetractorsSum += totalDetractorsLast30Days;

          // Calculate the series data for  YTD the chart
          const {
            totalPromotersYearToDate,
            totalPassivesYearToDate,
            totalDetractorsYearToDate,
          } = npsYearToDate;

          totalPromotersYTDSum += totalPromotersYearToDate;
          totalPassivesYTDSum += totalPassivesYearToDate;
          totalDetractorsYTDSum += totalDetractorsYearToDate;
        });
      //++++ Calculate the percentages and set values to array series values YEAR TO DATE START++
      //+++++++NPS For <Trans>Year</Trans> to date
      let totalReviewYTD =
        totalPromotersYTDSum + totalPassivesYTDSum + totalDetractorsYTDSum;

      let promotersYTDPercentage =
        (totalPromotersYTDSum / totalReviewYTD) * 100;
      let detractorsYTDPercentage =
        (totalDetractorsYTDSum / totalReviewYTD) * 100;

      // Calculate the NPS
      let npsLastYTD = promotersYTDPercentage - detractorsYTDPercentage;
      if (npsLastYTD != undefined) {
        if (npsLastYTD >= 30) {
          setColorYTDNPS('#41E998'); // Green for NPS >= 30
        } else if (npsLastYTD >= -30) {
          setColorYTDNPS('#F5A623');
        } else {
          setColorYTDNPS('#E84949');
        }
      }

      if (!isNaN(npsLastYTD)) {
        setYTDNPS(npsLastYTD);
      } else {
        setYTDNPS(0);
      }

      let YTDArr = [];

      YTDArr.push(
        totalPromotersYTDSum,
        totalPassivesYTDSum,
        totalDetractorsYTDSum
      );
      if (YTDArr?.length > 0) setYTDSeries(YTDArr);

      //-----Labels YTD-----

      let YTDLabelsArr = [];
      let totalPromoters =
        parseInt(totalPromotersYTDSum ? totalPromotersYTDSum : 0) /
        parseInt(totalReviewYTD ? totalReviewYTD : 0);
      let totalPassives =
        parseInt(totalPassivesYTDSum) / parseInt(totalReviewYTD);
      let totalDetractors =
        parseInt(totalDetractorsYTDSum) / parseInt(totalReviewYTD);
      YTDLabelsArr.push(
        `${language === 'en' ? 'Promoters' : 'Promotoras'}\n${(
          parseFloat(totalPromoters ? totalPromoters : 0) * 100
        ).toFixed(1)}% | ${parseInt(totalPromotersYTDSum)}`,
        `${language === 'en' ? 'Passives' : 'Pasivos'}\n${(
          parseFloat(totalPassives ? totalPassives : 0) * 100
        ).toFixed(1)}% | ${parseInt(totalPassivesYTDSum)}`,
        `${language === 'en' ? 'Detractors' : 'Detractoras'}\n${(
          parseFloat(totalDetractors ? totalDetractors : 0) * 100
        ).toFixed(1)}% | ${parseInt(totalDetractorsYTDSum)}`
      );
      setYTDLabels(YTDLabelsArr);
      //++++ Calculate the percentages and set values to array series values YEAR TO DATE END++

      //++++ Calculate the percentages and set values to array series values 30 DAYS START++
      //+++++++NPS For last 30 Days
      let totalReviewLast30Days =
        totalPromotersSum + totalPassivesSum + totalDetractorsSum;

      let promotersPercentage =
        (totalPromotersSum / totalReviewLast30Days) * 100;
      let detractorsPercentage =
        (totalDetractorsSum / totalReviewLast30Days) * 100;

      // Calculate the NPS
      let nps = promotersPercentage - detractorsPercentage;
      if (nps != undefined) {
        if (nps >= 30) {
          setColor30Days('#41E998'); // Green for NPS >= 30
        } else if (nps >= -30) {
          setColor30Days('#F5A623');
        } else {
          setColor30Days('#E84949');
        }
      }
      if (!isNaN(nps)) {
        setThirtyDaysNPS(nps);
      } else {
        setThirtyDaysNPS(0);
      }

      let thirtyDayArr = [];

      thirtyDayArr.push(
        totalPromotersSum,
        totalPassivesSum,
        totalDetractorsSum
      );
      setThirtyDaySeries(thirtyDayArr);

      //-----Labels 30 days-----

      let thirtyDayLabelsArr = [];

      let totalPromoters1 =
        parseInt(totalPromotersSum) / parseInt(totalReviewLast30Days);
      let totalPassives1 =
        parseInt(totalPassivesSum) / parseInt(totalReviewLast30Days);
      let totalDetractors1 =
        parseInt(totalDetractorsSum) / parseInt(totalReviewLast30Days);
      thirtyDayLabelsArr.push(
        `${language === 'en' ? 'Promoters' : 'Promotoras'}\n${(
          parseFloat(totalPromoters1 ? totalPromoters1 : 0) * 100
        ).toFixed(1)}% | ${totalPromotersSum}`,
        `${language === 'en' ? 'Passives' : 'Pasivos'}\n${(
          parseFloat(totalPassives1 ? totalPassives1 : 0) * 100
        ).toFixed(1)}% | ${totalPassivesSum}`,
        `${language === 'en' ? 'Detractors' : 'Detractoras'}\n${(
          parseFloat(totalDetractors1 ? totalDetractors1 : 0) * 100
        ).toFixed(1)}% | ${totalDetractorsSum}`
      );

      setThirtyDaysLabels(thirtyDayLabelsArr);
      //++++ Calculate the percentages and set values to array series values 30 DAYS END++
    }
  }, [thirtyDaysNPS, ytdNPS, allBusinessDTP, dtpId, selectedButton]);

  // console.log('thirtyDaysNPS ...', thirtyDaysNPS);
  const optionsLast30d = {
    chart: {
      type: 'donut',
      width: '200px',
      height: '200px',
      borderWidth: 15,
    },
    colors: ['#41E998', '#A2A39E', '#E84949'],
    stroke: {
      width: 0,
      lineCap: 'round',
    },
    labels: thirtyDaysLabels,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true, // Hide the "Total" text
              formatter: function () {
                return `${thirtyDaysNPS ? thirtyDaysNPS.toFixed(0) : 0}`;
              },
            },
            value: {
              fontSize: '45px',
              fontWeight: 'bold',
              offsetY: 15,
              color: color30Days,
            },
            name: {
              show: false,
              fontSize: '10px',
              offsetY: -0,
              color: '#000',
            },
          },
          size: '85%',
        },
        spacing: 1,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 280,
          },
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
      {
        breakpoint: 9999,
        options: {
          chart: {
            height: 350,
            width: 350,
          },
          legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    dataLabels: {
      style: {
        fontSize: '0px',
        fontFamily: 'Poppins, sans-serif',
      },
    },
  };
  const optionsYTD = {
    chart: {
      type: 'donut',
      width: '200px',
      height: '200px',
      borderWidth: 15,
    },
    colors: ['#41E998', '#A2A39E', '#E84949'],
    stroke: {
      width: 0,
      lineCap: 'round',
    },
    labels: YTDLabels,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true, // Hide the "Total" text
              formatter: function (val) {
                return `${ytdNPS ? ytdNPS?.toFixed(0) : 0}`; // Display the NPS value with a percentage symbol
              },
            },
            value: {
              fontSize: '45px',
              fontWeight: 'bold',
              offsetY: 15,
              color: colorYTDNPS,
            },
            name: {
              show: false,
              fontSize: '10px',
              offsetY: -0,
              color: '#000',
            },
          },
          size: '85%',
        },
        spacing: 1,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 280,
          },
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
      {
        breakpoint: 9999,
        options: {
          chart: {
            height: 350,
            width: 350,
          },
          legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    dataLabels: {
      style: {
        fontSize: '0px',
        fontFamily: 'Poppins, sans-serif',
      },
    },
  };

  //==============================================END OF LAST 30 DAYS AND YTD DONUT GRAPH

  //===================================Select Multiple DTP START===============================
  const [multiDtp, setMultiDtp] = useState([]);
  const [multiDtpData, setMultiDtpData] = useState();

  const handleMultiDtpSelection = (e) => {
    const options = e.map((item) => ({
      value: item.value,
      label: item.label,
    }));

    setMultiDtp(options);
  };

  useEffect(() => {
    if (multiDtp) {
      getData();
    }
  }, [multiDtp, selectedButton]);

  const getData = async () => {
    try {
      const response = await api.post('/review/getMultiDtpData', {
        verificationCode,
        dtpIds: multiDtp,
        businessID: profile?.businessId,
        selectedButton: selectedButton,
      });
      // console.log('response', response?.data);
      if (response?.data) setMultiDtpData(response?.data);
    } catch (error) {
      console.error('Error :', error);
    }
  };

  //===================================Select Multiple DTP END===============================

  //=====================Big Doughout Chart======================//
  let [sevenDaySeries, setSevenDaysSeries] = useState([]);
  let [sevenDayLabels, setSevenDaysLabels] = useState([]);
  const [sevenDayNPS, setSevenDayNPS] = useState();
  const [colorSevenDayNPS, setColorSevenDayNPS] = useState();

  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });

  const { startDate, endDate } = dateRange;

  useEffect(() => {
    const startDateLast7Days = new Date();
    startDateLast7Days.setDate(startDateLast7Days.getDate() - 6);

    let positivesCountLast7Days = 0;
    let passivesCountLast7Days = 0;
    let negativesCountLast7Days = 0;

    allBusinessDTP?.npsData?.forEach((Bdtp) => {
      Bdtp.reviews.forEach((review) => {
        const reviewDate = new Date(review.createdAt); // Assuming review.createdAt is the review date
        if (reviewDate >= startDateLast7Days) {
          if (review.rating === 3) {
            positivesCountLast7Days++;
          } else if (review.rating === 2) {
            passivesCountLast7Days++;
          } else if (review.rating === 1) {
            negativesCountLast7Days++;
          }
        }
      });
    });

    if (selectedButton && selectedButton !== '') {
      allBusinessDTP?.npsData
        ?.filter((Bdtp) => Bdtp?.type === selectedButton)
        .forEach((Bdtp) => {
          Bdtp.reviews.forEach((review) => {
            const reviewDate = new Date(review.createdAt); // Assuming review.createdAt is the review date
            if (reviewDate >= startDateLast7Days) {
              if (review.rating === 3) {
                positivesCountLast7Days++;
              } else if (review.rating === 2) {
                passivesCountLast7Days++;
              } else if (review.rating === 1) {
                negativesCountLast7Days++;
              }
            }
          });
        });
    }

    let overAll7ds =
      positivesCountLast7Days +
      passivesCountLast7Days +
      negativesCountLast7Days;

    let promoters7DsPercentage = (positivesCountLast7Days / overAll7ds) * 100;
    let detractors7DsPercentage = (negativesCountLast7Days / overAll7ds) * 100;

    // Calculate the NPS of 7 Days
    let npsLast7Ds = promoters7DsPercentage - detractors7DsPercentage;
    if (npsLast7Ds != undefined) {
      if (npsLast7Ds >= 30) {
        setColorSevenDayNPS('#41E998'); // Green for NPS >= 30
      } else if (npsLast7Ds >= -30) {
        setColorSevenDayNPS('#F5A623');
      } else {
        setColorSevenDayNPS('#E84949');
      }
    }

    if (!isNaN(npsLast7Ds)) {
      setSevenDayNPS(npsLast7Ds);
    } else {
      setSevenDayNPS(0);
    }

    setSevenDaysSeries([
      positivesCountLast7Days,
      passivesCountLast7Days,
      negativesCountLast7Days,
    ]);

    let positivesCount =
      parseInt(positivesCountLast7Days) / parseInt(overAll7ds);
    let passivesCount = parseInt(passivesCountLast7Days) / parseInt(overAll7ds);
    let negativesCount =
      parseInt(negativesCountLast7Days) / parseInt(overAll7ds);

    setSevenDaysLabels([
      `${language === 'en' ? 'Promoters' : 'Promotoras'}\n${(
        parseFloat(positivesCount ? positivesCount : 0) * 100
      ).toFixed(1)}% | ${positivesCountLast7Days}`,
      `${language === 'en' ? 'Passives' : 'Pasivos'}\n${(
        parseFloat(passivesCount ? passivesCount : 0) * 100
      ).toFixed(1)}% | ${passivesCountLast7Days}`,
      `${language === 'en' ? 'Detractors' : 'Detractoras'}\n${(
        parseFloat(negativesCount ? negativesCount : 0) * 100
      ).toFixed(1)}% | ${negativesCountLast7Days}`,
    ]);

    let npsOverAllData = 0;
    let multiDataPassives = 0;
    let multiDtpNps = 0;
    if (multiDtpData && multiDtpData?.reviewsInfo.length !== 0) {
      npsOverAllData =
        multiDtpData &&
        multiDtpData?.totalPositive +
          multiDtpData?.totalNeutral +
          multiDtpData?.totalNegative;

      multiDataPassives = (multiDtpData?.totalPositive / npsOverAllData) * 100;
      let multiDataDetractor =
        (multiDtpData?.totalNegative / npsOverAllData) * 100;

      multiDtpNps = multiDataPassives - multiDataDetractor;
    }

    if (multiDtpData != undefined && multiDtpData?.reviewsInfo.length !== 0) {
      if (!isNaN(multiDtpNps)) {
        setSevenDayNPS(multiDtpNps);
      } else {
        setSevenDayNPS(0);
      }

      if (multiDtpNps >= 30) {
        setColorSevenDayNPS('#41E998'); // Green for NPS >= 30
      } else if (multiDtpNps >= -30) {
        setColorSevenDayNPS('#F5A623');
      } else {
        setColorSevenDayNPS('#E84949');
      }

      setSevenDaysSeries([
        multiDtpData?.totalPositive,
        multiDtpData?.totalNeutral,
        multiDtpData?.totalNegative,
      ]);

      let TotalPromoter =
        parseInt(multiDtpData?.totalPositive) / parseInt(npsOverAllData);
      let totalPassive =
        parseInt(multiDtpData?.totalNeutral) / parseInt(npsOverAllData);
      let totalDetractors =
        parseInt(multiDtpData?.totalNegative) / parseInt(npsOverAllData);
      setSevenDaysLabels([
        `${language === 'en' ? 'Promoters' : 'Promotoras'}\n${(
          parseFloat(TotalPromoter ? TotalPromoter : 0) * 100
        ).toFixed(1)}% | ${multiDtpData?.totalPositive}`,
        `${language === 'en' ? 'Passives' : 'Pasivos'}\n${(
          parseFloat(totalPassive ? totalPassive : 0) * 100
        ).toFixed(1)}% | ${multiDtpData?.totalNeutral}`,
        `${language === 'en' ? 'Detractors' : 'Detractoras'}\n${(
          parseFloat(totalDetractors ? totalDetractors : 0) * 100
        ).toFixed(1)}% | ${multiDtpData?.totalNegative}`,
      ]);
    }
  }, [allBusinessDTP, sevenDayNPS, multiDtpData, selectedButton]);

  const optionsLast7Days = {
    chart: {
      type: 'donut',
      width: '200px',
      height: '200px',
      borderWidth: 15,
    },
    colors: ['#41E998', '#A2A39E', '#E84949'],
    stroke: {
      width: 0,
      lineCap: 'round',
    },
    labels: sevenDayLabels,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true, // Hide the "Total" text
              formatter: function (val) {
                return `
                ${sevenDayNPS ? sevenDayNPS.toFixed(0) : 0}`; // Display the NPS value with a percentage symbol
              },
            },
            value: {
              fontSize: '45px',
              fontWeight: 'bold',
              offsetY: 15,
              color: colorYTDNPS,
            },
            name: {
              show: false,
              fontSize: '10px',
              offsetY: -0,
              color: '#000',
            },
          },
          size: '85%',
        },
        spacing: 1,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 280,
          },
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
      {
        breakpoint: 9999,
        options: {
          chart: {
            height: 350,
            width: 350,
          },
          legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    dataLabels: {
      style: {
        fontSize: '0px',
        fontFamily: 'Poppins, sans-serif',
      },
    },
  };

  //=========Range Selected NPS====================START

  let [rangeSeries, setRangeSeries] = useState([]); // Initialize as empty array
  let [rangeLabels, setRangeLabels] = useState([]); // Initialize as empty array
  const [rangeDayNPS, setRangeDayNPS] = useState();
  const [colorRangeNPS, setColorRangeNPS] = useState();

  const [minEndDate, setMinEndDate] = useState('');

  const handleRangeChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setMinEndDate(value);
    }
    setDateRange({ ...dateRange, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (startDate && endDate && multiDtpData?.reviewsInfo.length === 0) {
      const rangeStartDate = new Date(startDate);
      const rangeEndDate = new Date(endDate);
      rangeEndDate.setDate(rangeEndDate.getDate() + 1);

      let positivesCountRange = 0;
      let passivesCountRange = 0;
      let negativesCountRange = 0;

      allBusinessDTP?.npsData?.forEach((Bdtp) => {
        Bdtp.reviews.forEach((review) => {
          const reviewDate = new Date(review.createdAt); // Assuming review.createdAt is the review date
          if (reviewDate >= rangeStartDate && reviewDate < rangeEndDate) {
            if (review.rating === 3) {
              positivesCountRange++;
            } else if (review.rating === 2) {
              passivesCountRange++;
            } else if (review.rating === 1) {
              negativesCountRange++;
            }
          }
        });
      });

      if (selectedButton && selectedButton !== '') {
        let SelectDpt = allBusinessDTP?.npsData
          ?.filter((Bdtp) => Bdtp?.type === selectedButton)
          .forEach((Bdtp) => {
            Bdtp.reviews.forEach((review) => {
              const reviewDate = new Date(review.createdAt); // Assuming review.createdAt is the review date
              if (reviewDate >= rangeStartDate && reviewDate < rangeEndDate) {
                if (review.rating === 3) {
                  positivesCountRange++;
                } else if (review.rating === 2) {
                  passivesCountRange++;
                } else if (review.rating === 1) {
                  negativesCountRange++;
                }
              }
            });
          });
        console.log('SelectDpt', SelectDpt);
      }
      const overAllRange =
        positivesCountRange + passivesCountRange + negativesCountRange;
      let promotersRangePercentage = (positivesCountRange / overAllRange) * 100;
      let detractorsRangePercentage =
        (negativesCountRange / overAllRange) * 100;

      // Calculate the NPS of Range selected Days
      let npsRangeSelected =
        promotersRangePercentage - detractorsRangePercentage;

      if (npsRangeSelected != undefined) {
        if (npsRangeSelected >= 30) {
          setColorRangeNPS('#41E998'); // Green for NPS >= 30
        } else if (npsRangeSelected >= -30) {
          setColorRangeNPS('#F5A623');
        } else {
          setColorRangeNPS('#E84949');
        }
      }

      if (!isNaN(npsRangeSelected)) {
        setRangeDayNPS(npsRangeSelected);
      } else {
        setRangeDayNPS(0);
      }

      setRangeSeries([
        positivesCountRange,
        passivesCountRange,
        negativesCountRange,
      ]);

      let totalPromoters1 =
        parseInt(positivesCountRange) / parseInt(overAllRange);
      let totalPassives1 =
        parseInt(passivesCountRange) / parseInt(overAllRange);
      let totalDetractors1 =
        parseInt(negativesCountRange) / parseInt(overAllRange);

      setRangeLabels([
        `${language === 'en' ? 'Promoters' : 'Promotoras'}\n${(
          parseFloat(totalPromoters1 ? totalPromoters1 : 0) * 100
        ).toFixed(1)}% | ${positivesCountRange}`,
        `${language === 'en' ? 'Passives' : 'Pasivos'}\n${(
          parseFloat(totalPassives1 ? totalPassives1 : 0) * 100
        ).toFixed(1)}% | ${passivesCountRange}`,
        `${language === 'en' ? 'Detractors' : 'Detractoras'}\n${(
          parseFloat(totalDetractors1 ? totalDetractors1 : 0) * 100
        ).toFixed(1)}% | ${negativesCountRange}`,
      ]);
    }

    let npsOverAllData = 0;
    let multiDataPassives = 0;
    let multiDtpNps = 0;
    if (multiDtpData && multiDtpData?.reviewsInfo.length !== 0) {
      npsOverAllData =
        multiDtpData &&
        multiDtpData?.totalPositive +
          multiDtpData?.totalNeutral +
          multiDtpData?.totalNegative;

      multiDataPassives = (multiDtpData?.totalPositive / npsOverAllData) * 100;
      let multiDataDetractor =
        (multiDtpData?.totalNegative / npsOverAllData) * 100;

      multiDtpNps = multiDataPassives - multiDataDetractor;
    }

    if (multiDtpData != undefined && multiDtpData?.reviewsInfo.length !== 0) {
      if (!isNaN(multiDtpNps)) {
        setRangeDayNPS(multiDtpNps);
      } else {
        setRangeDayNPS(0);
      }

      if (multiDtpNps >= 30) {
        setColorRangeNPS('#41E998'); // Green for NPS >= 30
      } else if (multiDtpNps >= -30) {
        setColorRangeNPS('#F5A623');
      } else {
        setColorRangeNPS('#E84949');
      }

      setRangeSeries([
        multiDtpData?.totalPositive,
        multiDtpData?.totalNeutral,
        multiDtpData?.totalNegative,
      ]);

      let totalPromoters1 =
        parseInt(multiDtpData?.totalPositive) / parseInt(npsOverAllData);
      let totalPassives1 =
        parseInt(multiDtpData?.totalNeutral) / parseInt(npsOverAllData);
      let totalDetractors1 =
        parseInt(multiDtpData?.totalNegative) / parseInt(npsOverAllData);

      setRangeLabels([
        `${language === 'en' ? 'Promoters' : 'Promotoras'}\n${(
          parseFloat(totalPromoters1 ? totalPromoters1 : 0) * 100
        ).toFixed(1)}% | ${multiDtpData?.totalPositive}`,
        `${language === 'en' ? 'Passives' : 'Pasivos'}\n${(
          parseFloat(totalPassives1 ? totalPassives1 : 0) * 100
        ).toFixed(1)}% | ${multiDtpData?.totalNeutral}`,
        `${language === 'en' ? 'Detractors' : 'Detractoras'}\n${(
          parseFloat(totalDetractors1 ? totalDetractors1 : 0) * 100
        ).toFixed(1)}% | ${multiDtpData?.totalNegative}`,
      ]);
    }
  }, [
    startDate,
    endDate,
    allBusinessDTP,
    rangeDayNPS,
    multiDtpData,
    selectedButton,
  ]);

  const optionsSelectedRange = {
    chart: {
      type: 'donut',
      width: '200px',
      height: '200px',
      borderWidth: 15,
    },
    colors: ['#41E998', '#A2A39E', '#E84949'],
    stroke: {
      width: 0,
      lineCap: 'round',
    },
    labels: rangeLabels,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true, // Hide the "Total" text
              formatter: function (val) {
                return `${rangeDayNPS ? rangeDayNPS.toFixed(0) : 0}`;
              },
            },
            value: {
              fontSize: '45px',
              fontWeight: 'bold',
              offsetY: 15,
              color: colorYTDNPS,
            },
            name: {
              show: false,
              fontSize: '10px',
              offsetY: -0,
              color: '#000',
            },
          },
          size: '85%',
        },
        spacing: 1,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 280,
          },
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
      {
        breakpoint: 9999,
        options: {
          chart: {
            height: 350,
            width: 350,
          },
          legend: {
            position: 'right',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    dataLabels: {
      style: {
        fontSize: '0px',
        fontFamily: 'Poppins, sans-serif',
      },
    },
  };

  //=========Range Selected NPS====================END

  //=====================Doughout Chart js END======================//

  //=======================Google Map cities as well creating new cities coordinated alex map

  const MapContainer = ({ cityCoordinates }) => {
    const mapRef = useRef(null);

    useEffect(() => {
      if (cityCoordinates) {
        const loadMapScript = () => {
          if (!window.google || !window.google.maps || !mapRef.current) {
            const script = document.createElement('script');
            // script.src = `https://maps.googleapis.com/maps/api/js?key=${
            //   process.env.REACT_APP_GOOGLE_MAP_KEY
            // }&callback=initMap`;
            // script.src = `https://maps.googleapis.com/maps/api/js?key=s omekey&callback=initMap`;

            script.async = true;
            script.defer = true;
            window.initMap = initMap;
            document.body.appendChild(script);
          } else {
            initMap();
          }
        };

        const initMap = () => {
          const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 40.4637, lng: -3.7492 }, // Default center coordinates
            zoom: 4,
          });

          cityCoordinates &&
            cityCoordinates.forEach((city) => {
              const marker = new window.google.maps.Marker({
                position: { lat: city.lat, lng: city.lng },
                map: map,
                title: city,
              });
            });
        };

        loadMapScript();
      }
    }, [cityCoordinates]);

    return <div ref={mapRef} style={{ height: '100%', width: '100%' }}></div>;
  };

  function MapComponent() {
    const [cityCoordinates, setCityCoordinates] = useState([]);

    // useEffect(() => {
    //   const geocoder = new window.google.maps.Geocoder();

    //   const fetchCoordinates = async () => {
    //     if (Array.isArray(allBusinessDTP?.npsData)) {
    //       const coordinates = await Promise.all(
    //         allBusinessDTP?.npsData?.map(async (cityName) => {
    //           return new Promise((resolve) => {
    //             geocoder.geocode(
    //               { address: cityName.city },
    //               (results, status) => {
    //                 if (status === "OK") {
    //                   resolve({
    //                     name:
    //                       cityName.city +
    //                       " " +
    //                       `(${cityName.npsLast30Days?.npsLast30Days.toFixed(
    //                         0
    //                       )} NPS)`,
    //                     lat: results[0].geometry.location.lat(),
    //                     lng: results[0].geometry.location.lng(),
    //                   });
    //                 } else {
    //                   resolve(null);
    //                 }
    //               }
    //             );
    //           });
    //         })
    //       );

    //       setCityCoordinates(coordinates.filter((coord) => coord !== null));
    //     }
    //   };

    //   fetchCoordinates();
    // }, [allBusinessDTP?.npsData]);

    return (
      <>
        {cityCoordinates && <MapContainer cityCoordinates={cityCoordinates} />}
      </>
    );
  }

  //---------------===========Tree Map chart ---------==================//
  const [barSeries, setBarSeries] = useState([]);

  useEffect(() => {
    // const selectedDTP = allBusinessDTP?.npsData?.find(
    //   (dataTouchPoint) => dataTouchPoint?.dataTouchPointId === dtpId
    // );

    let updatedBarSeries = [];

    if (multiDtpData && multiDtpData?.reviewsInfo.length !== 0) {
      // If a multi DTP is selected, update the barSeries using the selectedDTP's top5Reasons
      updatedBarSeries = multiDtpData?.top5Reasons
        .slice(0, 5)
        .map((reason) => ({
          name: reason.name,
          data: reason.data,
        }));
      setBarSeries(updatedBarSeries);
    } else if (
      multiDtp.length === 0 &&
      selectedButton &&
      selectedButton !== ''
    ) {
      const selectedBusinessNPSData = allBusinessDTP?.npsData?.filter(
        (dataTouchPoint) => dataTouchPoint.type === selectedButton
      );

      if (selectedBusinessNPSData && selectedBusinessNPSData.length > 0) {
        const overallTop5Reasons = selectedBusinessNPSData.reduce(
          (acc, dataTouchPoint) => {
            dataTouchPoint.top5Reasons.forEach((reason) => {
              const existingReason = acc.find((r) => r.name === reason.name);
              if (existingReason) {
                existingReason.data = existingReason.data.map(
                  (value, index) => value + reason.data[index]
                );
              } else {
                acc.push({ name: reason.name, data: [...reason.data] });
              }
            });
            return acc;
          },
          []
        );
        updatedBarSeries = overallTop5Reasons.slice(0, 5);

        setBarSeries(updatedBarSeries);
      }
    } else {
      // If no specific DTP is selected, update the barSeries using overall top5Reasons
      const overallTop5Reasons = allBusinessDTP?.npsData?.reduce(
        (acc, dataTouchPoint) => {
          dataTouchPoint.top5Reasons.forEach((reason) => {
            const existingReason = acc.find((r) => r.name === reason.name);
            if (existingReason) {
              existingReason.data = existingReason.data.map(
                (value, index) => value + reason.data[index]
              );
            } else {
              acc.push({ name: reason.name, data: reason.data });
            }
          });
          return acc;
        },
        []
      );

      updatedBarSeries = overallTop5Reasons?.slice(0, 5);
      // console.log('mkmkmk=======', updatedBarSeries);
      setBarSeries(updatedBarSeries);
    }
  }, [allBusinessDTP?.npsData, multiDtpData, selectedButton]);

  const [barOptions] = useState({
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 280,
          },
          legend: {
            position: 'bottom',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: false, // Disable the total dataLabels
          },
        },
      },
    },
    xaxis: {
      categories: [
        language === 'en' ? 'Promoters' : 'Promotoras',
        language === 'en' ? 'Passives' : 'Pasivos',
        language === 'en' ? 'Detractors' : 'Detractoras',
      ], // Adjust the categories
      labels: {
        show: true, // Hide x-axis labels
      },
    },
    yaxis: {
      show: false, // Hide the vertical axis
    },
    legend: {
      show: true,
      position: 'right',
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false, // Hide gridlines
    },
  });

  //---------------===========Tree Map chart ---------==================//

  //====================Line Chart=======================//

  const [timeFrameLabel, setTimeFrameLabel] = useState([]);
  const [timeFrameData, setTimeFrameData] = useState([]);
  const [compTimeFrameData, setCompTimeFrameData] = useState([]);
  const [filterData, setFilterData] = useState('hour');

  useEffect(() => {
    if (multiDtp !== undefined) {
      if (
        multiDtpData &&
        multiDtpData?.result?.hourWiseNps &&
        filterData === 'hour'
      ) {
        setTimeFrameLabel(multiDtpData?.result?.hourWiseNps?.name);
        setTimeFrameData(multiDtpData?.result?.hourWiseNps?.data);
      }

      if (
        multiDtpData &&
        multiDtpData?.result?.dayWiseNps &&
        filterData === 'daily'
      ) {
        setTimeFrameLabel(multiDtpData?.result?.dayWiseNps?.name);
        setTimeFrameData(multiDtpData?.result?.dayWiseNps?.data);
      }
      if (
        multiDtpData &&
        multiDtpData?.result?.weekWiseNps &&
        filterData === 'week'
      ) {
        setTimeFrameLabel(multiDtpData?.result?.weekWiseNps?.name);
        setTimeFrameData(multiDtpData?.result?.weekWiseNps?.data);
      }
      if (
        multiDtpData &&
        multiDtpData?.result?.monthWiseNps &&
        filterData === 'month'
      ) {
        setTimeFrameLabel(multiDtpData?.result?.monthWiseNps?.name);
        setTimeFrameData(multiDtpData?.result?.monthWiseNps?.data);
      }
      if (
        multiDtpData &&
        multiDtpData?.result?.yearWiseNps &&
        filterData === 'year'
      ) {
        setTimeFrameLabel(multiDtpData?.result?.yearWiseNps?.name);
        setTimeFrameData(multiDtpData?.result?.yearWiseNps?.data);
      }

      //+++++++COmpany
      if (multiDtpData && multiDtpData?.companyResult) {
        if (
          multiDtpData &&
          multiDtpData?.companyResult?.hourWiseNps &&
          filterData === 'hour'
        ) {
          setTimeFrameLabel(multiDtpData?.companyResult?.hourWiseNps?.name);
          setCompTimeFrameData(multiDtpData?.companyResult?.hourWiseNps?.data);
        }
        if (
          multiDtpData &&
          multiDtpData?.companyResult?.dayWiseNps &&
          filterData === 'daily'
        ) {
          setTimeFrameLabel(multiDtpData?.companyResult?.dayWiseNps?.name);
          setCompTimeFrameData(multiDtpData?.companyResult?.dayWiseNps?.data);
        }
        if (
          multiDtpData &&
          multiDtpData?.companyResult?.weekWiseNps &&
          filterData === 'week'
        ) {
          setTimeFrameLabel(multiDtpData?.companyResult?.weekWiseNps?.name);
          setCompTimeFrameData(multiDtpData?.companyResult?.weekWiseNps?.data);
        }
        if (
          multiDtpData &&
          multiDtpData?.companyResult?.monthWiseNps &&
          filterData === 'month'
        ) {
          setTimeFrameLabel(multiDtpData?.companyResult?.monthWiseNps?.name);
          setCompTimeFrameData(multiDtpData?.companyResult?.monthWiseNps?.data);
        }
        if (
          multiDtpData &&
          multiDtpData?.companyResult?.yearWiseNps &&
          filterData === 'year'
        ) {
          setTimeFrameLabel(multiDtpData?.companyResult?.yearWiseNps?.name);
          setCompTimeFrameData(multiDtpData?.companyResult?.yearWiseNps?.data);
        }
      }
    }
  }, [multiDtpData, filterData]);
  const benchmarkNPSData =
    allBusinessDTP?.benchMarkData &&
    allBusinessDTP?.benchMarkData?.map((item) => {
      return {
        label: item.name,
        data: item.npsData.map((dataPoint) => dataPoint.overAllNps),
        // borderColor: getRandomColor(),
        // backgroundColor: getRandomColor(),
        hidden: false,
      };
    });

  // function getRandomColor() {
  //   const letters = "0123456789ABCDEF";
  //   let color = "#";
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // }

  //=================CHART

  const chartOptions = {
    chart: {
      type: 'line',
      height: 350,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 4, // Set the line width
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        style: {
          colors: '#8e8da4',
        },
        offsetY: -7,
        offsetX: 0,
      },
    },
    yaxis: {
      min: -100,
      max: 100,
      tickAmount: 10, // You can adjust the number of ticks as needed
      labels: {
        style: {
          colors: '#8e8da4',
        },
        offsetY: -7,
        offsetX: 0,
        formatter: function (value) {
          if (value !== undefined) {
            return value.toFixed(0); // Ensure no decimal points
          }
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      x: {
        format: 'HH:mm', // Tooltip format for the x-axis
      },
      fixed: {
        enabled: false,
        position: 'topRight',
      },
    },
    grid: {
      show: false,
      yaxis: {
        lines: {
          offsetX: -30,
        },
      },
      padding: {
        left: 10,
      },
    },
  };

  // const convertToLocalTimeZone = (date) => {
  //   const newDate = new Date(date);
  //   return newDate?.toLocaleTimeString([], {
  //     year: '2-digit',
  //     month: '2-digit',
  //     day: '2-digit',

  //     hour: '2-digit',
  //     minute: '2-digit',
  //     hour12: false,
  //   });
  // };

  // const convertToLocalTimeZone = (dateString) => {
  //   const date = new Date(dateString);
  //   const options = {
  //     year: 'numeric',
  //     month: '2-digit',
  //     day: '2-digit',
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     hour12: false,
  //     timeZone: 'UTC', // Make sure to specify the timezone of the input date string
  //   };

  //   const localTimeString = date.toLocaleTimeString('en-US', options);
  //   return `${localTimeString}`;
  // };

  const convertToLocalTimeZone = (date) => {
    const localDate = new Date(date); // Convert to local time zone
    return format(localDate, 'dd-MM-yyyy HH:mm', {
      useAdditionalWeekYearTokens: true,
    });
  };

  const sliceIndex = Math.max(0, timeFrameLabel.length - 15);
  const slicedTimeFrameLabel = timeFrameLabel.slice(sliceIndex);

  const southData = slicedTimeFrameLabel.map((label, index) => ({
    x: convertToLocalTimeZone(label),
    // x: label,
    y: compTimeFrameData[index + sliceIndex],
  }));

  const northData = slicedTimeFrameLabel.map((label, index) => ({
    x: convertToLocalTimeZone(label),
    y: timeFrameData[index + sliceIndex],
  }));

  // Format your data for different datasets
  // console.log('time frame data', timeFrameData);
  // const northData = timeFrameLabel.map((label, index) => ({
  //   x: convertToLocalTimeZone(label),
  //   // x: label,
  //   y: timeFrameData[index],
  // }));
  // const southData = timeFrameLabel.map((label, index) => ({
  //   x: convertToLocalTimeZone(label),
  //   // x: label,
  //   y: compTimeFrameData[index],
  // }));

  const benchmarkData = benchmarkNPSData?.map((dataSet) => ({
    name: dataSet.label,
    data: timeFrameLabel.map((label, index) => ({
      x: label,
      y: dataSet.data[index],
    })),
  }));

  // Combine all datasets into a single array
  const chartSeries = [
    {
      name: (multiDtp?.length !== 0 && multiDtp.map((d) => d.label)) || '',
      data: northData,
    },
    {
      name: 'Selected Business',
      data: southData,
    },
    ...(Array.isArray(benchmarkData) ? benchmarkData : []), // Ensure benchmarkData is an array
  ];

  useEffect(() => {
    handleButtonClick('Customer Experience');

    return () => {};
  }, []);

  return (
    <>
      <div className="container-fluid py-3">
        <div className="row">
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <h6 className="dashboard  fw-500 d-flex align-items-center mt-2">
              <Trans>Dashboard</Trans>
            </h6>

            <div className="d-flex gap-2 flex-wrap ">
              <div>
                <button
                  type="button"
                  className={`btn w-100 btn-dash text-nowrap rounded-pill   ${
                    selectedButton === 'Customer Experience' ? 'selected' : ''
                  }`}
                  onClick={() => handleButtonClick('Customer Experience')}
                >
                  <Trans>Customer Experience</Trans>
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className={`btn w-100 btn-dash text-nowrap   rounded-pill  ${
                    selectedButton === 'Employee Sentiment' ? 'selected' : ''
                  }`}
                  onClick={() => handleButtonClick('Employee Sentiment')}
                >
                  <Trans>EmployeeSentiment</Trans>
                </button>
              </div>
              {/* {selectedButton !== "" && (
                    <div
                      className={`${selectedButton !== "" ? "col-2" : "col-0"}`}
                    >
                      <button
                        type="button"
                        className="btn btn-loginAs text-nowrap  rounded-pill"
                        onClick={() => setSelectedButton("")}
                      >
                        <FontAwesomeIcon icon={faClose} />
                      </button>
                    </div>
                  )} */}
            </div>
          </div>
        </div>

        <div className="row my-2">
          <div className="col-md-6 col-lg-6 mb-2">
            <div className="chart-div card shadow-sm">
              <div className="card-header">
                <h5 className="mb-0">
                  <Trans>NPS Last 30 days</Trans>
                </h5>
              </div>
              <div
                id="chart"
                className="small-chart card-body OverFlow p-1  mx-auto mt-1"
              >
                <ReactApexChart
                  options={optionsLast30d}
                  series={thirtyDaySeries}
                  type="donut"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 mb-2">
            <div className="chart-div card shadow-sm">
              <div className="card-header">
                <h5 className="mb-0">
                  <Trans>NPS YTD</Trans>
                </h5>
              </div>
              <div
                id="chart"
                className="small-chart card-body OverFlow p-1  mx-auto mt-1"
              >
                <ReactApexChart
                  options={optionsYTD}
                  series={YTDSeries}
                  type="donut"
                />
              </div>
            </div>
          </div>

          <hr className="mt-3" />
        </div>

        {/* //=====Filter Date Range and Multi Select DTP ===== */}
        <div className="row">
          <div className="col-12 col-md-6 mb-2">
            <div className="mob-flex" id="filer">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="date"
                    className="form-control m-0"
                    name="startDate"
                    value={startDate}
                    onChange={handleRangeChange}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div className="form-group">
                  <input
                    type="date"
                    className="form-control m-0"
                    name="endDate"
                    value={endDate}
                    min={minEndDate}
                    onChange={handleRangeChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-2">
            {multiDtp && (
              <Select
                id="multiple-select-field"
                isMulti
                options={allBusinessDTP?.npsData?.map((dataTouchPoint) => ({
                  label: dataTouchPoint.name,
                  value: dataTouchPoint.dataTouchPointId,
                }))}
                onChange={handleMultiDtpSelection}
                // value={multiDtp}
              />
            )}
          </div>
        </div>

        {/* //=====Filter Date Range and Multi Select DTP END===== */}

        <div className="row">
          <div className="col-md-6 mb-2">
            <div className="card p-0 card-height shadow-sm">
              <div className="card-header">
                <h4 className="mb-0">NPS</h4>
              </div>
              <div className="card-body">
                {startDate && (
                  <h6>
                    {' '}
                    {startDate &&
                      endDate &&
                      moment(startDate).format('DD-MM-YYYY') +
                        ' -- ' +
                        moment(endDate).format('DD-MM-YYYY')}{' '}
                  </h6>
                )}
                <div
                  id="chart"
                  className="small-chart card-body p-1 mx-auto mt-md-5 mt-0"
                >
                  {startDate && endDate ? (
                    <ReactApexChart
                      options={optionsSelectedRange}
                      series={rangeSeries}
                      type="donut"
                    />
                  ) : (
                    <ReactApexChart
                      options={optionsLast7Days}
                      series={sevenDaySeries}
                      type="donut"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-2">
            <div className="card p-0 card-height shadow-sm">
              <div className="card-header">
                <h4 className="mb-0">
                  <Trans>Reason of Votes</Trans>
                </h4>
              </div>
              <div className="card-body">
                <div
                  id="Treechart"
                  className="small-chart card-body OverFlow p-1  mx-auto mt-1"
                >
                  <ReactApexChart
                    options={barOptions}
                    series={barSeries}
                    type="bar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Graph and Map */}
        <div className="row my-3">
          {/* <div className="col-12 col-md-6 mb-2">
            <div className="card card-height-btm shadow-sm">
              <div className="card-header">
                <h5 className="mb-0">Data Touchpoint Location</h5>
              </div>
              <div className="card-body">
                <div className="map-container">
                  <MapComponent />
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-12 col-md-12 mb-2">
            <div className="card card-height-btm shadow-sm">
              <div className="card-header mb-0">
                <div className="mbl-graph ">
                  <div>
                    <h5 className="mb-0">
                      <Trans>NPS Evolution</Trans>
                    </h5>
                  </div>
                  <div>
                    <div className="d-flex justify-content-center gap-2">
                      <button
                        onClick={() => setFilterData('hour')}
                        className={
                          filterData === 'hour'
                            ? 'btn-chart active'
                            : 'btn-chart'
                        }
                      >
                        <Trans>Hour</Trans>
                      </button>
                      <button
                        onClick={() => setFilterData('daily')}
                        className={
                          filterData === 'daily'
                            ? 'btn-chart active'
                            : 'btn-chart'
                        }
                      >
                        <Trans>Day</Trans>
                      </button>
                      <button
                        onClick={() => setFilterData('week')}
                        className={
                          filterData === 'week'
                            ? 'btn-chart active'
                            : 'btn-chart'
                        }
                      >
                        <Trans>Week</Trans>
                      </button>
                      <button
                        onClick={() => setFilterData('month')}
                        className={
                          filterData === 'month'
                            ? 'btn-chart active'
                            : 'btn-chart'
                        }
                      >
                        <Trans>Month</Trans>
                      </button>
                      <button
                        onClick={() => setFilterData('year')}
                        className={
                          filterData === 'year'
                            ? 'btn-chart active'
                            : 'btn-chart'
                        }
                      >
                        <Trans>Year</Trans>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="mbl-top">
                  <ReactApexChart
                    options={chartOptions}
                    series={chartSeries}
                    type="line"
                    height={350}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
