import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faSearch } from '@fortawesome/free-solid-svg-icons';

import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';

import { Modal, Button, Form, Alert } from 'react-bootstrap';
import ReactLoading from 'react-loading';
//=========Redux===========
import { useSelector, useDispatch } from 'react-redux';
import { getAllSubscriptions } from '../../../../redux/subscription/subscriptionSlice';
import {
  availSubscription,
  // updateBusinessSub,
  // updateBusinessBenchMark,
  getAllSubReq,
  reset,
  updateSubReqStatus,
} from '../../../../redux/auth/authSlice';
// import {
//   getAllMoreDTPRequst,
//   updateMoreDTPRequest,
// } from "../../../../redux/additionalDtpRequest/additionalDtpSlice";

// import { Country, City } from "country-state-city";
import { toast } from 'react-toastify';

import { RotatingLines } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import ButtonLoading from '../../../../common/ButtonLoading';

// import "./csub.css";

//========ENV VARIABLES========
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const Csubscription = () => {
  const { language, setLanguage } = useMyContext();
  const dispatch = useDispatch();
  const { allSubscriptions } = useSelector((state) => {
    return state.subscription;
  });

  const {
    profile,
    allSubRequests,
    isSuccess,
    isLoading,
    isLoadingAvailSubscription,
  } = useSelector((state) => {
    return state.auth;
  });

  // const { allAdditionalDTPReq, isSuccess } = useSelector((state) => {
  //   return state.additionalDtpRequest;
  // });

  //============Get All Subscriptions =============================

  useEffect(() => {
    dispatch(getAllSubscriptions({ verificationCode }));
    // dispatch(getAllMoreDTPRequst({ verificationCode })); //we will use it later
    dispatch(getAllSubReq({ verificationCode }));
  }, [dispatch]);
  //============Get All Subscriptions End=============================

  //============Handle Subscribe subscription============
  const [showViewSub, setShowViewSub] = useState(false);
  const handleViewSubClose = () => setShowViewSub(false);

  let SType;
  const [viewData, setViewData] = useState(null);

  if (viewData?.subscriptionType === 'Benchmarks') {
    SType = 'Benchmarks';
  }
  if (viewData?.subscriptionType === 'Touchpoints') {
    SType = 'Touchpoints';
  }
  const [statistics, setStatistics] = useState(false);
  const [emailModule, setEmailModule] = useState(false);
  const [isOnePerCompany, setIsOnePerCompany] = useState(false);
  //Setting extra quantity
  const [extraQtn, setExtraQtn] = useState(0);
  const [subTotalPrice, setSubTotalPrice] = useState(0);

  const handleViewSub = (sub) => {
    setViewData(sub);
    if (sub) {
      if (sub?.subscriptionType === 'Benchmarks') {
        setExtraQtn(sub.benchMark);
      } else {
        setExtraQtn(sub.allowedDTP);
      }
    }
    setStatistics(sub?.statistics);
    setEmailModule(sub?.emailModule);
    setIsOnePerCompany(sub?.isOnePerCompany);
    setShowViewSub(true);
  };

  // Calculate total value based on the selected sticker size and units
  const calculateTotalSubVal = () => {
    if (viewData) {
      const totalVal = parseFloat(viewData?.priceAfterVat) * parseInt(extraQtn);
      setSubTotalPrice(isNaN(totalVal) ? 0 : totalVal);
    }
  };

  useEffect(() => {
    calculateTotalSubVal();
  }, [viewData, extraQtn]);

  const handleAvailSubscription = (e) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      selectedSubscription: viewData._id,
      units: extraQtn,
      price: viewData?.price,
      vat: viewData?.vat,
      totalPrice: subTotalPrice,
      reqType: SType,
    };
    dispatch(availSubscription(body));
  };

  //=============Search Field================
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  // Apply the filter based on the input text
  const filteredData = searchQuery
    ? allSubscriptions.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : allSubscriptions;

  //=================Search field for Additional Subscription to get more
  const [searchReqQuery, setSearchReqQuery] = useState('');

  const handleSearchReqChange = (event) => {
    setSearchReqQuery(event.target.value);
  };
  const filteredReqData = searchReqQuery
    ? allSubRequests.filter(
        (item) =>
          item.business.name_of_business
            .toLowerCase()
            .includes(searchReqQuery.toLowerCase()) ||
          item.business.email
            .toLowerCase()
            .includes(searchReqQuery.toLowerCase()) ||
          item?.selectedSubscription.name
            .toLowerCase()
            .includes(searchReqQuery.toLowerCase())
      )
    : allSubRequests;

  //=============Payment Modal Form And Api ================

  //=============Payment Form And Api ================++++++++++++++++++++++++++++++++++++++++++++++++
  const [paymentData, setPaymentData] = useState({});

  const [showModalPayment, setShowModalPayment] = useState(false);
  const handlePaymentClose = () => setShowModalPayment(false);
  const [CitiesLoading, setCitiesLoading] = useState(false);
  const handlePaymentShow = (data) => {
    setPaymentData(data);
    setShowModalPayment(true);
  };

  //=================================Card Validations START=================================
  //========CVV  =================================

  const [cvv, setCvv] = useState('');

  const handleCvvChange = (e) => {
    const value = e.target.value;
    const regex = /^\d{0,3}$/; // accepts 0-3 digits
    if (regex.test(value)) {
      setCvv(value);
    }
  };

  //========Expiry Card Checks =================================
  const [expiryDate, setExpiryDate] = useState('');
  const [expiryCardMessage, setExpiryCardMessage] = useState('');
  const [isExpired, setIsExpired] = useState(false);

  const handleExpiryDateChange = (event) => {
    let input = event.target.value;

    // Remove any non-numeric characters
    input = input.replace(/[^\d]/g, '');
    const expiryMonth = parseInt(input.substring(0, 2));
    const expiryYear = parseInt(input.substring(3));
    const currentYear = new Date().getFullYear() % 100;
    const currentMonth = new Date().getMonth() + 1;

    // Check if the expiry month is greater than 12 and set it to 12
    if (expiryMonth > 12) {
      input = '12/' + input.substring(2);
    }

    if (
      expiryYear < currentYear ||
      (expiryYear === currentYear && expiryMonth < currentMonth)
    ) {
      setIsExpired(true);
    } else {
      setIsExpired(false);
    }

    // Format the expiry date with a slash after 2 digits
    if (input.length > 2) {
      input = input.slice(0, 2) + '/' + input.slice(2);
    }

    setExpiryDate(input);
  };

  const validateExpiryDate = () => {
    const [expiryMonth, expiryYear] = expiryDate.split('/');

    // Check if the expiry date is valid and more than the current date
    const now = new Date();
    const expiry = new Date(`20${expiryYear}`, expiryMonth - 1, 1);
    if (expiry <= now || isNaN(expiry)) {
      setExpiryCardMessage('Invalid expiry date');
    }

    return '';
  };

  //=======Credit Card No Added by Alex
  let [validCardNo, setValidCardNo] = useState(false);
  const [cardNumber, setCardNumber] = useState('');

  const handleCardNoChange = (event) => {
    let { value } = event.target;
    value = value.replace(/\D/g, '');
    // only allow 16 digits
    if (value.length > 16) {
      value = value.slice(0, 16);
    }
    value = value.replace(/(\d{4})/g, '$1-');
    // remove trailing dash
    value = value.replace(/-$/, '');
    setCardNumber(value);
    // check if number is valid using Luhn's algorithm
    const luhnCheck = (ccNum) => {
      let checkSum = 0;
      let isEven = false;

      for (let i = ccNum.length - 1; i >= 0; i--) {
        let digit = parseInt(ccNum.charAt(i));
        if (isEven) {
          digit *= 2;
          if (digit > 9) {
            digit -= 9;
          }
        }
        checkSum += digit;
        isEven = !isEven;
      }
      return checkSum % 10 === 0;
    };

    if (luhnCheck(value)) {
      setValidCardNo(true);
    } else {
      setValidCardNo(false);
    }
  };

  //=================================Card Validations END=================================
  //===========Country and Cities Selection START=================================
  const [selectedCountry, setSelectedCountry] = useState('');
  const [cities, setCities] = useState([]);

  const handleCountryChange = async (event) => {
    const countryCode = event.target.value;
    setSelectedCountry(countryCode);

    setCitiesLoading(true);
    try {
      const response = await fetch(
        'https://countriesnow.space/api/v0.1/countries/cities',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ country: countryCode }),
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Handle the response data
      const responseData = await response.json();

      setCities(responseData?.data);
      setCitiesLoading(false);
    } catch (error) {
      console.error('Error during POST request:', error);
    }
  };
  //===========Country and Cities Selection END=================================

  const [priceAfterVat, setPriceAfterVat] = useState(0);
  const [formValues, setFormValues] = useState({
    address: '',
    city: '',
    zip: '',
    vat: 0,
    currencyType: '€',
    agreeTerms: false,
  });

  const { address, city, zip, vat, currencyType, agreeTerms } = formValues;

  const calculateVATValue = () => {
    if (!isNaN(paymentData?.totalPrice) && !isNaN(vat)) {
      const priceNum = parseFloat(paymentData && paymentData?.totalPrice);
      const vatNum = parseFloat(vat);
      const vatAmount = (priceNum * vatNum) / 100;
      setPriceAfterVat((priceNum + vatAmount).toFixed(2));
    }
  };

  useEffect(() => {
    calculateVATValue();
  }, [paymentData?.totalPrice, vat]);

  const handlePaymentChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormValues((prevValues) => ({ ...prevValues, [name]: fieldValue }));
  };

  const [AllCountry, setAllCountry] = useState();
  const fetchData = async () => {
    try {
      await fetch(`https://countriesnow.space/api/v0.1/countries`).then(
        async (response) => {
          let data = await response.json();

          setAllCountry(data.data);
        }
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmitPayment = (e) => {
    e.preventDefault();
    if (cardNumber == '' || cvv == '' || expiryDate == '') {
      toast.warning('Please Fill All required Fields.');
      return;
    }

    if (cardNumber.length < 19) {
      toast.error('Card Number should be 16 digits.');
      return;
    }

    if (expiryDate.length < 7) {
      toast.error('Date Should be in MM/YYYY format.');
      return;
    }
    if (cvv.length < 3) {
      toast.error('Cvv Number should be 3 digits.');
      return;
    }

    let body = {
      verificationCode: verificationCode,
      reqId: paymentData?._id,
      units: paymentData?.units,
      cardNumber: cardNumber,
      cvv: cvv,
      expiryDate: expiryDate,
      amount: paymentData?.totalPrice,
      currency: currencyType,
      address: address,
      city: city,
      country: selectedCountry,
      zip: zip,
      agreeTerms: agreeTerms,
      vat: vat,
      priceAfterVat: priceAfterVat,
      reqType: paymentData?.reqType,
      product: paymentData?.selectedSubscription?.name,
    };
    dispatch(updateSubReqStatus(body));
  };

  //=============Payment Modal Form And Api End================

  //================Resetting data on success-------------------------
  const [payLoad, setPayLoad] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setFormValues({
        address: '',
        city: '',
        zip: '',
        vat: 0,
        currencyType: '€',
        agreeTerms: false,
      });
      handlePaymentClose();
      handleViewSubClose();
      setPayLoad(false);
    }
    dispatch(reset());
  }, [isSuccess]);

  useEffect(() => {
    if (isLoading) {
      setPayLoad(true);
    }
    dispatch(reset());
  }, [isLoading]);

  const [OpenModalBankTransfer, setOpenModalBankTransfer] = useState(false);

  function handlerModalBankTransfer() {
    setOpenModalBankTransfer(true);
    handleViewSubClose(false);
  }
  function handleClose(params) {
    setOpenModalBankTransfer(false);
  }

  const [AccountDetails, setAccountDetails] = useState('');
  const [AccountNumber, setAccountNumber] = useState('');
  const [BankName, setBankName] = useState('');
  const [Accountholdername, setAccountholdername] = useState('');

  function handlerModalBankTransfer() {
    let body = {
      verificationCode: verificationCode,
      selectedSubscription: viewData._id,
      units: extraQtn,
      price: viewData?.price,
      vat: viewData?.vat,
      totalPrice: subTotalPrice,
      reqType: SType,
      paymentMethod: 'bank_transfer',
    };
    dispatch(availSubscription(body));
    handleClose();
  }

  return (
    <>
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col-md-12">
            <div className="mob-flex justify-content-between">
              <h3 className="d-flex align-items-center m-0 fw-500">
                <Trans>Shop</Trans>
              </h3>
            </div>
          </div>
        </div>
        {/* new Date(sub?.endDate).getTime() > new Date() */}
        <div className="row mt-3">
          {allSubscriptions &&
            allSubscriptions?.length !== 0 &&
            allSubscriptions?.map((sub, i) =>
              true ? (
                <div className="col-md-3 col-lg-2 d-flex" key={i}>
                  <div
                    className="sub-cards my-2"
                    id="sub_card"
                    style={{
                      // position: "relative",
                      // minHeight: "210px",
                      background:
                        profile?.selectedBenchMark &&
                        profile?.selectedBenchMark._id === sub._id
                          ? '#2d4b64 '
                          : '' ||
                            (profile?.selectedSubscription &&
                              profile?.selectedSubscription._id === sub._id)
                          ? ' #2d4b64 '
                          : '',
                      color:
                        profile?.selectedBenchMark &&
                        profile?.selectedBenchMark._id === sub._id
                          ? 'white'
                          : '' ||
                            (profile?.selectedSubscription &&
                              profile?.selectedSubscription._id === sub._id)
                          ? 'white '
                          : '',
                    }}
                  >
                    <h5
                      className="blueHead text-center fw-600"
                      style={{
                        color:
                          profile?.selectedBenchMark &&
                          profile?.selectedBenchMark._id === sub._id
                            ? 'white'
                            : '' ||
                              (profile?.selectedSubscription &&
                                profile?.selectedSubscription._id === sub._id)
                            ? 'white '
                            : '',
                      }}
                    >
                      {sub?.name}
                    </h5>
                    {sub?.allowedDTP !== 0 ? (
                      <h6
                        className="text-center"
                        style={{
                          color:
                            profile?.selectedBenchMark &&
                            profile?.selectedBenchMark._id === sub._id
                              ? 'white'
                              : '' ||
                                (profile?.selectedSubscription &&
                                  profile?.selectedSubscription._id === sub._id)
                              ? 'white '
                              : '',
                        }}
                      >
                        {/* {sub?.allowedDTP} */}
                        {sub?.description}
                      </h6>
                    ) : (
                      sub?.benchMark !== 0 && (
                        <h6
                          className="text-center"
                          style={{
                            color:
                              profile?.selectedBenchMark &&
                              profile?.selectedBenchMark._id === sub._id
                                ? 'white'
                                : '' ||
                                  (profile?.selectedSubscription &&
                                    profile?.selectedSubscription._id ===
                                      sub._id)
                                ? 'white '
                                : '',
                          }}
                        >
                          {' '}
                          Up to {sub?.benchMark} Benchmarks
                        </h6>
                      )
                    )}
                    <h5
                      className="text-center"
                      style={{
                        color:
                          profile?.selectedBenchMark &&
                          profile?.selectedBenchMark._id === sub._id
                            ? 'white'
                            : '' ||
                              (profile?.selectedSubscription &&
                                profile?.selectedSubscription._id === sub._id)
                            ? 'white '
                            : '',
                      }}
                    >
                      {sub?.priceAfterVat} {sub?.currencyType}
                      <small>/{sub?.monthlyAnnual}</small>{' '}
                    </h5>
                    {new Date(sub?.endDate).getTime() > new Date() ? (
                      <div className="d-flex justify-content-center">
                        <button
                          className="btn btn-sub margnbtn w-75"
                          onClick={() => handleViewSub(sub)}
                        >
                          Subscribe
                        </button>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <button className="btn btn-sub margnbtn w-75">
                          Expired {moment(sub?.endDate).format('DD/MM/YYYY')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ) : null
            )}
        </div>

        {allSubRequests && allSubRequests?.length > 0 ? (
          <div className="row my-5">
            <div className="col-md-12">
              <div className="d-flex justify-content-between">
                <div className=" my-auto">
                  <h5>
                    <Trans>Subscription</Trans>
                  </h5>
                </div>
                <div className="search-field search-bar my-auto">
                  <input
                    type="text"
                    placeholder={language === 'en' ? 'Search' : 'Búsqueda'}
                    className="dashboardinput"
                    id="userSearch"
                    value={searchReqQuery}
                    onChange={handleSearchReqChange}
                  />
                  <span className="search-icon">
                    <FontAwesomeIcon icon={faSearch} className="usersearch" />
                  </span>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center text-nowrap">Tx ID</th>
                      <th className="text-center">
                        <Trans>VAD ID</Trans>
                      </th>
                      <th className="text-center">
                        <Trans>Product Name</Trans>
                      </th>
                      <th className="text-center">
                        <Trans>Reviews Limit</Trans>
                      </th>
                      <th className="text-center">
                        <Trans>Requested Type</Trans>
                      </th>
                      <th className="text-center">
                        <Trans>Total Amount</Trans>
                      </th>
                      <th className="text-center text-nowrap">
                        <Trans>Product Expiry</Trans>
                      </th>
                      <th className="text-center">
                        <Trans>Status</Trans>
                      </th>
                      {/* <th className="text-center">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      ...filteredReqData?.filter(
                        (ATPReq) => ATPReq?.status === false
                      ),
                      ...filteredReqData?.filter(
                        (ATPReq) => ATPReq?.status === true
                      ),
                    ].map((ATPReq, i) => (
                      <tr key={i}>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-center">{ATPReq?.business?.vat}</td>
                        <td className="text-center">
                          {ATPReq?.selectedSubscription?.name}
                        </td>
                        <td className="text-center">
                          {ATPReq?.selectedSubscription?.maxVoteAllowed}
                        </td>
                        <td className="text-center">{ATPReq?.reqType}</td>
                        <td className="text-center">{ATPReq?.totalPrice} €</td>

                        <td className="text-center">
                          {moment(ATPReq?.selectedSubscription?.endDate).format(
                            'DD-MM-YYYY'
                          )}
                        </td>
                        <td className="text-center">
                          {ATPReq?.status === false ? (
                            <span className="badge border-0 bg-danger p-2">
                              <Trans>Unpaid</Trans>
                            </span>
                          ) : (
                            <span className="badge border-0 bg-success p-2">
                              <Trans>Paid</Trans>
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {/* //=====Payment subscription modal=========== */}

      {/*  Payment Modal  */}
      <Modal
        show={showModalPayment}
        onHide={handlePaymentClose}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="row g-1" onSubmit={handleSubmitPayment}>
            <Form.Group className="col-md-6" controlId="cardNumber">
              <Form.Label>Card Number *</Form.Label>
              <input
                className="form-control"
                type="text"
                placeholder="XXXX-XXXX-XXXX-XXXX"
                value={cardNumber}
                onChange={handleCardNoChange}
              />
            </Form.Group>

            <Form.Group className="col-md-6" controlId="expiryDate">
              <Form.Label>Expiry Date *</Form.Label>
              <input
                type="text"
                className="form-control"
                placeholder="MM / YYYY"
                id="expiryDate"
                name="expiryDate"
                value={expiryDate}
                maxLength="7"
                onChange={handleExpiryDateChange}
                onBlur={validateExpiryDate}
              />
              {isExpired && <span className="text-danger">{'Expired'}</span>}
            </Form.Group>

            <Form.Group className="col-md-6" controlId="ccv">
              <Form.Label>CVV *</Form.Label>
              <Form.Control
                type="number"
                maxLength={3}
                name="cvv"
                value={cvv}
                onChange={handleCvvChange}
                placeholder="123"
              />
            </Form.Group>
            <div className="row">
              {paymentData && (
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="form-label">Amount *</label>
                    <input
                      type="number"
                      className="form-control"
                      value={paymentData?.totalPrice}
                      readOnly
                    />
                  </div>
                </div>
              )}

              <div className="col-md-3">
                <label className="form-label">
                  <Trans>VAT</Trans> %
                </label>
                <div className="form-group">
                  <input
                    type="number"
                    value={vat}
                    name="vat"
                    onChange={handlePaymentChange}
                    className="form-control"
                  />
                </div>
              </div>
              {vat !== 0 && (
                <div className="col-md-4">
                  <label className="form-label">
                    Price After <Trans>VAT</Trans>
                  </label>
                  <div className="form-group">
                    <input
                      type="number"
                      name="priceAfterVat"
                      value={priceAfterVat}
                      readOnly
                      className="form-control"
                    />
                  </div>
                </div>
              )}
            </div>

            <Form.Group className="col-md-6" controlId="address">
              <Form.Label>Billing Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={address}
                onChange={handlePaymentChange}
              />
            </Form.Group>

            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Country</label>
                <select
                  className="form-select"
                  name="country"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  <option value="">
                    <Trans>Select from list</Trans>...
                  </option>
                  {AllCountry?.map((country) => (
                    <option key={country.country} value={country.country}>
                      {country.country}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">City</label>
                {CitiesLoading ? (
                  <div className="form-control d-flex justify-content-center">
                    <ReactLoading
                      type={'spinningBubbles'}
                      color={'#000000'}
                      height={24}
                      width={17}
                    />
                  </div>
                ) : (
                  <div>
                    <select
                      className="form-select"
                      type="text"
                      name="city"
                      value={city}
                      onChange={handlePaymentChange}
                    >
                      <option value="">
                        <Trans>Select from list</Trans>...
                      </option>
                      {cities.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>

            <Form.Group className="col-md-6" controlId="zip">
              <Form.Label>
                <Trans>Postal Code</Trans>
              </Form.Label>
              <Form.Control
                type="text"
                name="zip"
                value={zip}
                onChange={handlePaymentChange}
              />
            </Form.Group>

            <Form.Group controlId="agreeTerms">
              <Form.Check
                type="checkbox"
                name="agreeTerms"
                label="Please confirm you agree to our terms and conditions and privacy policy."
                checked={agreeTerms}
                onChange={handlePaymentChange}
              />
            </Form.Group>

            <div className="my-4 d-flex justify-content-end">
              {payLoad ? (
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  height={50}
                  width={50}
                  visible={true}
                />
              ) : (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={handleSubmitPayment}
                >
                  Pay Now
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/*  Payment Modal  End*/}

      {/* //=====subscribe subscription view modal=========== */}
      <Modal
        show={showViewSub}
        onHide={handleViewSubClose}
        centered
        dialogClassName="sub-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {profile && !profile?.customerId && (
            <Alert className="p-1  text-center">
              Please configure payment details in{' '}
              <Link
                to="/company/setting"
                style={{ textDecoration: 'underline' }}
              >
                <Trans>Settings</Trans>
              </Link>{' '}
              .
            </Alert>
          )}
          {profile && profile?.customerId && (
            <Alert className="p-1  text-center">
              Payment will be done automatically on Subscribe
            </Alert>
          )}
          {viewData && (
            <>
              <div className="table-container">
                <table className="mb-3">
                  {viewData && (
                    <tbody>
                      <tr>
                        <td className="fw-600">Name</td>
                        <td>{viewData.name}</td>
                      </tr>
                      <tr>
                        <td className="fw-600">
                          <Trans>Description</Trans>
                        </td>
                        <td>{viewData.description}</td>
                      </tr>
                      <tr>
                        <td className="fw-600">
                          <Trans>Shop</Trans> Type
                        </td>
                        {viewData.subscriptionType === 'Benchmarks' && (
                          <td>
                            <Trans>Benchmarks</Trans>
                          </td>
                        )}
                        {viewData.subscriptionType === 'Touchpoints' && (
                          <td>Touchpoints</td>
                        )}
                      </tr>
                      <tr>
                        <td className="fw-600">
                          Max. No. Of <Trans>Votes</Trans>
                        </td>
                        {/* {viewData.subscriptionType === "Benchmarks" ? (
                          <td className="fw-600">Max. No. Of <Trans>Votes</Trans></td>
                        ) : (
                          <td className="fw-600">No. Of Touchpoints</td>
                        )} */}
                        <td>
                          {' '}
                          {viewData?.maxVoteAllowed}
                          {/* {viewData.benchMark
                            ? viewData.benchMark
                            : viewData.allowedDTP} */}
                        </td>
                      </tr>

                      {/* <tr>
                        <td className="fw-600">Maximum Quantity Allowed</td>
                        <td>{viewData.maxQtn}</td>
                      </tr> */}

                      <tr>
                        <td className="fw-600">
                          <Trans>Start Date</Trans>
                        </td>
                        <td>{viewData.startDate.split('T')[0]}</td>
                      </tr>
                      <tr>
                        <td className="fw-600">
                          <Trans>End Date</Trans>
                        </td>
                        <td>{viewData.endDate.split('T')[0]}</td>
                      </tr>
                      <tr>
                        <td className="fw-600">
                          {' '}
                          Price {viewData.monthlyAnnual}
                        </td>
                        <td>
                          {viewData.priceAfterVat + ' ' + viewData.currencyType}
                        </td>
                      </tr>
                      {viewData.subscriptionType === 'Touchpoints' ? (
                        <tr>
                          <td className="fw-600">
                            <Trans>Email Module</Trans>
                          </td>
                          <td>
                            {emailModule ? (
                              <span>
                                <FontAwesomeIcon icon={faCheck} />
                              </span>
                            ) : (
                              <span>
                                <FontAwesomeIcon icon={faClose} />
                              </span>
                            )}
                          </td>
                        </tr>
                      ) : (
                        ''
                      )}
                      {viewData.subscriptionType === 'Touchpoints' ? (
                        <tr>
                          <td className="fw-600">
                            <Trans>Statistics</Trans>
                          </td>
                          <td className="fw-600">
                            {statistics ? (
                              <span>
                                <FontAwesomeIcon icon={faCheck} />
                              </span>
                            ) : (
                              <span>
                                <FontAwesomeIcon icon={faClose} />
                              </span>
                            )}
                          </td>
                        </tr>
                      ) : (
                        ''
                      )}

                      <tr>
                        <td className="fw-600">
                          <Trans>Limit to 1 per Business</Trans>
                        </td>
                        <td>
                          {' '}
                          {isOnePerCompany ? (
                            <span>
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                          ) : (
                            <span>
                              <FontAwesomeIcon icon={faClose} />
                            </span>
                          )}
                        </td>
                      </tr>
                      {viewData && (
                        <tr>
                          <td className="fw-600">Total to be paid</td>
                          <td>
                            <h4 className="mt-2">
                              {subTotalPrice} {viewData.currencyType}
                            </h4>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>

              <div className="table-responsive">
                <div className="d-flex justify-content-between mb-3">
                  {/* <div className="form-group me-3">
                    <label htmlFor="extraQtn"> Quantity</label>
                    <input
                      type="number"
                      className="form-control "
                      name="extraQtn"
                      value={extraQtn}
                      onChange={(e) => setExtraQtn(e.target.value)}
                    />
                  </div> */}
                  <div className="form-group mt-4 pt-1 gap-2">
                    {isLoadingAvailSubscription ? (
                      <div
                        className="btn btn-gradient pt-2 pb-2  ms-auto text-white border-0"
                        style={{
                          width: '93px',
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <ReactLoading
                            type={'spinningBubbles'}
                            color={'white'}
                            height={21}
                            width={31}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        {profile?.paymentMethod === 'card' ? (
                          <button
                            type="button"
                            className="btn btn-gradient  ms-auto  text-white border-0"
                            onClick={handleAvailSubscription}
                            style={{
                              height: '35px',
                            }}
                            // disabled={
                            //   profile?.selectedBenchMark &&
                            //   profile?.selectedBenchMark._id === viewData?._id
                            //     ? true
                            //     : false ||
                            //       (profile?.selectedSubscription &&
                            //         profile?.selectedSubscription._id ===
                            //           viewData?._id)
                            //     ? true
                            //     : false
                            // }
                          >
                            Subscribe
                          </button>
                        ) : (
                          <button
                            onClick={handlerModalBankTransfer}
                            type="button"
                            className="btn btn-gradient  ms-2 text-white border-0 "
                            // disabled={profile?.customerId ? true : false}
                            style={{
                              height: '35px',
                            }}
                          >
                            Subscribe
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Csubscription;
