import api, { apiWithFormData } from "../../utility/api";

// Login admin
const login = async (userData) => {

  const response = await api.post("/user/login", userData);
  if (response.data) {
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("profile", JSON.stringify(response.data));
    return response;
  }
};

// Create New Admin
const createAdmin = async (userData) => {
  const response = await apiWithFormData.post("/user/signup", userData);
  return response;
};

// Get All Admins
const getAllAdmins = async (data) => {
  const response = await api.post("/user/getAll", data);
  return response;
};

// Update Admin Settings or SUPER admin can update admin
const updateAdminSettings = async (userData) => {
  const response = await apiWithFormData.post("/user/updateSettings", userData);
  return response;
};

// Delete  Admin by Super Admin
const deleteAdmin = async (data) => {
  const response = await api.post("/user/delete", data);
  return response;
};

// Update default Customer Questions by  Admin  (alex)
const customerDefaultVotingAdmin = async (data) => {
  const response = await api.post("/user/customerDefaultVoting", data);
  return response;
};

// Update default Employee Questions by  Admin  (alex)
const employeeDefaultVotingAdmin = async (data) => {
  const response = await api.post("/user/employeeDefaultVoting", data);
  return response;
};

//=======================================BUSINESS APIs START =================================

// Regiter/SignUp Business
const businessRegistration = async (userData) => {
  const response = await apiWithFormData.post("/business/signup", userData);
  return response;
};

// Create Business by Admin
const createBusiness = async (userData) => {
  const response = await apiWithFormData.post("/business/signup", userData);
  return response;
};

// Sign Up Business by Admin
const signupBusiness = async (userData) => {
  const response = await apiWithFormData.post("/business/signup", userData);
  return response;
};

// Update Business Settings (Can be from business or can Admin)
const updateCompanySettings = async (businessData) => {
  const response = await apiWithFormData.post(
    "/business/updateBusinessSettings",
    businessData
  );
  return response;
};

// Update Business Card Details Settings (Can be from business or can Admin)
const updateCompanyCardDetails = async (businessData) => {
  const response = await api.post("/business/updateCardDetails", businessData);
  return response;
};

// Edit Business Settings BY ADMIN (ONLY)
const editCompanySettings = async (businessData) => {
  const response = await apiWithFormData.post(
    "/business/updateBusinessSettings",
    businessData
  );
  return response;
};

// Update Business subscription by business (later may be Admin)
const updateBusinessSub = async (businessData) => {
  const response = await api.post("/business/updateSubscription", businessData);
  return response;
};

// Update Business BenchMark by business (later may be Admin)
const updateBusinessBenchMark = async (businessData) => {
  const response = await api.post("/business/updateBenchMark", businessData);
  return response;
};

// Update default Customer Questions by business (later may be Admin)
const updateDefaultCusQuestion = async (businessData) => {
  const response = await api.post(
    "/business/defaultCustomerQuestion",
    businessData
  );
  return response;
};

// Update default Employee Questions by business (later may be Admin)
const updateDefaultEmpQuestion = async (businessData) => {
  const response = await api.post(
    "/business/defaultEmployeeQuestion",
    businessData
  );
  return response;
};

// Update default Employee QR Code by business (later may be Admin)
const updateDefaultEmpQR = async (data) => {
  const response = await api.post("/dataTouchPoint/defaultEmployeeQR", data);
  return response;
};

// Update default Customer QR Code by business (later may be Admin)
const updateDefaultCustQR = async (data) => {
  const response = await api.post("/dataTouchPoint/defaultCustomerQR", data);
  return response;
};

// Update default Customer Reasons by business (later may be Admin)
const updateCusReason = async (data) => {
  const response = await api.post("/reason/defaultCustomer", data);
  return response;
};

// Update default Employee Reasons by business (later may be Admin)
const updateEmpReason = async (data) => {
  const response = await api.post("/reason/defaultEmployee", data);
  return response;
};

//====================Default Question for Reason-----------------
const updateQuestionReason = async (data) => {
  const response = await api.post("/reason/defaultReasonQuestion", data);
  return response;
};

// Get All Businesses For Admin
const getAllBusinesses = async (data) => {
  const response = await api.post("/business/getAllBusinesses", data);
  return response;
};

// Update Approve status by Admin
const approveBusinessStatus = async (data) => {
  const response = await api.put("/business/approveStatus", data);
  return response;
};

// Update disapprove status by Admin
const disApproveBusiness = async (data) => {
  const response = await api.put("/business/updateBusinessStatus", data);
  return response;
};

// Delete Businesses by Admin
const deleteBusiness = async (data) => {
  const response = await api.post("/business/deleteBusiness", data);
  return response;
};

// Create note for Businesses by Admin
const createBusineeNote = async (data) => {
  const response = await api.post("/business/addNote", data);
  return response;
};

// Get Single business data by Admin or business owner
const getOneBusiness = async (data) => {
  const response = await api.post("/business/getOneBusinessData", data);
  return response;
};

//===========Data touchpoints API START =================================

// Get All datatouchpoints For business
const getAllBusinessDTP = async (data) => {
  const response = await api.post("/dataTouchPoint/getAllDataTouchPoint", data);
  return response;
};

// Get One Data Touch Point Details for reviews
const getOneDTP = async (data) => {
  const response = await api.post("/dataTouchPoint/getDataTouchPoint", data);
  return response;
};

//==========Create TouchPoint by Business
const createBusinessDTP = async (data) => {
  const response = await api.post("/dataTouchPoint/create", data);
  return response;
};

//===============Add User to DTP
const addUserToDTP = async (data) => {
  const response = await api.post("/dataTouchPoint/accessEmail", data);
  return response;
};

//===============Delete User to DTP
const deleteUserToDTP = async (data) => {
  const response = await api.post("/dataTouchPoint/deleteAccessEmail", data);
  return response;
};

// Update Approve status DTP by Admin/business
const approveDTPStatus = async (data) => {
  const response = await api.put(
    "/dataTouchPoint/updateDataTouchPointStatus",
    data
  );
  return response;
};

// Delete Data Touchpoint by admin or business
const deleteDTP = async (data) => {
  const response = await api.post("/dataTouchPoint/deleteDataTouchPoint", data);
  return response;
};

// Avail Subscription
const availSubscription = async (data) => {
  const response = await api.post("/subscription/availSubscription", data);
  return response;
};

// getAllSubReq Subscription
const getAllSubReq = async (data) => {
  const response = await api.post("/subscription/getAllSubReq", data);
  return response;
};
// updateSubReqStatus Subscription
const updateSubReqStatus = async (data) => {
  const response = await api.put("/subscription/updateSubStatus", data);
  return response;
};

//========Benchmark setting get =============
const getBenchSettings = async (data) => {
  const response = await api.post("/benchmarkSetting/settings/get", data);
  return response;
};

//========Benchmark setting false =============
const benchSetToFalse = async (data) => {
  const response = await api.post("/benchmarkSetting/settings/false", data);
  return response;
};

//========Benchmark setting True =============
const benchSetToTrue = async (data) => {
  const response = await api.post("/benchmarkSetting/settings/true", data);
  return response;
};

const authService = {
  login,
  createAdmin, // Only Super Admin
  deleteAdmin, // Only Super Admin
  getAllAdmins, // get all admins
  updateAdminSettings,
  customerDefaultVotingAdmin, // Only Admin (alex)
  employeeDefaultVotingAdmin, // Only Admin (alex)
  // loginBusiness,
  businessRegistration,
  createBusiness,
  signupBusiness, // Sign Up Business
  updateCompanySettings,
  editCompanySettings, //ONLY ADMIN
  getAllBusinesses,
  approveBusinessStatus,
  disApproveBusiness,
  deleteBusiness,
  createBusineeNote,
  getOneBusiness,
  updateBusinessSub,
  // loginDTP,
  getAllBusinessDTP,
  createBusinessDTP,
  addUserToDTP,
  deleteUserToDTP,
  approveDTPStatus,
  deleteDTP,
  getOneDTP,
  updateBusinessBenchMark,
  updateDefaultCusQuestion,
  updateDefaultEmpQuestion,
  updateDefaultEmpQR,
  updateDefaultCustQR,
  updateCusReason,
  updateEmpReason,
  updateQuestionReason,
  availSubscription,
  getAllSubReq,
  updateSubReqStatus,
  updateCompanyCardDetails,
  getBenchSettings, // BenchMark settings get
  benchSetToFalse,
  benchSetToTrue,
};

export default authService;
